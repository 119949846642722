import classNames from "classnames";
import React from "react";
import "what-input";

interface ContainerProps {
  children?: React.ReactNode;
  cssClass?: string;
  style?: React.CSSProperties;
}

const Container: React.FC<ContainerProps> = ({ cssClass, children, style }) => (
  <div
    className={classNames("sans-serif", cssClass)}
    data-testid="container"
    style={style}
  >
    {children}
  </div>
);

export default Container;
