import { ApolloError, ApolloQueryResult, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/nextjs";
import { useEffect } from "react";

import { CHECKOUT_ID_KEY } from "../../config";
import { CHECKOUT_GET } from "../graphql/checkout";
import { Checkout } from "../types/shopify";
import { getLocalStorageItem, removeLocalStorageItem } from "../utils/storage";

export interface UseCheckoutData {
  node: Checkout | null;
}

export interface UseCheckoutVariables {
  checkoutId?: string;
}

interface UseCheckout {
  checkout?: Checkout;
  error?: ApolloError;
  loading: boolean;
  refetch: () => Promise<ApolloQueryResult<UseCheckoutData>>;
}

const useCheckout = (id?: string): UseCheckout => {
  const checkoutId = id ?? getLocalStorageItem(CHECKOUT_ID_KEY);
  const { data, error, loading, refetch } = useQuery<
    UseCheckoutData,
    UseCheckoutVariables
  >(CHECKOUT_GET, {
    variables: {
      checkoutId,
    },
    skip: checkoutId === undefined,
    fetchPolicy: "no-cache",
  });

  const node = data?.node;

  useEffect(() => {
    if (node === null || (node?.id === checkoutId && node?.completedAt)) {
      removeLocalStorageItem(CHECKOUT_ID_KEY);
    }
  }, [checkoutId, node]);

  if (error) {
    Sentry.captureException(error);
  }

  const checkout = node && !node.completedAt ? node : undefined;

  return {
    checkout,
    loading: typeof window === "undefined" ? true : loading,
    error,
    refetch,
  };
};

export default useCheckout;
