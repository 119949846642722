import { ApolloError, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/nextjs";
import React from "react";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import {
  CheckoutCreateInput,
  MutationToCheckoutCreateArgs,
} from "shopify-storefront-api-typings";

import { CHECKOUT_ID_KEY } from "../../config";
import { CHECKOUT_CREATE } from "../graphql/checkout";
import { Checkout, CheckoutCreatePayload } from "../types/shopify";
import { setLocalStorageItem } from "../utils/storage";

interface UseCheckoutCreate {
  createCheckout: (
    input: CheckoutCreateInput,
  ) => Promise<Checkout | null | undefined>;
  data?: CheckoutCreatePayload;
  error?: ApolloError;
  loading: boolean;
}

interface UseCheckoutCreateOptions {
  useCache: boolean;
}

export interface UseCheckoutCreateData {
  checkoutCreate: CheckoutCreatePayload;
}

const useCheckoutCreate = ({
  useCache,
}: UseCheckoutCreateOptions): UseCheckoutCreate => {
  const [mutation, { data, error: apolloError, loading }] = useMutation<
    UseCheckoutCreateData,
    MutationToCheckoutCreateArgs
  >(CHECKOUT_CREATE);

  if (apolloError) {
    Sentry.captureException(apolloError);
  }

  const createCheckout = async (
    input: CheckoutCreateInput,
  ): Promise<Checkout | null | undefined> => {
    try {
      const { data } = await mutation({
        variables: {
          input,
        },
      });

      if (data) {
        if (data.checkoutCreate.checkoutUserErrors.length) {
          data.checkoutCreate.checkoutUserErrors.forEach(checkoutUserError => {
            Sentry.captureException(checkoutUserError);
          });
          return null;
        }

        if (data.checkoutCreate.checkout) {
          if (useCache) {
            setLocalStorageItem(
              CHECKOUT_ID_KEY,
              data.checkoutCreate.checkout.id,
            );
          }
          return data?.checkoutCreate?.checkout;
        }
      }

      return null;
    } catch (error) {
      toast.error(
        <p data-testid="toast">
          <FormattedMessage id="cart.errorCreatingCart" />
        </p>,
      );
      Sentry.captureException(error);
      return null;
    }
  };

  return {
    createCheckout,
    loading: typeof window === "undefined" ? false : loading,
    error: apolloError,
    data: data?.checkoutCreate,
  };
};

export default useCheckoutCreate;
