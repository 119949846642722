import classNames from "classnames";
import React from "react";

import { Menu } from "../lib/contentful/build-menu";
import sarkanniemiStylesheet from "../styles/themes/sarkanniemi";

import FooterMenuListSubMenu from "./FooterMenuListSubMenu";
import LocaleLink from "./LocaleLink";

export const TEST_ID_FOOTER_MENU_LIST_ITEM = "footer-menu-list-item";

interface FooterMenuListProps {
  menu: Menu;
}

const FooterMenuList: React.FC<FooterMenuListProps> = ({ menu }) => {
  return (
    <ul className="list ma0 pa0 overlflow-hidden flex-l justify-between">
      {menu?.items.map(item => (
        <li className="pr3-l mt4" key={item.name}>
          {item.items && item.items.length > 0 ? (
            <FooterMenuListSubMenu menuItem={item} />
          ) : (
            <LocaleLink href={item.href} slug={item.slug}>
              <a
                className={classNames(
                  sarkanniemiStylesheet.navItemFooter,
                  sarkanniemiStylesheet.heading3,
                  "pv2",
                )}
                target={item.openInNewWindow ? "_blank" : undefined}
                data-testid={TEST_ID_FOOTER_MENU_LIST_ITEM}
              >
                {item.name}
              </a>
            </LocaleLink>
          )}
        </li>
      ))}
    </ul>
  );
};

export default FooterMenuList;
