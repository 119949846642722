import { PageLikeEntry } from ".";

const mapEntryLink = (entry: PageLikeEntry): string[] => {
  if (!entry.fields) {
    return [];
  }
  const structure = [entry.fields.slug];
  if (entry.fields.parentContent) {
    structure.push(mapEntryLink(entry.fields.parentContent)[0]);
  }
  return structure;
};

export const buildSlug = (entry: PageLikeEntry): string[] => {
  if (!entry.fields?.slug) {
    return [];
  }

  if (entry.fields.parentSlug) {
    return entry.fields.parentSlug.endsWith(entry.fields.slug)
      ? [entry.fields.parentSlug]
      : [entry.fields.parentSlug, entry.fields.slug];
  }

  if (entry.fields.parentContent) {
    const structure = mapEntryLink(entry.fields.parentContent);
    structure.unshift(entry.fields.slug);

    return structure.reverse();
  }

  return [entry.fields.slug];
};

export const buildEntryLink = (entry: PageLikeEntry): string => {
  return buildSlug(entry).join("/");
};
