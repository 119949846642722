import { ApolloError, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/nextjs";

import {
  COLLECTION_MAX_COUNT,
  COLLECTION_PRODUCT_MAX_COUNT,
  COLLECTION_PRODUCT_VARIANT_MAX_COUNT,
} from "../../config";
import { COLLECTIONS_WITH_PRODUCTS_GET } from "../graphql/collections";
import {
  Collection,
  CollectionConnection,
  CollectionProduct,
} from "../types/shopify";

interface UseCollections {
  collections: Collection[];
  error?: ApolloError;
  loading: boolean;
  products: CollectionProduct[];
}

export interface UseCollectionsData {
  collections: CollectionConnection;
}

export interface UseCollectionsVariables {
  collectionCount: number;
  productCount: number;
  variantCount: number;
}

const useCollections = (
  collectionCount = COLLECTION_MAX_COUNT,
  productCount = COLLECTION_PRODUCT_MAX_COUNT,
  variantCount = COLLECTION_PRODUCT_VARIANT_MAX_COUNT,
): UseCollections => {
  const { data, error, loading } = useQuery<
    UseCollectionsData,
    UseCollectionsVariables
  >(COLLECTIONS_WITH_PRODUCTS_GET, {
    variables: {
      collectionCount,
      productCount,
      variantCount,
    },
  });

  if (error) {
    Sentry.captureException(error);
  }

  const collections = data ? data.collections.edges.map(edge => edge.node) : [];

  const products = collections
    ? collections.flatMap(collection =>
        collection.products.edges.map(edge => edge.node),
      )
    : [];

  return {
    collections,
    error,
    loading: typeof window === "undefined" ? true : loading,
    products,
  };
};

export default useCollections;
