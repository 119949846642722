import { createClient, Entry } from "contentful";

import { env } from "../../../environment";
import {
  IPageFields,
  IMenuFields,
  IAttractionFields,
  IRestaurantFields,
  IPage,
  IAttraction,
  IRestaurant,
  IEmailSubscriptionFields,
  IEmailSubscriptionFormFields,
} from "../../types/generated/contentful";
import { Locale } from "../i18n";

export type EntryPage = Entry<IPageFields>;
export type EntryMenu = Entry<IMenuFields>;
export type EntryEmailSubscriptionForm = Entry<IEmailSubscriptionFormFields>;
export type EntryAttraction = Entry<IAttractionFields>;
export type EntryRestaurant = Entry<IRestaurantFields>;
export type PageLikeEntry =
  | EntryAttraction
  | EntryRestaurant
  | EntryPage
  | IPage;
export type PageLike = IAttraction | IRestaurant | IPage;

const client = createClient({
  accessToken: env.CONTENTFUL_DELIVERY_API_ACCESS_TOKEN,
  environment: env.CONTENTFUL_ENVIRONMENT,
  host: env.CONTENTFUL_DELIVERY_API_HOST,
  space: env.CONTENTFUL_SPACE_ID,
});
const previewClient = createClient({
  accessToken: env.CONTENTFUL_CONTENT_PREVIEW_API_ACCESS_TOKEN,
  environment: env.CONTENTFUL_ENVIRONMENT,
  host: env.CONTENTFUL_CONTENT_PREVIEW_API_HOST,
  space: env.CONTENTFUL_SPACE_ID,
});

function getClient(preview: boolean) {
  return preview ? previewClient : client;
}

export function isEntryPage(entry: PageLikeEntry): entry is EntryPage {
  return (
    entry.sys.contentType.sys.id === "page" ||
    entry.sys.contentType.sys.id === "restaurant" ||
    entry.sys.contentType.sys.id === "attraction"
  );
}

export const getPages = async (
  locale: Locale,
  preview = false,
): Promise<{ pages: EntryPage[]; locale: Locale }> => {
  const response = await getClient(preview).getEntries<IPageFields>({
    locale,
    content_type: "page",
    include: 2,
    limit: 1000,
  });

  return {
    pages: response.items,
    locale,
  };
};

export const getPageBySlug = async (
  slug: string,
  locale: string,
  preview = false,
): Promise<EntryPage> => {
  const response = await getClient(preview).getEntries<IPageFields>({
    locale,
    content_type: "page",
    "fields.slug": slug,
    include: 10,
    limit: 1,
  });

  return response?.items?.[0] || Promise.reject(new Error("Entry not found."));
};

export const getAttractionBySlug = async (
  slug: string,
  locale: string,
  preview = false,
): Promise<EntryAttraction> => {
  const response = await getClient(preview).getEntries<IAttractionFields>({
    locale,
    content_type: "attraction",
    "fields.slug": slug,
    include: 10,
    limit: 1,
  });

  return response?.items?.[0] || Promise.reject(new Error("Entry not found."));
};

export const getAttractions = async (
  locale: Locale,
  preview = false,
): Promise<{ attractions: EntryAttraction[]; locale: Locale }> => {
  const response = await getClient(preview).getEntries<IAttractionFields>({
    locale,
    content_type: "attraction",
    include: 2,
    limit: 1000,
  });

  return {
    attractions: response.items,
    locale,
  };
};

export const getRestaurantBySlug = async (
  slug: string,
  locale: string,
  preview = false,
): Promise<EntryRestaurant> => {
  const response = await getClient(preview).getEntries<IRestaurantFields>({
    locale,
    content_type: "restaurant",
    "fields.slug": slug,
    include: 10,
    limit: 1,
  });

  return response?.items?.[0] || Promise.reject(new Error("Entry not found."));
};

export const getRestaurants = async (
  locale: Locale,
  preview = false,
): Promise<{ restaurants: EntryRestaurant[]; locale: Locale }> => {
  const response = await getClient(preview).getEntries<IRestaurantFields>({
    locale,
    content_type: "restaurant",
    include: 2,
    limit: 1000,
  });

  return {
    restaurants: response.items,
    locale,
  };
};

export const getMenuBySlug = async (
  slug: string,
  locale: string,
  preview = false,
): Promise<EntryMenu> => {
  const response = await getClient(preview).getEntries<IMenuFields>({
    locale,
    content_type: "menu",
    "fields.slug": slug,
    include: 4,
    limit: 1,
  });

  return response?.items?.[0] || Promise.reject(new Error("Menu not found."));
};

export const getMenusBySlugs = async (
  slugs: string[],
  locale: string,
  preview = false,
): Promise<EntryMenu[]> => {
  const response = await getClient(preview).getEntries<IMenuFields>({
    locale,
    content_type: "menu",
    "fields.slug[in]": slugs.join(),
    include: 4,
  });

  return response?.items || Promise.reject(new Error("Menu not found."));
};

export const getPageWithLocaleBySlug = async (
  slug: string,
  currentLocale: string,
  preview = false,
): Promise<EntryPage> => {
  const response = await getClient(preview).getEntries<IPageFields>({
    locale: "*",
    content_type: "page",
    [`fields.slug.${currentLocale}`]: slug,
    include: 10,
    limit: 1,
  });

  return response?.items?.[0] || Promise.reject(new Error("Entry not found."));
};

export const getAttractionWithLocaleBySlug = async (
  slug: string,
  currentLocale: string,
  preview = false,
): Promise<EntryAttraction> => {
  const response = await getClient(preview).getEntries<IAttractionFields>({
    locale: "*",
    content_type: "attraction",
    [`fields.slug.${currentLocale}`]: slug,
    include: 10,
    limit: 1,
  });

  return response?.items?.[0] || Promise.reject(new Error("Entry not found."));
};

export const getRestaurantWithLocaleBySlug = async (
  slug: string,
  currentLocale: string,
  preview = false,
): Promise<EntryRestaurant> => {
  const response = await getClient(preview).getEntries<IRestaurantFields>({
    locale: "*",
    content_type: "restaurant",
    [`fields.slug.${currentLocale}`]: slug,
    include: 10,
    limit: 1,
  });

  return response?.items?.[0] || Promise.reject(new Error("Entry not found."));
};

export const getEmailSubscriptionFormById = async (
  id: IEmailSubscriptionFields["id"],
  locale: string,
  preview = false,
): Promise<EntryEmailSubscriptionForm | null> => {
  try {
    const response = await getClient(
      preview,
    ).getEntries<IEmailSubscriptionFormFields>({
      locale,
      content_type: "emailSubscriptionForm",
      "fields.id": id,
      include: 2,
      limit: 1,
    });

    if (response.items.length) {
      return response.items[0];
    }

    return null;
  } catch (error) {
    return null;
  }
};
