import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import {
  CommonError,
  SignUpConfirmError,
} from "../../contexts/AuthenticationContext";
import { getHighlightElement } from "../../lib/intl-utils";

import Button from "./Button";
import ErrorText from "./ErrorText";
import FormField from "./FormField";
import { CommonViewProps } from "./LoginModal";
import LoginModalContainer from "./LoginModalContainer";
import LoginModalContent from "./LoginModalContent";
import LoginModalHeader from "./LoginModalHeader";

export const TEST_ID_SIGN_UP_VERIFICATION_VIEW_TITLE =
  "sign-up-verification-view-title";
export const TEST_ID_SIGN_UP_VERIFICATION_VIEW_TEXT =
  "sign-up-verification-view-text";

interface SignUpVerificationViewProps extends CommonViewProps {
  email: string;
  onResendSignUpConfirmation: () => void;
  onSignUpConfirm: (verificationCode: string) => void;
  resendSignUpConfirmationError: CommonError | null;
  signUpConfirmError: SignUpConfirmError | null;
}

const SignUpVerificationView: React.FC<SignUpVerificationViewProps> = ({
  email,
  onClose,
  onResendSignUpConfirmation,
  onSignUpConfirm,
  resendSignUpConfirmationError,
  signUpConfirmError,
  state,
}) => {
  const [verificationCode, setVerificationCode] = useState<string>("");
  const [codeSent, setCodeSent] = useState(false);
  const loading = state !== "idle";

  const onResend = () => {
    onResendSignUpConfirmation();
    setCodeSent(true);
  };

  return (
    <LoginModalContainer>
      <LoginModalHeader
        style="default"
        titleId="loginModal.signUp"
        onClose={onClose}
      />
      <LoginModalContent>
        <h3 data-testid={TEST_ID_SIGN_UP_VERIFICATION_VIEW_TITLE}>
          <FormattedMessage id="loginModal.verification.title" />
        </h3>
        <p data-testid={TEST_ID_SIGN_UP_VERIFICATION_VIEW_TEXT}>
          <FormattedMessage
            id="loginModal.verification.text"
            values={{ email, strong: getHighlightElement }}
          />
        </p>
        <FormField
          autoComplete="off"
          labelId="forms.verificationCode"
          type="text"
          value={verificationCode}
          name="one-time-code"
          onChange={event => setVerificationCode(event.target.value)}
          placeholder="000000"
        />
        <Button
          disabled={verificationCode.length < 6 || loading}
          textId="forms.verify"
          onClick={() => onSignUpConfirm(verificationCode)}
          style="primary"
          loading={state === "sign-up-confirm"}
          bold
        />
        <h3>
          <FormattedMessage id="loginModal.verification.codeLost" />
        </h3>
        {codeSent ? (
          <Button
            disabled={true}
            icon="checkmark"
            textId={"loginModal.verification.codeResent"}
            onClick={onResend}
            style="secondary"
            bold
          />
        ) : (
          <Button
            disabled={loading}
            textId={"loginModal.verification.resend"}
            onClick={onResend}
            style="secondary"
            loading={state === "resend-signup-confirm"}
            bold
          />
        )}
        {signUpConfirmError && <ErrorText errorId={signUpConfirmError} />}
        {resendSignUpConfirmationError && (
          <ErrorText errorId={resendSignUpConfirmationError} />
        )}
      </LoginModalContent>
    </LoginModalContainer>
  );
};

export default SignUpVerificationView;
