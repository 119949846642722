import classNames from "classnames";
import React, { useContext } from "react";

import IconFacebook from "../../public/svg/icon-facebook.svg";
import IconInstagram from "../../public/svg/icon-instagram.svg";
import IconTwitter from "../../public/svg/icon-twitter.svg";
import IconYoutube from "../../public/svg/icon-youtube.svg";
import { ContentfulPageContext } from "../contexts/ContentfulPageContext";
import { EmailSubscriptionFormContext } from "../contexts/EmailSubscriptionFormContext";
import { Menu } from "../lib/contentful/build-menu";

import EmailSubscriptionForm from "./EmailSubscriptionForm";
import css from "./Footer.module.scss";
import FooterMenuList from "./FooterMenuList";

export const TEST_ID_FOOTER_MENU_LIST = "footer-menu-list";
export const TEST_ID_FOOTER_SMALL_CONTACT = "footer-small-contact";
export const TEST_ID_FOOTER_SOCIALS_LIST = "footer-socials-list";
export const TEST_ID_FOOTER_EMAIL_FORM = "footer-email-form";

type FooterProps = {
  menu: Menu | null | undefined;
  isSmallFooter?: boolean;
};

const subscriptionFormDecorationSpacer = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="4"
    width="1"
    className={css.subscriptionFormDecorationSizer}
  />
);

const Footer: React.FC<FooterProps> = ({ menu, isSmallFooter }) => {
  const { form } = useContext(EmailSubscriptionFormContext);
  const { backgroundClassName, backgroundColor } = useContext(
    ContentfulPageContext,
  );

  return (
    <footer style={{ marginTop: "auto" }}>
      <div
        className={classNames(
          "relative",
          "w-100",
          css.topDecoration,
          backgroundClassName
            ? backgroundClassName
            : backgroundColor === undefined
            ? "bg-white"
            : undefined,
        )}
        style={{ backgroundColor }}
        aria-hidden="true"
      />
      {!isSmallFooter ? (
        <div className="ph3 relative bg-green white pv5 pb6-l">
          {form && (
            <div
              className={classNames(
                css.subscriptionForm,
                "w-100 center mb5 pa3 bg-white",
              )}
              data-testid={TEST_ID_FOOTER_EMAIL_FORM}
            >
              <div
                className={classNames(
                  css.subscriptionFormDecoration,
                  css["subscriptionFormDecoration--left"],
                )}
              >
                {subscriptionFormDecorationSpacer}
              </div>
              <EmailSubscriptionForm {...form} colorScheme="dark" />
              <div
                className={classNames(
                  css.subscriptionFormDecoration,
                  css["subscriptionFormDecoration--right"],
                )}
              >
                {subscriptionFormDecorationSpacer}
              </div>
            </div>
          )}
          <div className="flex-l">
            <div className="w-100-l" data-testid={TEST_ID_FOOTER_MENU_LIST}>
              {menu && <FooterMenuList menu={menu} />}
            </div>
            <ul
              className="list pa0 mt4 pl5-l mb0 center flex justify-center w-100 mw6 flex-column-l items-end-l w-auto-l"
              data-testid={TEST_ID_FOOTER_SOCIALS_LIST}
            >
              <li className="mh1 mh0-l mb2-l">
                <a
                  className="icon-wrapper-small icon-wrapper-large-l svg-icon bg-transparent ba bw1 b--inherit br-100 white flex items-center justify-center"
                  aria-label="Facebook"
                  href="https://www.facebook.com/Sarkanniemi"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  <IconFacebook />
                </a>
              </li>
              <li className="mh1 mh0-l mb2-l">
                <a
                  className="icon-wrapper-small icon-wrapper-large-l svg-icon bg-transparent ba bw1 b--inherit br-100 white flex items-center justify-center"
                  aria-label="YouTube"
                  href="https://www.youtube.com/TampereenSarkanniemi"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  <IconYoutube />
                </a>
              </li>
              <li className="mh1 mh0-l mb2-l">
                <a
                  className="icon-wrapper-small icon-wrapper-large-l svg-icon bg-transparent ba bw1 b--inherit br-100 white flex items-center justify-center"
                  aria-label="Instagram"
                  href="https://www.instagram.com/sarkanniemi"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  <IconInstagram />
                </a>
              </li>
              <li className="mh1 mh0-l mb2-l">
                <a
                  className="icon-wrapper-small icon-wrapper-large-l svg-icon bg-transparent ba bw1 b--inherit br-100 white flex items-center justify-center"
                  aria-label="Twitter"
                  href="https://twitter.com/sarkanniemi"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  <IconTwitter />
                </a>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <div
          className={css.smallContactInfo}
          data-testid={TEST_ID_FOOTER_SMALL_CONTACT}
        >
          Tampereen Särkänniemi Oy
          <br />
          puh. 020 7130 200
          <br />
          www.sarkanniemi.fi
          <br />
        </div>
      )}
      <div className={css.copyright}>Copyright &copy; Särkänniemi</div>
    </footer>
  );
};

export default Footer;
