import React, { useCallback } from "react";
import { useIntl } from "react-intl";

import { locales, localeNames } from "../lib/i18n";

interface LocaleSwitcherProps {
  handleLocaleChange: (locale: string) => void;
}

const LocaleSwitcher: React.FC<LocaleSwitcherProps> = ({
  handleLocaleChange,
}) => {
  const { locale: currentLocale } = useIntl();

  const handleLocaleButtonClick = useCallback(
    (event: React.MouseEvent): void => {
      handleLocaleChange(event.currentTarget.id);
    },
    [handleLocaleChange],
  );

  return (
    <ul className="list ma0 pa0 color-inherit">
      {locales.map(locale => (
        <li
          className={`dib mr3 locale-switcher-choice ${
            currentLocale === locale && "current"
          }`}
          key={locale}
          data-testid={currentLocale === locale ? "current-locale" : ""}
        >
          <button
            className={`icon-wrapper-large db fw6 f5 ttu ba bw1 br-100 pa0 lh-solid pointer lh-solid ${
              currentLocale === locale
                ? "white bg-black b--black"
                : "bg-transparent b--inherit"
            }`}
            aria-label={localeNames[locale]}
            onClick={handleLocaleButtonClick}
            id={locale}
            data-testid={`switch-language-to-${locale}`}
          >
            <span>{locale}</span>
          </button>
        </li>
      ))}
    </ul>
  );
};

export default LocaleSwitcher;
