import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from "@apollo/client";
import { DefaultSeo } from "next-seo";
import React from "react";
import { ToastContainer } from "react-toastify";

import AuthenticationProvider from "../contexts/AuthenticationContext";
import CalendarProvider from "../contexts/CalendarContext";
import CheckoutProvider from "../contexts/CheckoutContext";
import CollectionsProvider from "../contexts/CollectionsContext";
import CookieConsentProvider from "../contexts/CookieConsentContext";
import EmailSubscriptionFormProvider from "../contexts/EmailSubscriptionFormContext";
import MenuProvider from "../contexts/MenuContext";
import MiniCartVisibilityProvider from "../contexts/MiniCartVisibilityContext";
import MobileProvider from "../contexts/MobileContext";
import WithLocale from "../hocs/withLocale";
import { getPageHrefsFromPathname } from "../lib/contentful/localization";
import { Locale } from "../lib/i18n";
import { NEXT_SEO_DEFAULT_CONFIG } from "../seo/seo";

import HeadAlternateLinks from "./HeadAlternateLinks";

interface PageProps {
  apolloClient: ApolloClient<NormalizedCacheObject>;
  checkoutId?: string;
  children?: React.ReactNode;
  locale: Locale;
  pathname: string;
}

const Page: React.FC<PageProps> = ({
  apolloClient,
  checkoutId,
  children,
  locale,
  pathname,
}) => {
  return (
    <WithLocale locale={locale}>
      <ApolloProvider client={apolloClient}>
        <AuthenticationProvider>
          <MobileProvider>
            <HeadAlternateLinks hrefs={getPageHrefsFromPathname(pathname)} />
            <CookieConsentProvider>
              <CalendarProvider>
                <CollectionsProvider>
                  <MiniCartVisibilityProvider>
                    <CheckoutProvider checkoutId={checkoutId}>
                      <MenuProvider locale={locale}>
                        <EmailSubscriptionFormProvider formId="footer">
                          <DefaultSeo {...NEXT_SEO_DEFAULT_CONFIG} />
                          {children}
                        </EmailSubscriptionFormProvider>
                      </MenuProvider>
                    </CheckoutProvider>
                  </MiniCartVisibilityProvider>
                  <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    newestOnTop={true}
                    limit={1}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable={false}
                    hideProgressBar={true}
                  />
                </CollectionsProvider>
              </CalendarProvider>
            </CookieConsentProvider>
          </MobileProvider>
        </AuthenticationProvider>
      </ApolloProvider>
    </WithLocale>
  );
};

export default Page;
