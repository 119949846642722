import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from "body-scroll-lock";
import Link from "next/link";
import { Router } from "next/router";
import React, { useState, useEffect, useContext, useCallback } from "react";
import { useIntl } from "react-intl";

import { MobileContext } from "../contexts/MobileContext";
import { PageLocaleContext } from "../contexts/PageLocaleContext";
import useLocaleSwitcher from "../hooks/useLocaleSwitcher";
import { Menu } from "../lib/contentful/build-menu";
import px2rem from "../utils/px2rem";

import HeaderDesktop from "./HeaderDesktop";
import HeaderMobile from "./HeaderMobile";

export const TEST_ID_HEADER = "header";
export const TEST_ID_HEADER_LOGO = "header-logo";

type HeaderProps = {
  menu: Menu | null | undefined;
};

const Header: React.FC<HeaderProps> = ({ menu }) => {
  const { locale } = useIntl();
  const localeSwitcher = useLocaleSwitcher();
  const { hrefs } = useContext(PageLocaleContext);

  const [bodyScrollTarget, setBodyScrollTarget] = useState<Element | null>(
    null,
  );
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { isMobile, visibleHeaderComponents } = useContext(MobileContext);

  const handleLocaleChange = useCallback(
    (language: string): void => {
      localeSwitcher.change(language, hrefs);
    },
    [hrefs, localeSwitcher],
  );

  useEffect(() => {
    clearAllBodyScrollLocks();
    setBodyScrollTarget(document.querySelector("#mobile-navigation"));

    return (): void => {
      clearAllBodyScrollLocks();
    };
  }, []);

  const closeMobileMenu = useCallback((): void => {
    if (mobileMenuOpen) {
      setMobileMenuOpen(false);

      if (bodyScrollTarget) {
        enableBodyScroll(bodyScrollTarget);
      }
    }
  }, [bodyScrollTarget, mobileMenuOpen]);

  useEffect(() => {
    Router.events.on("routeChangeComplete", closeMobileMenu);

    return (): void => {
      Router.events.off("routeChangeComplete", closeMobileMenu);
    };
  }, [closeMobileMenu]);

  const openMobileMenu = useCallback((): void => {
    setMobileMenuOpen(true);

    if (bodyScrollTarget) {
      disableBodyScroll(bodyScrollTarget);
    }
  }, [bodyScrollTarget]);

  const toggleMobileMenu = useCallback((): void => {
    if (mobileMenuOpen) {
      closeMobileMenu();
    } else {
      openMobileMenu();
    }
  }, [closeMobileMenu, mobileMenuOpen, openMobileMenu]);

  const logoContainerProps: Pick<
    React.HTMLAttributes<HTMLDivElement | HTMLAnchorElement>,
    "className" | "style"
  > = {
    className: "flex-shrink-0 flex-grow-0",
    style: {
      visibility: visibleHeaderComponents.logo ? "visible" : "hidden",
    },
  };

  const logoElement = (
    <picture>
      <img
        src="/images/sarkanniemi-logo.png"
        width="60"
        height="60"
        alt="Särkänniemi"
        style={{ width: px2rem(42), height: px2rem(42) }}
      />
    </picture>
  );

  return (
    <header
      className="fixed top-0 left-0 w-100 bg-white pv3 z-999"
      data-testid={TEST_ID_HEADER}
    >
      <div className="ph3 flex items-center justify-between">
        {isMobile ? (
          <div {...logoContainerProps} data-testid={TEST_ID_HEADER_LOGO}>
            {logoElement}
          </div>
        ) : (
          <Link href={`/${locale}`} legacyBehavior>
            <a {...logoContainerProps} data-testid={TEST_ID_HEADER_LOGO}>
              {logoElement}
            </a>
          </Link>
        )}
        <HeaderDesktop
          menu={menu}
          handleLocaleChange={handleLocaleChange}
          toggleMobileMenu={toggleMobileMenu}
        />
        {visibleHeaderComponents.menu && (
          <HeaderMobile
            menu={menu}
            handleLocaleChange={handleLocaleChange}
            mobileMenuOpen={mobileMenuOpen}
            toggleMobileMenu={toggleMobileMenu}
          />
        )}
      </div>
    </header>
  );
};

export default Header;
