import { NextSeoProps } from "next-seo";
import { OpenGraphMedia } from "next-seo/lib/types";

import { SITE_URL } from "../../config";
import { PageLikeEntry } from "../lib/contentful";
import { buildEntryLink } from "../lib/contentful/build-entry-link";
import { LOCALE_BY_HOME_SLUG } from "../lib/i18n";
import { Product } from "../types/shopify";

const BASE_CONFIG: NextSeoProps = {
  titleTemplate: "%s | Särkänniemi",
};

export const NEXT_SEO_HIDDEN_CONFIG: NextSeoProps = {
  ...BASE_CONFIG,
  noindex: true,
  nofollow: true,
};

export const NEXT_SEO_DEFAULT_CONFIG: NextSeoProps =
  process.env.ALLOW_INDEXING === "true" ? BASE_CONFIG : NEXT_SEO_HIDDEN_CONFIG;

function getURLForContentfulPage(entry: PageLikeEntry): string {
  const locale = entry.sys.locale;
  const slug = entry.fields.slug;
  const homePath = LOCALE_BY_HOME_SLUG[slug];

  if (homePath) {
    return `${SITE_URL}/${homePath}`;
  }

  const path = buildEntryLink(entry);
  return `${SITE_URL}/${locale}/${path}`;
}

export function getNextSeoConfigForContentfulPage(
  entry: PageLikeEntry,
): NextSeoProps {
  const { title, seoDescription, seoImage } = entry.fields;
  const url = getURLForContentfulPage(entry);
  const images: OpenGraphMedia[] =
    seoImage && seoImage.fields?.file
      ? [
          {
            url: seoImage.fields.file.url,
            width: seoImage.fields.file.details.image?.width,
            height: seoImage.fields.file.details.image?.height,
            alt: seoImage.fields.description,
          },
        ]
      : [];
  return {
    ...NEXT_SEO_DEFAULT_CONFIG,
    canonical: url,
    description: seoDescription,
    openGraph: {
      description: seoDescription,
      images,
      title,
      url,
    },
    title,
  };
}

function getURLForProductPage({ handle, id }: Product, locale: string): string {
  return `${SITE_URL}/${locale}/product/${handle}/${id}`;
}

export function getNextSeoConfigForProductPage(
  product: Product,
  locale: string,
): NextSeoProps {
  const { title } = product;
  const url = getURLForProductPage(product, locale);
  const images = product.images.edges.reduce<{ url: string }[]>((acc, edge) => {
    if (edge.node.url) {
      return [...acc, { url: edge.node.url }];
    }
    return acc;
  }, []);
  return {
    ...NEXT_SEO_DEFAULT_CONFIG,
    canonical: url,
    openGraph: {
      images,
      title,
      url,
    },
    title,
  };
}
