import React from "react";

export function getDateFormat(includeTime = false): Intl.DateTimeFormatOptions {
  return includeTime
    ? {
        dateStyle: "medium",
        timeStyle: "short",
      }
    : {
        dateStyle: "medium",
      };
}

export function getSpanElement(
  className?: string,
): (...chunks: React.ReactNode[]) => React.ReactNode[] {
  return function SpanElement(...chunks: React.ReactNode[]) {
    // eslint-disable-next-line react/jsx-key
    return [<span className={className}>{chunks}</span>];
  };
}

export function getHighlightElement(
  ...chunks: React.ReactNode[]
): React.ReactNode[] {
  // eslint-disable-next-line react/jsx-key
  return [<strong>{chunks}</strong>];
}

export function getLineBreakElement(): React.ReactNode[] {
  // eslint-disable-next-line react/jsx-key
  return [<br />];
}

export function getAnchorElement(
  href: string,
  target = "_blank",
  className?: string,
): (...chunks: React.ReactNode[]) => React.ReactNode[] {
  return function AnchorElement(...chunks: React.ReactNode[]) {
    return [
      // eslint-disable-next-line react/jsx-key
      <a className={className} href={href} target={target}>
        {chunks}
      </a>,
    ];
  };
}
