// HEY YOU! This is legacy stuff and should eventually be phased out in favor of themes (front/src/styles/themes).

export const headingHero = "ttu b f1 f-headline-l tracked lh-title";
export const heading3 = "ttu b f6 f5-l lh-title";
export const paragraph1 = "f6 f5-l fw6 lh-copy";
export const paragraph2 = "f7 f6-l fw6 lh-copy";
export const paragraph3 = "f7 f6-l fw5 lh-copy";
export const primaryButton = `${heading3} dib ba bw1 br-pill b--black pv3 ph4 black no-underline hover-bg-black hover-white pointer lh-solid tc button-text-vertical-adjustment`;
export const secondaryButton = `${heading3} dib ba bw1 br-pill b--black pv3 ph4 white bg-black no-underline hover-bg-pink hover-white hover-b--pink pointer lh-solid tc button-text-vertical-adjustment`;
export const textButton = `${paragraph1} bn no-underline pink pointer bg-transparent underline-hover`;
export const iconButton = "bn bg-transparent pointer";
export const input = "h2 br-0 bl-0 bt-0 bb b--dark-gray f6 fw6 outline-0";
export const separator = "bb br-0 bl-0 bt-0 hide-last-border bn-ns";

export function heading1(isNasinneula = false): string {
  if (isNasinneula) {
    return "ttu f3 f-subheadline-l lh-title nn tc tracked-5";
  }

  return "ttu b f3 f-subheadline-l lh-title";
}

export function heading2(isNasinneula = false): string {
  if (isNasinneula) {
    return "ttu fw6 f4 lh-title tracked-10";
  }

  return "ttu b f4 f2-l lh-title";
}
