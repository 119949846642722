import "@formatjs/intl-locale/polyfill";
import "@formatjs/intl-getcanonicallocales/polyfill";
import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/en";
import "@formatjs/intl-pluralrules/locale-data/fi";
import { NextPageContext } from "next";

export const defaultLocale = "fi" as const;

export const locales = ["fi", "en"] as const;

export const localeNames = {
  fi: "Suomi",
  en: "English",
};

export type Locale = (typeof locales)[number];

export function isLocale(tested: string): tested is Locale {
  return locales.some(locale => locale === tested);
}

export const getLocale = (ctx: NextPageContext): string => {
  // query.locale is not available in browser after initial load
  if (!ctx.query.locale) {
    const requestURL = ctx.req?.url;
    if (requestURL) {
      for (const locale of locales) {
        if (requestURL.startsWith(`/${locale}`)) {
          return locale;
        }
      }
    }

    return defaultLocale;
  }

  return ctx.query.locale && Array.isArray(ctx.query.locale)
    ? ctx.query.locale[0]
    : ctx.query.locale;
};

export const HOME_SLUG_BY_LOCALE: Record<string, string> = {
  en: "home",
  fi: "etusivu",
};

export const LOCALE_BY_HOME_SLUG: Record<string, string> = {
  home: "en",
  etusivu: "fi",
};

const attractionBaseSlug = ["huvilaitteet", "rides"];
const restaurantBaseSlug = ["ravintolat", "restaurants"];

export const isAttraction = (query: string | string[]): boolean => {
  if (Array.isArray(query)) {
    return query.length > 1 && attractionBaseSlug.includes(query[0]);
  }

  return attractionBaseSlug.includes(query);
};

export const isRestaurant = (query: string | string[]): boolean => {
  if (Array.isArray(query)) {
    return query.length > 1 && restaurantBaseSlug.includes(query[0]);
  }

  return restaurantBaseSlug.includes(query);
};

export const removeLocaleFromPathname = (pathname: string): string => {
  const parts = pathname.split("/").slice(1);

  if (isLocale(parts[0]) || parts[0] === "[locale]") {
    return ["", ...parts.slice(1)].join("/");
  } else {
    return pathname;
  }
};

export const DATE_PLACEHOLDER_BY_LOCALE: Record<string, string> = {
  fi: "PP.KK.VVVV",
  en: "DD.MM.YYYY",
};

export const DATE_MASK_BY_LOCALE: Record<string, string[]> = {
  fi: ["P", "P", "K", "K", "V", "V", "V", "V"],
  en: ["D", "D", "M", "M", "Y", "Y", "Y", "Y"],
};
