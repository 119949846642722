import React, { useEffect, useState } from "react";

import { Menu } from "../lib/contentful/build-menu";
import { MenuRecord } from "../pages/api/contentful/menus";

type MenuContextProps = {
  footerMenu: Menu | null;
  headerMenu: Menu | null;
};

export const MenuContext = React.createContext<MenuContextProps>({
  footerMenu: null,
  headerMenu: null,
});

type MenuProviderProps = { children?: React.ReactNode; locale: string };

const MenuProvider: React.FC<MenuProviderProps> = ({ children, locale }) => {
  const [footerMenu, setFooterMenu] = useState<Menu | null>(null);
  const [headerMenu, setHeaderMenu] = useState<Menu | null>(null);

  useEffect(() => {
    fetch(`/api/contentful/menus?locale=${locale}`).then(async response => {
      if (response.status === 200) {
        const { footer, header } = (await response.json()) as MenuRecord;
        if (footer) {
          setFooterMenu(footer);
        }

        if (header) {
          setHeaderMenu(header);
        }
      }
    });
  }, [locale]);

  return (
    <MenuContext.Provider
      value={{
        footerMenu,
        headerMenu,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

export default MenuProvider;
