import { gql } from "@apollo/client";

const CHECKOUT_FRAGMENT = gql`
  fragment CHECKOUT_FRAGMENT on Checkout {
    id
    email
    completedAt
    webUrl
    updatedAt
    lineItems(first: 250) {
      edges {
        node {
          id
          quantity
          title
          variant {
            availableForSale
            quantityAvailable
            id
            title
            sku
            product {
              id
              handle
              title
              tags
              productType
            }
            price {
              amount
            }
            compareAtPrice {
              amount
            }
            image {
              id
              url
            }
            selectedOptions {
              name
              value
            }
            weight
          }
          customAttributes {
            key
            value
          }
        }
      }
    }
    customAttributes {
      key
      value
    }
    totalTax {
      amount
    }
    totalPrice {
      amount
    }
    subtotalPrice {
      amount
    }
  }
`;

export const CHECKOUT_GET = gql`
  query checkout($checkoutId: ID!) {
    node(id: $checkoutId) {
      ... on Checkout {
        ...CHECKOUT_FRAGMENT
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
`;

export const CHECKOUT_CREATE = gql`
  mutation checkoutCreate($input: CheckoutCreateInput!) {
    checkoutCreate(input: $input) {
      checkout {
        ...CHECKOUT_FRAGMENT
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
`;

export const CHECKOUT_LINE_ITEMS_REPLACE = gql`
  mutation checkoutLineItemsReplace(
    $checkoutId: ID!
    $lineItems: [CheckoutLineItemInput!]!
  ) {
    checkoutLineItemsReplace(checkoutId: $checkoutId, lineItems: $lineItems) {
      checkout {
        ...CHECKOUT_FRAGMENT
      }
      userErrors {
        field
        message
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
`;

export const CHECKOUT_ATTRIBUTES_UPDATE = gql`
  mutation checkoutAttributesUpdateV2(
    $checkoutId: ID!
    $input: CheckoutAttributesUpdateV2Input!
  ) {
    checkoutAttributesUpdateV2(checkoutId: $checkoutId, input: $input) {
      checkout {
        ...CHECKOUT_FRAGMENT
      }
      userErrors {
        field
        message
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
`;
