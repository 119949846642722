import classNames from "classnames";
import React, { useCallback, useContext } from "react";
import { useIntl } from "react-intl";

import IconChevronDown from "../../public/svg/icon-chevron-down.svg";
import IconChevronUp from "../../public/svg/icon-chevron-up.svg";
import { ThemeContext } from "../contexts/ThemeContext";
import { MenuItem } from "../lib/contentful/build-menu";

import css from "./HeaderDesktopMenuListSubMenu.module.scss";
import LocaleLink from "./LocaleLink";

export const TEST_ID_SUB_MENU_ITEM = "header-desktop-menu-list-sub-menu-item";

interface HeaderDesktopMenuListSubMenuProps {
  index: number;
  isOpen: boolean;
  menuItem: MenuItem;
  handleMenuOpen: (index: number | null) => void;
}

const HeaderDesktopMenuListSubMenu: React.FC<
  HeaderDesktopMenuListSubMenuProps
> = ({ index, isOpen, menuItem, handleMenuOpen }) => {
  const { formatMessage } = useIntl();
  const { styles } = useContext(ThemeContext);

  const handleMenuItemClick = useCallback((): void => {
    if (!isOpen) {
      handleMenuOpen(index);
    } else {
      handleMenuOpen(null);
    }
  }, [handleMenuOpen, index, isOpen]);

  return (
    <ul className="list ma0 pa0">
      <li>
        <button
          className={classNames(
            "flex",
            "items-center",
            "bg-transparent",
            "bw0",
            "pointer",
            "pa0",
            "w-100",
            "secondaryButton",
            "svg-icon-large",
            "stroke-current-color",
            "fill-transparent",
            {
              underline: isOpen,
            },
          )}
          aria-label={formatMessage({
            id: isOpen ? "menu.close" : "menu.open",
          })}
          onClick={handleMenuItemClick}
          id={menuItem.name}
          data-testid="children-toggle"
        >
          <span className={classNames(styles.navItem, styles.navItemHeader)}>
            {menuItem.name}
          </span>
          {isOpen ? <IconChevronUp /> : <IconChevronDown />}
        </button>
        <ul
          className={classNames(
            "list",
            "ma0",
            "pa0",
            "absolute",
            "pt1",
            "ph3",
            "top-100",
            css.childrenMenuList,
            "ws-normal",
            {
              db: isOpen,
              dn: !isOpen,
            },
          )}
          style={{
            left: "4rem",
          }}
          data-testid="children-menu"
        >
          {!menuItem.useOnlyAsLabel && (
            <li className="dib relative z-1 mr4 mb3" key={menuItem.name}>
              <LocaleLink href={menuItem.href} slug={menuItem.slug}>
                <a
                  className={classNames(styles.navItem, styles.navItemHeader)}
                  target={menuItem.openInNewWindow ? "_blank" : undefined}
                  data-testid={TEST_ID_SUB_MENU_ITEM}
                >
                  {menuItem.name}
                </a>
              </LocaleLink>
            </li>
          )}
          {menuItem.items
            ?.filter(subMenuItem => !subMenuItem.useOnlyAsLabel)
            .map(subMenuItem => (
              <li className="dib relative z-1 mr4 mb3" key={subMenuItem.name}>
                <LocaleLink href={subMenuItem.href} slug={subMenuItem.slug}>
                  <a
                    className={classNames(styles.navItem, styles.navItemHeader)}
                    target={subMenuItem.openInNewWindow ? "_blank" : undefined}
                    data-testid={TEST_ID_SUB_MENU_ITEM}
                  >
                    {subMenuItem.name}
                  </a>
                </LocaleLink>
              </li>
            ))}
        </ul>
      </li>
    </ul>
  );
};

export default HeaderDesktopMenuListSubMenu;
