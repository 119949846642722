import React from "react";

import { ThemeStylesheet } from "../styles/themes/base";
import nasinneulaStylesheet from "../styles/themes/nasinneula";
import sarkanniemiStylesheet from "../styles/themes/sarkanniemi";
import { IPageFields } from "../types/generated/contentful";

type ThemeContextProps = {
  isNasinneula: boolean;
  isSarkanniemi: boolean;
  theme: IPageFields["theme"];
  styles: ThemeStylesheet;
};

export const ThemeContext = React.createContext<ThemeContextProps>({
  isNasinneula: false,
  isSarkanniemi: true,
  styles: sarkanniemiStylesheet,
  theme: "sarkanniemi",
});

function getStylesheet(theme: IPageFields["theme"]): ThemeStylesheet {
  switch (theme) {
    case "nasinneula":
      return nasinneulaStylesheet;
    case "sarkanniemi":
      return sarkanniemiStylesheet;
    default:
      return sarkanniemiStylesheet;
  }
}

type ThemeProviderProps = {
  children?: React.ReactNode;
  theme: IPageFields["theme"];
};

export const ThemeProvider: React.FC<ThemeProviderProps> = ({
  children,
  theme,
}) => {
  return (
    <ThemeContext.Provider
      value={{
        isNasinneula: theme === "nasinneula",
        isSarkanniemi: !theme || theme === "sarkanniemi",
        styles: getStylesheet(theme),
        theme: theme ? theme : "sarkanniemi",
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
