import classNames from "classnames";
import React from "react";
import { useIntl } from "react-intl";

import IconCross from "../../public/svg/icon-cross.svg";
import { Menu } from "../lib/contentful/build-menu";

import css from "./HeaderMobile.module.scss";
import HeaderMobileMenuList from "./HeaderMobileMenuList";
import LocaleSwitcher from "./LocaleSwitcher";

export const TEST_ID_HEADER_MOBILE = "mobile-navigation";

interface HeaderMobileProps {
  handleLocaleChange: (language: string) => void;
  menu: Menu | null | undefined;
  toggleMobileMenu: () => void;
  mobileMenuOpen: boolean;
}

const HeaderMobile: React.FC<HeaderMobileProps> = ({
  handleLocaleChange,
  menu,
  toggleMobileMenu,
  mobileMenuOpen,
}) => {
  const { formatMessage } = useIntl();

  return (
    <nav
      className={classNames(
        "fixed top-0 left-0 w-100 vh-100 pv2 bg-light-pink z-max overflow-container momentum-scrolling bg-lightest-blue flex-column justify-between",
        {
          dn: !mobileMenuOpen,
          flex: mobileMenuOpen,
        },
      )}
      data-testid={TEST_ID_HEADER_MOBILE}
      id="mobile-navigation"
    >
      <div className="mw9 ph3" data-testid="mobile-navigation-content">
        <div
          className="flex items-center justify-end"
          style={{ height: "60px" }}
        >
          <button
            className="icon-wrapper-small bg-transparent ba bw1 br-100 b--inherit stroke-current-color flex flex-column items-center justify-center pointer"
            aria-label={formatMessage({ id: "menu.close" })}
            onClick={toggleMobileMenu}
            data-testid="mobile-navigation-close"
          >
            <IconCross />
          </button>
        </div>
        <div
          className="mw6 center mt3"
          style={{ paddingBottom: "80px" }}
          // eslint-disable-next-line react/no-unknown-property
          body-scroll-lock-ignore="true"
        >
          {menu && <HeaderMobileMenuList menu={menu} />}
          <div className="mt4">
            <LocaleSwitcher handleLocaleChange={handleLocaleChange} />
          </div>
        </div>
      </div>
      <div className={css.bottomDecoration} />
    </nav>
  );
};

export default HeaderMobile;
