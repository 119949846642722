import classNames from "classnames";
import React from "react";
import { FormattedMessage } from "react-intl";

import AppleLogo from "../../../public/svg/apple-logo.svg";
import Checkmark from "../../../public/svg/checkmark.svg";
import GoogleLogo from "../../../public/svg/google-logo.svg";
import Login from "../../../public/svg/login.svg";
import Profile from "../../../public/svg/profile.svg";
import Refresh from "../../../public/svg/refresh.svg";
import Trashcan from "../../../public/svg/trashcan.svg";

import css from "./Button.module.scss";

export const TEST_ID_BUTTON_LOADING_ICON = "button-loading-icon";

export interface ButtonProps {
  bold?: boolean;
  className?: string;
  disabled?: boolean;
  icon?: "apple" | "delete" | "google" | "login" | "register" | "checkmark";
  loading?: boolean;
  style?: "primary" | "secondary";
  onClick: () => void;
  textId: string;
}

function getIcon(icon: ButtonProps["icon"]): React.ReactNode {
  switch (icon) {
    case "apple": {
      return <AppleLogo />;
    }
    case "checkmark": {
      return <Checkmark className={css.checkmark} />;
    }
    case "delete": {
      return <Trashcan />;
    }
    case "google": {
      return <GoogleLogo />;
    }
    case "login": {
      return <Login />;
    }

    case "register": {
      return <Profile />;
    }
  }
}

const Button: React.FC<ButtonProps> = ({
  bold,
  className,
  disabled,
  icon,
  loading,
  onClick,
  style,
  textId,
}) => {
  return (
    <button
      className={classNames(css.button, className, {
        [css.bold]: bold === true,
        [css.primary]: style === "primary",
        [css.secondary]: style === undefined || style === "secondary",
      })}
      disabled={disabled}
      onClick={onClick}
    >
      {icon !== undefined && getIcon(icon)}
      <FormattedMessage id={textId} />
      {loading && (
        <Refresh
          className={css.loading}
          data-testid={TEST_ID_BUTTON_LOADING_ICON}
        />
      )}
    </button>
  );
};

export default Button;
