import React from "react";

import { PageLikeEntry, isEntryPage } from "../lib/contentful";

type BackgroundProps = {
  backgroundColor?: string;
  /** **DEPRECATED:** Use `backgroundColor` instead */
  backgroundClassName?: string;
};

function getBackgroundProps(entry: PageLikeEntry): BackgroundProps {
  if (!isEntryPage(entry)) {
    return {};
  }

  const { backgroundColor, backgroundColorV2 } = entry.fields;
  if (backgroundColorV2 !== undefined) {
    return {
      backgroundColor: backgroundColorV2.value,
    };
  }

  return {
    backgroundClassName: backgroundColor,
  };
}

type ContentfulPageContext = BackgroundProps;

export const ContentfulPageContext = React.createContext<ContentfulPageContext>(
  {
    backgroundClassName: undefined,
    backgroundColor: undefined,
  },
);

type ContentfulPageProviderProps = {
  children?: React.ReactNode;
  entry: PageLikeEntry;
};

const ContentfulPageProvider: React.FC<ContentfulPageProviderProps> = ({
  children,
  entry,
}) => {
  const { backgroundClassName, backgroundColor } = getBackgroundProps(entry);
  return (
    <ContentfulPageContext.Provider
      value={{
        backgroundClassName,
        backgroundColor,
      }}
    >
      {children}
    </ContentfulPageContext.Provider>
  );
};

export default ContentfulPageProvider;
