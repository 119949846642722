/// <reference types="../../../backend/organizations/api/generated/types/organizations" />

import { useRouter } from "next/router";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import ErrorPage from "../components/ErrorPage";
import LoadingSpinner from "../components/LoadingSpinner";
import OrganizationAPIClient, {
  ErrorResult,
  IOrganizationAPIClient,
} from "../organizations/org-api-client";
import {
  IOrgAuthManager,
  OrgAuthManager,
} from "../organizations/org-auth-manager";

type AuthState = "initial" | "success" | "failed";

type OrganizationContextProps = IOrganizationAPIClient & {
  authManager: IOrgAuthManager;
  authState: AuthState;
  organizationId: string;
};

const defaultMethod = async (): Promise<ErrorResult> =>
  new Promise((_, reject) =>
    reject({ errorMessage: "No OrganizationProvider available", status: null }),
  );

export const OrganizationContext =
  React.createContext<OrganizationContextProps>({
    authManager: new Proxy(
      {},
      {
        get: () => {
          throw new Error("authManager is not available outside the provider");
        },
      },
    ) as IOrgAuthManager,
    authState: "initial",
    organizationId: "",
    deleteShares: defaultMethod,
    deleteWalletShares: defaultMethod,
    editWallet: defaultMethod,
    editWalletShare: defaultMethod,
    fetchWallet: defaultMethod,
    reserveByMassShare: defaultMethod,
  });

interface OrganizationProviderProps {
  children?: React.ReactNode;
  contextProps?: Partial<OrganizationContextProps>;
  organizationId: string;
}

const OrganizationProvider: React.FC<OrganizationProviderProps> = ({
  children,
  contextProps,
  organizationId,
}) => {
  const router = useRouter();

  const authManager = useMemo(() => new OrgAuthManager(), []);
  const apiClient = useMemo<IOrganizationAPIClient>(
    () => new OrganizationAPIClient({ authManager, organizationId }),
    [authManager, organizationId],
  );

  const [authState, setAuthState] = useState<AuthState>(
    contextProps?.authState ?? "initial",
  );

  const editWallet = useCallback(
    async (
      id: Paths.OrgEditWallet.Responses.$200["walletId"],
      body: Paths.OrgEditWallet.RequestBody,
    ) => {
      return await apiClient.editWallet(id, body);
    },
    [apiClient],
  );

  const editWalletShare = useCallback(
    async (
      walletId: Paths.$OrganizationIdWallets$WalletIdShares$ShareId.Parameters.$2,
      shareId: Paths.$OrganizationIdWallets$WalletIdShares$ShareId.Parameters.$1,
      body: Paths.OrgEditWalletShare.RequestBody,
    ) => {
      return await apiClient.editWalletShare(walletId, shareId, body);
    },
    [apiClient],
  );

  const fetchWallet = useCallback(
    async (id: Paths.OrgFetchWallet.Responses.$200["walletId"]) => {
      return await apiClient.fetchWallet(id);
    },
    [apiClient],
  );

  const reserveByMassShare = useCallback(
    async (
      id: Paths.OrgFetchWallet.Responses.$200["walletId"],
      body: Paths.OrgReserveByMassShare.RequestBody,
    ) => {
      return await apiClient.reserveByMassShare(id, body);
    },
    [apiClient],
  );

  const deleteShares = useCallback(
    async (body: Paths.DeleteOrganizationShares.RequestBody) => {
      return await apiClient.deleteShares(body);
    },
    [apiClient],
  );

  const deleteWalletShares: OrganizationAPIClient["deleteWalletShares"] =
    useCallback(
      async walletId => {
        return await apiClient.deleteWalletShares(walletId);
      },
      [apiClient],
    );

  const getComponent = useCallback(() => {
    switch (authState) {
      case "initial": {
        return <LoadingSpinner />;
      }
      case "success": {
        return <>{children}</>;
      }
      default: {
        return (
          <ErrorPage
            message={{
              headingId: "auth.authenticationFailed",
              bodyId: "auth.tryAgain",
              buttonId: "auth.backToHome",
            }}
          />
        );
      }
    }
  }, [authState, children]);

  useEffect(() => {
    const { idToken, refreshToken } = router.query;
    if (typeof refreshToken === "string" && typeof idToken === "string") {
      authManager.setAuthTokens({
        refreshToken,
        idToken,
      });
      // After setting new tokens to session storage, refresh the page without query-params
      router.push(router.asPath.split("?")[0]);
    }

    const willRedirect = authManager.redirectIfAuthTokensNotPresent();
    if (willRedirect) {
      return;
    }

    setAuthState("success");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OrganizationContext.Provider
      value={{
        authManager,
        authState,
        organizationId,
        deleteShares,
        deleteWalletShares,
        editWallet,
        editWalletShare,
        fetchWallet,
        reserveByMassShare,
        ...contextProps,
      }}
    >
      {getComponent()}
    </OrganizationContext.Provider>
  );
};

export default OrganizationProvider;
