import { ApolloError, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/nextjs";
import { useCallback } from "react";
import {
  CheckoutAttributesUpdateV2Input,
  MutationToCheckoutAttributesUpdateV2Args,
  UserError,
} from "shopify-storefront-api-typings";

import { CHECKOUT_ATTRIBUTES_UPDATE } from "../graphql/checkout";
import { Checkout } from "../types/shopify";

interface UseCheckoutAttributes {
  updateAttributes: (
    checkoutId: string,
    input: CheckoutAttributesUpdateV2Input,
  ) => Promise<Checkout | null>;
  data?: {
    checkout: Checkout;
    userErrors: UserError[];
  };
  error?: ApolloError;
  loading: boolean;
}

export interface UseCheckoutAttributesData {
  checkoutAttributesUpdateV2: {
    checkout: Checkout;
    userErrors: UserError[];
  };
}

const useCheckoutAttributes = (): UseCheckoutAttributes => {
  const [mutation, { data, error: apolloError, loading }] = useMutation<
    UseCheckoutAttributesData,
    MutationToCheckoutAttributesUpdateV2Args
  >(CHECKOUT_ATTRIBUTES_UPDATE);

  if (apolloError) {
    Sentry.captureException(apolloError);
  }

  const updateAttributes: UseCheckoutAttributes["updateAttributes"] =
    useCallback(
      async (checkoutId, input) => {
        const { data } = await mutation({
          variables: {
            checkoutId,
            input,
          },
        });

        return data?.checkoutAttributesUpdateV2.checkout ?? null;
      },
      [mutation],
    );

  return {
    updateAttributes,
    loading: typeof window === "undefined" ? true : loading,
    error: apolloError,
    data: data?.checkoutAttributesUpdateV2,
  };
};

export default useCheckoutAttributes;
