import { PrimitiveType } from "intl-messageformat";
import { DateTime } from "luxon";
import { MessageDescriptor } from "react-intl";

import {
  CheckoutLineItemProductVariant,
  Product,
  ProductVariant,
  SubProduct,
} from "../types/shopify";
import {
  ExtendedVariant,
  VariantProductDetails,
} from "../types/shopify-components";

export enum SelectedOption {
  DATE_SELECTION = "date_selection",
  HEIGHT_RESTRICTION = "height_restriction",
  MEDIA = "Media",
  SLOT_IDS = "slot_ids",
}

export enum DateSelection {
  DATE = "date",
  FIXED = "fixed",
  OPEN = "open",
}

export enum ProductType {
  COMPOSITE_PARENT = "composite-parent",
  COMPOSITE_CHILD = "composite-child",
  TICKET = "ticket",
  TICKET_CONTAINER = "ticket-container",
  SEASON_CARD = "seasonpass",
}

export enum ProductTagPrefix {
  CONTAINER_PRODUCT = "super",
  SUB_PRODUCT = "sub",
  COMPOSITE_PARENT_PRODUCT = "composite-parent",
  COMPOSITE_CHILD_PRODUCT = "composite-child",
}

export const PRODUCT_TAG_SEPARATOR = ":";

type SeasonCardMediaRecord = {
  digital: string;
  physical: string;
  renewal: string;
};

/**
 * Season card `Media` option values should match these in Shopify product definition.
 * The English values are translated from Finnish strings with Shopify `T Lab` app.
 */
export const SEASON_CARD_MEDIA: Record<string, SeasonCardMediaRecord> = {
  fi: {
    digital: "Digikortti",
    physical: "Digi + muovikortti",
    renewal: "Vanhan kausikortin uusinta",
  },
  en: {
    digital: "Digital card",
    physical: "Digital + plastic card",
    renewal: "Renewal of old season card",
  },
};

type SeasonCardAttributeKey =
  | "FIRST_NAME"
  | "LAST_NAME"
  | "DATE_OF_BIRTH"
  | "GUARDIAN_FIRST_NAME"
  | "GUARDIAN_LAST_NAME"
  | "ADDRESS"
  | "POSTAL_CODE"
  | "CITY"
  | "EMAIL"
  | "PHONE"
  | "PHOTO_ID"
  | "TICKET_ID";

export const SEASON_CARD_ATTRIBUTES: Record<
  SeasonCardAttributeKey,
  | Components.Schemas.CustomAttributeKeySeasonCard
  | Components.Schemas.CustomAttributeKeySeasonCardOptional
> = {
  FIRST_NAME: "etunimi",
  LAST_NAME: "_sukunimi",
  DATE_OF_BIRTH: "_syntymaaika",
  GUARDIAN_FIRST_NAME: "_huoltajan_etunimi",
  GUARDIAN_LAST_NAME: "_huoltajan_sukunimi",
  ADDRESS: "_osoite",
  POSTAL_CODE: "_postinumero",
  CITY: "_kaupunki",
  EMAIL: "_email",
  PHONE: "_puhelinnumero",
  PHOTO_ID: "_kuvan_id",
  TICKET_ID: "_lipun_id",
};

export enum Tag {
  TIME_SELECTION = "time_selection",
  THRESHOLD_PREFIX = "threshold_",
}

export type SKUData = {
  type: ProductTagPrefix;
  sku: string;
};

function isDateString(value: string): boolean {
  /* Some quirks about JS runtimes to keep in mind
     - new Date("2") may be "Invalid Date" or 2001-01-31T22:00:00.000Z
     - new Date("2019-02-29") may be "Invalid Date" or 2019-03-01T00:00:00.000Z
  */
  const date = new Date(value);
  return !isNaN(date.getTime()) && date.toISOString().substr(0, 10) === value;
}

function getDateSelectionType(
  value: string,
): "fixed" | "date" | "open" | undefined {
  let ret: "fixed" | "date" | "open" | undefined;
  if (value === "open") {
    ret = "open";
  } else if (value === "fixed") {
    ret = "fixed";
  } else if (isDateString(value)) {
    ret = "date";
  } else {
    console.warn(`Encountered unknown date value: ${value}`);
    ret = undefined;
  }
  return ret;
}

export function hasBothOpenAndFixedDateVariants(
  productOptions: Product["options"],
): boolean {
  let ret: boolean;
  const dateSelection = productOptions.find(
    option => option.name === SelectedOption.DATE_SELECTION,
  );
  if (!dateSelection) {
    ret = false;
  } else {
    let openFound = false;
    let fixedFound = false;
    let dateFound = false;
    for (const value of dateSelection.values) {
      const dateSelectionType = getDateSelectionType(value);
      switch (dateSelectionType) {
        case "open":
          openFound = true;
          break;
        case "fixed":
          fixedFound = true;
          break;
        case "date":
          dateFound = true;
          break;
        case undefined:
        // getDateSelectionType already warns for this, so let's do nothing here
      }
    }
    if (dateFound && fixedFound) {
      console.warn(
        "Encountered both date options as well as 'fixed', intended for dynamic pricing",
      );
    }
    const specificFound = fixedFound || dateFound;
    if (!specificFound) {
      console.warn(
        "Product has date selection option but there are no values to make for a choice",
      );
    }
    return openFound && specificFound;
  }
  return ret;
}

function getMinimumAndMaximumHeight(
  option: ProductVariant["selectedOptions"][0],
): { minimumHeight?: number; maximumHeight?: number } {
  /* Ideally we'd have an option minimum_height: 120cm/140cm/unlimited. There'd be no redundancy nor room for errors
     like "-120cm, 120cm-, 130cm-".
     However, with that scheme we'd have to fetch lineItem->variant->product.options to resolve "unlimited" to
     { minimumHeight: 140 }, making queries heavier */
  let maximumHeight: number | undefined;
  let minimumHeight: number | undefined;

  const maximumHeightMatches = /^-([0-9]+) ?cm$/.exec(option.value);
  if (maximumHeightMatches) {
    return {
      maximumHeight: parseInt(maximumHeightMatches[1]),
      minimumHeight: undefined,
    };
  }
  const minimumHeightMatches = /^([0-9]+) ?cm-$/.exec(option.value);
  if (minimumHeightMatches) {
    return {
      minimumHeight: parseInt(minimumHeightMatches[1]),
      maximumHeight: undefined,
    };
  }

  return {
    minimumHeight,
    maximumHeight,
  };
}

export function getExtendedVariant(
  variant: ProductVariant | CheckoutLineItemProductVariant,
  productDetails: VariantProductDetails,
): ExtendedVariant {
  const {
    availableForSale,
    compareAtPrice,
    currentlyNotInStock,
    id,
    image,
    price,
    quantityAvailable,
    selectedOptions,
    sku,
    weight,
  } = variant;

  let extendedVariant: ExtendedVariant;

  if ("product" in variant) {
    extendedVariant = {
      availableForSale,
      compareAtPrice,
      currentlyNotInStock,
      handle: variant.product.handle,
      id,
      image,
      price,
      quantityAvailable,
      selectedOptions,
      sku,
      weight,
      title: variant.product.title,
      productType: variant.product.productType,
      tags: variant.product.tags,
    };
  } else {
    extendedVariant = {
      availableForSale,
      compareAtPrice,
      currentlyNotInStock,
      handle: productDetails.handle,
      id,
      image,
      price,
      quantityAvailable,
      selectedOptions,
      sku,
      weight,
      title: productDetails.title,
      productType: productDetails.productType,
      tags: productDetails.tags,
    };
  }

  const dateSelection = selectedOptions.find(
    option => option.name === SelectedOption.DATE_SELECTION,
  );
  if (dateSelection) {
    const validityDate = DateTime.fromISO(dateSelection.value);

    extendedVariant = {
      ...extendedVariant,
      dateSelection: validityDate.isValid
        ? DateSelection.FIXED
        : (dateSelection.value as DateSelection),
    };

    if (validityDate.isValid) {
      extendedVariant = {
        ...extendedVariant,
        dateSelection: validityDate.isValid
          ? DateSelection.FIXED
          : (dateSelection.value as DateSelection),
        validityDate,
      };
    }
  }

  const heightRestriction = selectedOptions.find(
    option => option.name === SelectedOption.HEIGHT_RESTRICTION,
  );
  if (heightRestriction) {
    extendedVariant = {
      ...extendedVariant,
      ...getMinimumAndMaximumHeight(heightRestriction),
    };
  }
  return extendedVariant;
}

export const getVariantTitle = (
  productName: string,
  variant: ExtendedVariant,
):
  | { descriptor: MessageDescriptor; values: Record<string, PrimitiveType> }
  | string => {
  const { maximumHeight, minimumHeight, tags } = variant;

  if (tags.includes(Tag.TIME_SELECTION) && variant.validityDate) {
    return variant.validityDate.toFormat("HH:mm");
  }

  if (maximumHeight !== undefined || minimumHeight !== undefined) {
    let key;
    let value;

    if (minimumHeight) {
      key = "heightOver";
      value = minimumHeight;
    } else {
      key = "heightUnder";
      value = maximumHeight;
    }

    return {
      descriptor: {
        id: `cart.${key}`,
      },
      values: {
        value,
      },
    };
  }

  return productName;
};

/**
 * Get the cheapest price from variants, optionally with date selection.
 * If cheapest price could not be found, return null instead.
 */
export const getCheapestPriceFromVariants = (
  variants: ExtendedVariant[],
  dateSelection?: DateSelection,
  startingDate?: DateTime,
  schedule?: number[],
): number | null => {
  if (!variants.length) {
    return null;
  }

  let filteredVariants: ExtendedVariant[];

  if (startingDate && schedule) {
    filteredVariants = variants.filter(
      ({ dateSelection: variantDateSelection, validityDate }) =>
        validityDate &&
        validityDate >= startingDate &&
        schedule.includes(validityDate.toMillis()) &&
        variantDateSelection === dateSelection,
    );
  } else {
    filteredVariants = variants.filter(
      variant => variant.dateSelection === dateSelection,
    );
  }

  if (!filteredVariants.length) {
    return null;
  }

  const cheapestPrice = filteredVariants.reduce(
    (prevMin, { price: priceRaw }) => {
      const price = Number(priceRaw.amount);
      return Math.min(prevMin, price);
    },
    Infinity,
  );
  return cheapestPrice !== Infinity ? cheapestPrice : null;
};

/**
 * Get the cheapest price for the month of the provided start date.
 * If all the product versions have the same price, return `null` instead.
 */
export const getCheapestPriceOfMonth = (
  startingDate: DateTime,
  variants: ExtendedVariant[],
  schedule: number[], // array of time values in milliseconds,
): number | null => {
  const lastDayOfMonth = startingDate.endOf("month");

  /**
   * Select a variant if:
   * - It does not have a validity date (open-date variant)
   *
   * OR
   *
   * - It has a validity date between the starting date and the last day of the starting date month
   * - It is in the schedule
   */
  const filteredVariants = variants.filter(
    ({ validityDate }) =>
      !validityDate ||
      (validityDate >= startingDate &&
        validityDate <= lastDayOfMonth &&
        schedule.includes(validityDate.toMillis())),
  );

  let firstPrice: number | undefined = undefined;
  let hasDifferingPrices = false;

  for (const { price: priceRaw } of filteredVariants) {
    const price = Number(priceRaw.amount);
    if (firstPrice === undefined) {
      firstPrice = price;
    } else if (price !== firstPrice) {
      hasDifferingPrices = true;
      break;
    }
  }

  return hasDifferingPrices
    ? filteredVariants.reduce((prevMin, { price: priceRaw }) => {
        const price = Number(priceRaw.amount);
        return Math.min(prevMin, price);
      }, Infinity)
    : null;
};

export const extendedVariantValidityDateComparator = (
  a: ExtendedVariant,
  b: ExtendedVariant,
): number => {
  const EQUAL_SORT = 0;
  const A_FIRST = -1;
  const B_FIRST = 1;
  // ExtendedVariant.validityDate is optional property, so we must check for possible undefined values
  if (a.validityDate === undefined) {
    if (b.validityDate === undefined) {
      return EQUAL_SORT; // both undefined - sort them as equal
    }
    return B_FIRST; // only a undefined - sort b first
  }

  if (b.validityDate === undefined) {
    return A_FIRST; // only b undefined - sort a first
  }

  // using dateTime === dateTime doesn't work, thus we need to leave EQUAL_SORT as the default value
  return a.validityDate < b.validityDate
    ? A_FIRST
    : a.validityDate > b.validityDate
    ? B_FIRST
    : EQUAL_SORT;
};

const {
  CONTAINER_PRODUCT,
  SUB_PRODUCT,
  COMPOSITE_PARENT_PRODUCT,
  COMPOSITE_CHILD_PRODUCT,
} = ProductTagPrefix;

const skuTagMatcher = RegExp(
  `^(${CONTAINER_PRODUCT}|${SUB_PRODUCT}|${COMPOSITE_PARENT_PRODUCT}|${COMPOSITE_CHILD_PRODUCT})${PRODUCT_TAG_SEPARATOR}(.+)$`,
);

export function getProductSKUFromTags(tags: string[]): SKUData | null {
  for (const tag of tags) {
    const match = skuTagMatcher.exec(tag);
    if (match !== null && match[1] !== undefined && match[2] !== undefined) {
      return {
        type: match[1] as ProductTagPrefix,
        sku: match[2],
      };
    }
  }
  return null;
}

export const isChildTagPrefix = (tag: ProductTagPrefix): boolean =>
  tag === ProductTagPrefix.SUB_PRODUCT ||
  tag === ProductTagPrefix.COMPOSITE_CHILD_PRODUCT;

export function getChildTagPrefixFromParent(
  parent: ProductTagPrefix,
): ProductTagPrefix | null {
  if (parent === CONTAINER_PRODUCT) {
    return SUB_PRODUCT;
  } else if (parent === COMPOSITE_PARENT_PRODUCT) {
    return COMPOSITE_CHILD_PRODUCT;
  }
  return null;
}

export function combineContainerAndSubProducts({
  containerProduct,
  subProducts,
  includeContainer = false,
}: {
  containerProduct: Product;
  subProducts: SubProduct[];
  includeContainer?: boolean;
}): Product {
  const combinedVariants = subProducts.flatMap(
    subProduct => subProduct.variants.edges,
  );

  const variants = includeContainer
    ? { edges: [...containerProduct.variants.edges, ...combinedVariants] }
    : { edges: combinedVariants };

  return {
    ...containerProduct,
    variants,
  };
}

export function getProductTag(prefix: ProductTagPrefix, sku: string): string {
  return `${prefix}${PRODUCT_TAG_SEPARATOR}${sku}`;
}
