import GTM from "react-gtm-module";

import { ExtendedVariant } from "../types/shopify-components";

interface CartEventItem {
  item_name: string;
  item_id: string;
  price: string;
  quantity: string;
}

function mapVariantToItem(
  { id, title, price }: ExtendedVariant,
  quantity = 1,
): CartEventItem {
  return {
    item_name: title,
    item_id: id,
    price: price.amount,
    quantity: String(quantity),
  };
}

export function triggerAddToCartEvent(
  variant: ExtendedVariant,
  quantity?: number,
): void {
  try {
    GTM.dataLayer({
      dataLayer: {
        event: "add_to_cart",
        ecommerce: {
          items: [mapVariantToItem(variant, quantity)],
        },
      },
    });
  } catch (error) {
    return;
  }
}

export function triggerRemoveFromCartEvent(
  variant: ExtendedVariant,
  quantity?: number,
): void {
  try {
    GTM.dataLayer({
      dataLayer: {
        event: "remove_from_cart",
        ecommerce: {
          items: [mapVariantToItem(variant, quantity)],
        },
      },
    });
  } catch (error) {
    return;
  }
}

export function triggerCustobarProductViewEvent(
  customerId: string,
  productId: string,
): void {
  try {
    GTM.dataLayer({
      dataLayer: {
        cb_customer_id: `sfy-${customerId.split("/").pop()}`,
        cb_product_id: `sfy-p${productId.split("/").pop()}`,
      },
    });
  } catch (error) {
    return;
  }
}
