import React from "react";
import { FormattedMessage } from "react-intl";

import { getHighlightElement } from "../../lib/intl-utils";

import Button from "./Button";
import { CommonViewProps } from "./LoginModal";
import LoginModalContainer from "./LoginModalContainer";
import LoginModalContent from "./LoginModalContent";
import LoginModalHeader from "./LoginModalHeader";

export const TEST_ID_SIGN_UP_SUCCESS_VIEW_TITLE = "sign-up-success-view-title";
export const TEST_ID_SIGN_UP_SUCCESS_VIEW_TEXT = "sign-up-success-view-text";

interface SignUpSuccessViewProps extends CommonViewProps {
  onContinue: () => void;
}

const SignUpSuccessView: React.FC<SignUpSuccessViewProps> = ({
  onClose,
  onContinue,
}) => {
  return (
    <LoginModalContainer>
      <LoginModalHeader
        style="default"
        titleId="loginModal.signUp"
        onClose={onClose}
      />
      <LoginModalContent>
        <h3 data-testid={TEST_ID_SIGN_UP_SUCCESS_VIEW_TITLE}>
          <FormattedMessage id="loginModal.verification.success.title" />
        </h3>
        <p data-testid={TEST_ID_SIGN_UP_SUCCESS_VIEW_TEXT}>
          <FormattedMessage
            id="loginModal.verification.success.text"
            values={{
              strong: getHighlightElement,
            }}
          />
        </p>
        <Button
          style="primary"
          textId="loginModal.continue"
          onClick={onContinue}
          bold
        />
      </LoginModalContent>
    </LoginModalContainer>
  );
};

export default SignUpSuccessView;
