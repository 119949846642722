import { Attribute, Product } from "shopify-storefront-api-typings";

import { ExtendedVariant } from "../types/shopify-components";

import { ProductType, SEASON_CARD_ATTRIBUTES, SelectedOption } from "./product";

export const getCustomAttributeByKey = (
  customAttributes: Attribute[],
  key: string,
): string | undefined => customAttributes.find(attr => attr.key === key)?.value;

export const getCustomAttributesByKeys = (
  customAttributes: Attribute[],
  keys: string[],
): (string | undefined)[] =>
  keys.map(key => getCustomAttributeByKey(customAttributes, key));

export function getSeasonCardVariantTitle(
  selectedOptions: ExtendedVariant["selectedOptions"],
): string | undefined {
  return selectedOptions.find(option => option.name === SelectedOption.MEDIA)
    ?.value;
}

export function getSeasonCardHolderName(
  customAttributes: Attribute[],
  productType: Product["productType"],
): string | null {
  if (productType !== ProductType.SEASON_CARD) {
    return null;
  }

  const [firstName, lastName] = getCustomAttributesByKeys(customAttributes, [
    SEASON_CARD_ATTRIBUTES.FIRST_NAME,
    SEASON_CARD_ATTRIBUTES.LAST_NAME,
  ]);

  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }

  return null;
}
