import { DateTime } from "luxon";
import * as Yup from "yup";

export const text = Yup.string()
  .required("validation.required")
  .trim("validation.whitespace")
  .strict(true)
  .max(250, "validation.length");

export const textNotRequired = Yup.string()
  .trim("validation.whitespace")
  .strict(true)
  .max(250, "validation.length");

export const email = Yup.string()
  .required("validation.required")
  /* 
    Note: Uses Whatwg's regex for email validation https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
    which means that it allows emails like "test@localhost"
  */
  .email("validation.email")
  .max(250, "validation.length");

export const isUnderAge = (dateString: string): boolean => {
  const dateOfBirth = DateTime.fromISO(dateString);
  if (!dateOfBirth.isValid) {
    return false;
  }
  const now = DateTime.local();
  return now.diff(dateOfBirth, ["year"]).years < 16;
};

export const guardianName = Yup.string().when("dateOfBirth", {
  is: (val: string) => isUnderAge(val),
  then: schema =>
    schema
      .required("validation.required")
      .trim("validation.whitespace")
      .strict(true)
      .max(250, "validation.length"),
  otherwise: schema =>
    schema
      .trim("validation.whitespace")
      .strict(true)
      .max(250, "validation.length"),
});
