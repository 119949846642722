import { DateTime } from "luxon";
import React from "react";

import { SHOP_TIMEZONE } from "../../config";
import {
  getProductSKUFromTags,
  ProductTagPrefix,
  SelectedOption,
} from "../ecommerce/product";
import useCollections from "../hooks/useCollections";
import { Collection, CollectionProduct, MoneyV2 } from "../types/shopify";

interface CollectionsContextProps {
  collections: Collection[];
  getProductsByCollectionId: (
    collections: Collection[],
    collectionId: string,
  ) => CollectionProduct[] | null;
  loading: boolean;
  products: CollectionProduct[];
}

function getProductsByCollectionId(
  collections: Collection[],
  collectionId: string,
): CollectionProduct[] | null {
  const collection = collections.find(collection => {
    try {
      return collection.id.endsWith(`/${collectionId}`);
    } catch {
      return false;
    }
  });

  if (collection) {
    const nonSubProducts: CollectionProduct[] = [];
    const minPricesBySKU: { [key: string]: MoneyV2 } = {};
    const today = DateTime.now().setZone(SHOP_TIMEZONE).startOf("day");

    for (const { node } of collection.products.edges) {
      const data = getProductSKUFromTags(node.tags);
      if (data?.type === ProductTagPrefix.SUB_PRODUCT) {
        for (const edge of node.variants.edges) {
          const minPrice = minPricesBySKU[data.sku];
          const { price, selectedOptions } = edge.node;
          const dateSelection = selectedOptions.find(
            ({ name }) => name === SelectedOption.DATE_SELECTION,
          )?.value;
          if (
            dateSelection &&
            DateTime.fromISO(dateSelection).startOf("day") >= today &&
            (minPrice === undefined ||
              parseFloat(minPrice.amount) > parseFloat(price.amount))
          ) {
            minPricesBySKU[data.sku] = price;
          }
        }
      } else if (data?.type === ProductTagPrefix.COMPOSITE_CHILD_PRODUCT) {
        continue;
      } else {
        nonSubProducts.push(node);
      }
    }

    return nonSubProducts.map(product => {
      const data = getProductSKUFromTags(product.tags);
      if (data?.type === ProductTagPrefix.CONTAINER_PRODUCT) {
        const minPrice = minPricesBySKU[data.sku];

        return {
          ...product,
          priceRange: {
            ...product.priceRange,
            minVariantPrice: minPrice ?? product.priceRange.minVariantPrice,
          },
        };
      }

      return product;
    });
  }

  return null;
}

export const CollectionsContext = React.createContext<CollectionsContextProps>({
  collections: [],
  getProductsByCollectionId: () => [],
  loading: false,
  products: [],
});

interface CollectionsProviderProps {
  children?: React.ReactNode;
}

const CollectionsProvider: React.FC<CollectionsProviderProps> = ({
  children,
}) => {
  const { collections, loading, products } = useCollections();

  return (
    <CollectionsContext.Provider
      value={{
        collections,
        getProductsByCollectionId,
        loading,
        products,
      }}
    >
      {children}
    </CollectionsContext.Provider>
  );
};

export default CollectionsProvider;
