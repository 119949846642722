import React, { useCallback, useEffect, useState } from "react";

import { env } from "../../environment";

/** Temporary logging in staging for testing */
function log(message?: unknown, ...optionalParams: unknown[]): void {
  if (env.ENVIRONMENT === "staging") {
    console.log(message, ...optionalParams);
  }
}

export type CookieCategory =
  // Strictly necessary
  | "C0000"
  // Functionality
  | "C0001"
  // Performance
  | "C0002"
  // Targeting
  | "C0003";

export type PandectesConsent = {
  allowedCategories: {
    functionality: boolean;
    performance: boolean;
    strictlyNecessary: boolean;
    targeting: boolean;
    unclassified: boolean;
  } | null;
  applicable: boolean;
  id: string | null;
  preferences?: number;
  status: "allow" | "deny" | null;
  timestamp: number | null;
};

export type Pandectes = {
  Pandectes?: {
    fn: {
      leaveSite: () => void;
      hideBanner: () => void;
      showBanner: () => void;
      revokeConsent: () => void;
      showRevokeButton: () => void;
    };
    getCurrentConsent: () => PandectesConsent;
  };
};

type LeadooToggleTracking = (
  enabled: boolean,
  obeyTrackingConfiguration?: boolean,
) => boolean;

type LeadooAnalytics = {
  ldanalytics?: {
    push: (a: LeadooToggleTracking) => void;
  };
};

export type ExtendedWindow = Window & Pandectes & LeadooAnalytics;

const extendedWindow =
  typeof window !== "undefined"
    ? (window as unknown as ExtendedWindow)
    : undefined;

type PandenctesConsentType = "default" | "new" | "stored" | "revoke";
type PandectesPreferences = number | undefined;

export interface PandectesEvent extends Event {
  detail?: {
    consentType: PandenctesConsentType;
    preferences: PandectesPreferences;
  };
}

interface CookieConsentContextProps {
  givenPermissions: CookieCategory[];
  updateSettings: () => void;
}

export const CookieConsentContext =
  React.createContext<CookieConsentContextProps>({
    givenPermissions: [],
    updateSettings: () => undefined,
  });

interface CookieConsentProviderProps {
  children?: React.ReactNode;
  initialGivenPermissions?: CookieCategory[];
}

const STRICTLY_NECESSARY: CookieCategory = "C0000";
const FUNCTIONAL: CookieCategory = "C0001";
const PERFORMANCE: CookieCategory = "C0002";
const TARGETING: CookieCategory = "C0003";

const CookieConsentProvider: React.FC<CookieConsentProviderProps> = ({
  children,
  initialGivenPermissions,
}) => {
  const [givenPermissions, setGivenPermissions] = useState<CookieCategory[]>(
    initialGivenPermissions ?? [STRICTLY_NECESSARY],
  );

  const handlePreferences = useCallback((preferences?: number) => {
    let permissions: CookieCategory[] = [STRICTLY_NECESSARY];

    // No preferences found or all categories are blocked. In other words, the user has denied consent for all types of cookies and tracking except the strictly necessary ones.
    if (preferences === undefined || preferences === 7) {
      permissions = [STRICTLY_NECESSARY];
    } else if (preferences === 0) {
      // This value means that all cookies are allowed
      permissions = [STRICTLY_NECESSARY, FUNCTIONAL, PERFORMANCE, TARGETING];
    } else {
      // If this condition is true, it means that cookies and tracking for functionality purposes are allowed.
      if ((preferences & 1) === 0) {
        permissions.push(FUNCTIONAL);
      }

      // This condition being true indicates that Performance-related cookies and tracking are permitted.
      if ((preferences & 2) === 0) {
        permissions.push(PERFORMANCE);
      }

      // When this is true, it signifies that Targeting cookies and tracking are allowed.
      if ((preferences & 4) === 0) {
        permissions.push(TARGETING);
      }
    }

    log(`Cookie permissions: ${permissions.join(", ")}`);

    setGivenPermissions(permissions);
  }, []);

  /**
   * See documentation at:
   * https://help.pandectes.io/en/article/how-to-run-custom-javascript-after-consent-157or5d/#3-understanding-the-consent-event-listener
   */
  const onConsent = useCallback(
    (event: PandectesEvent) => {
      if (event.detail !== undefined) {
        const { consentType, preferences } = event.detail;
        log(`PandectesEvent_OnConsent: ${consentType} / ${preferences}`);

        switch (consentType) {
          case "new": {
            handlePreferences(preferences);
            break;
          }
        }
      }
    },
    [handlePreferences],
  );

  const updateSettings = useCallback(() => {
    extendedWindow?.Pandectes?.fn.showBanner();
  }, []);

  useEffect(() => {
    window.addEventListener("PandectesEvent_OnConsent", onConsent);

    return () => {
      window.removeEventListener("PandectesEvent_OnConsent", onConsent);
    };
  }, [onConsent]);

  useEffect(() => {
    const currentConsent = extendedWindow?.Pandectes?.getCurrentConsent();
    log("Pandectes.getCurrentConsent", currentConsent);

    if (currentConsent) {
      handlePreferences(currentConsent.preferences);
    }
  }, [handlePreferences]);

  return (
    <CookieConsentContext.Provider value={{ givenPermissions, updateSettings }}>
      {children}
    </CookieConsentContext.Provider>
  );
};

export default CookieConsentProvider;
