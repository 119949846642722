import classNames from "classnames";
import React from "react";
import { FormattedMessage } from "react-intl";

import Close from "../../../public/svg/close.svg";

import css from "./LoginModalHeader.module.scss";

export const TEST_ID_LOGIN_MODAL_HEADER = "login-modal-header";
export const TEST_ID_LOGIN_MODAL_HEADER_TITLE = "login-modal-header-title";
export const TEST_ID_LOGIN_MODAL_HEADER_CLOSE_BUTTON =
  "login-modal-header-close-button";

interface LoginModalHeaderProps {
  onClose: () => void;
  style: "hero" | "default";
  titleId?: string;
}

const LoginModalHeader: React.FC<LoginModalHeaderProps> = ({
  onClose,
  style,
  titleId,
}) => {
  return (
    <header
      className={classNames({
        [css.hero]: style === "hero",
        [css.default]: style === "default",
      })}
      data-testid={TEST_ID_LOGIN_MODAL_HEADER}
    >
      {titleId && (
        <h1 data-testid={TEST_ID_LOGIN_MODAL_HEADER_TITLE}>
          <FormattedMessage id={titleId} />
        </h1>
      )}
      <button
        className={css.closeButton}
        onClick={onClose}
        data-testid={TEST_ID_LOGIN_MODAL_HEADER_CLOSE_BUTTON}
      >
        <Close />
      </button>
    </header>
  );
};

export default LoginModalHeader;
