import { gql } from "@apollo/client";

export const COLLECTIONS_WITH_PRODUCTS_GET = gql`
  query GetCollections(
    $collectionCount: Int!
    $productCount: Int!
    $variantCount: Int!
  ) {
    collections(first: $collectionCount) {
      edges {
        node {
          id
          products(first: $productCount) {
            edges {
              node {
                id
                description
                handle
                title
                options {
                  id
                  name
                  values
                }
                compareAtPriceRange {
                  minVariantPrice {
                    amount
                  }
                  maxVariantPrice {
                    amount
                  }
                }
                priceRange {
                  minVariantPrice {
                    amount
                  }
                  maxVariantPrice {
                    amount
                  }
                }
                images(first: 1) {
                  edges {
                    node {
                      id
                      url
                    }
                  }
                }
                productType
                tags
                variants(first: $variantCount) {
                  edges {
                    node {
                      price {
                        amount
                      }
                      selectedOptions {
                        name
                        value
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
