import React, { Fragment, ReactNode, useContext } from "react";
import { FormattedMessage } from "react-intl";

import { AuthenticationContext } from "../../contexts/AuthenticationContext";
import { getHighlightElement } from "../../lib/intl-utils";

import Button from "./Button";
import { CommonViewProps } from "./LoginModal";
import css from "./LoginModal.module.scss";
import LoginModalContainer from "./LoginModalContainer";
import LoginModalContent from "./LoginModalContent";
import LoginModalHeader from "./LoginModalHeader";

export const TEST_ID_INITIAL_VIEW_TITLE = "initial-view-title";
export const TEST_ID_INITIAL_VIEW_TEXT = "initial-view-text";

interface InitialViewProps extends CommonViewProps {
  loginButton: ReactNode;
  registerButton: ReactNode;
  onDelete: () => void;
  onSignOut: () => void;
}

const InitialView: React.FC<InitialViewProps> = ({
  loginButton,
  registerButton,
  onClose,
  onDelete,
  onSignOut,
  state,
}) => {
  const { user } = useContext(AuthenticationContext);
  const loading = state !== "idle";
  return (
    <LoginModalContainer>
      <LoginModalHeader style="hero" onClose={onClose} />
      <LoginModalContent>
        {user !== null ? (
          <Fragment>
            <h3 data-testid={TEST_ID_INITIAL_VIEW_TITLE}>
              {user.fullName ? (
                <FormattedMessage
                  id="loginModal.signIn.success.titleWithName"
                  values={{ name: user.fullName }}
                />
              ) : (
                <FormattedMessage id="loginModal.signIn.success.title" />
              )}
            </h3>
            <p data-testid={TEST_ID_INITIAL_VIEW_TEXT}>
              <FormattedMessage
                id="loginModal.signIn.success.text"
                values={{ email: user.email, strong: getHighlightElement }}
              />
            </p>
            <Button
              style="primary"
              textId="loginModal.continue"
              onClick={onClose}
              disabled={loading}
              bold
            />
            <Button
              style="secondary"
              textId="loginModal.signOut"
              onClick={onSignOut}
              disabled={loading}
              loading={state === "sign-out"}
              bold
            />
            <button className={css.linkButton} onClick={onDelete}>
              <FormattedMessage id="loginModal.deleteAccount" />
            </button>
          </Fragment>
        ) : (
          <Fragment>
            {loginButton}
            {registerButton}
          </Fragment>
        )}
      </LoginModalContent>
    </LoginModalContainer>
  );
};

export default InitialView;
