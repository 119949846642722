import React from "react";

export const TEST_ID_LOADING_SPINNER = "loading-spinner";

interface LoadingSpinnerProps {
  message?: string;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ message }) => {
  return (
    <div
      className="center mv4 flex items-center flex-column"
      data-testid={TEST_ID_LOADING_SPINNER}
    >
      <div className="ripple">
        <div />
        <div />
      </div>
      {message && (
        <p className="mt2 f4" data-testid="loading-spinner-message">
          {message}
        </p>
      )}
    </div>
  );
};

export default LoadingSpinner;
