import * as Sentry from "@sentry/nextjs";
import { AttributeInput } from "shopify-storefront-api-typings";

import { SEASON_CARD_DATA_KEY } from "../../config";
import { Checkout } from "../types/shopify";
import { CheckoutItem, CheckoutSummary } from "../types/shopify-components";

import { getCustomAttributesByKeys } from "./common";
import {
  getExtendedVariant,
  ProductType,
  SEASON_CARD_ATTRIBUTES,
} from "./product";

export function mapCheckoutToCheckoutItems(
  checkout?: Checkout,
): CheckoutItem[] {
  if (checkout) {
    return checkout.lineItems.edges.reduce<CheckoutItem[]>((acc, { node }) => {
      if (node.variant) {
        const { id, quantity, customAttributes, variant } = node;
        const checkoutItem: CheckoutItem = {
          variant: getExtendedVariant(variant, {
            title: variant.product.title,
            handle: variant.product.handle,
            productType: variant.product.productType,
            tags: variant.product.tags,
          }),
          input: {
            variantId: variant.id,
            quantity,
            customAttributes: customAttributes.reduce<AttributeInput[]>(
              (acc, cur) => {
                if (cur.value && cur.value !== "") {
                  const { key, value } = cur;
                  acc.push({ key, value });
                }
                return acc;
              },
              [],
            ),
          },
          checkoutLineItemId: id,
        };
        acc.push(checkoutItem);
      }
      return acc;
    }, []);
  }
  return [];
}

export function getCheckoutSummary(items: CheckoutItem[]): CheckoutSummary {
  const filteredItems = items.filter(
    item => item.variant.productType !== ProductType.COMPOSITE_CHILD,
  );
  const itemCount = filteredItems.reduce((count, { input }) => {
    return count + input.quantity;
  }, 0);
  const prices = filteredItems.reduce(
    (prev, { input, variant }) => {
      const { quantity } = input;

      const price = Number(variant.price.amount);
      const compareAtPrice = variant.compareAtPrice
        ? Number(variant.compareAtPrice.amount)
        : price;

      const newTotalPrice = Number.isNaN(price)
        ? prev.totalPrice
        : prev.totalPrice + quantity * price;
      const newComparePriceTotal = Number.isNaN(compareAtPrice)
        ? prev.comparePriceTotal
        : prev.comparePriceTotal + quantity * compareAtPrice;
      const newComparePriceDiscount =
        newComparePriceTotal > newTotalPrice
          ? Math.abs(newComparePriceTotal - newTotalPrice)
          : 0;

      return {
        totalPrice: newTotalPrice,
        comparePriceTotal: newComparePriceTotal,
        comparePriceDiscount: newComparePriceDiscount,
      };
    },
    {
      totalPrice: 0,
      comparePriceTotal: 0,
      comparePriceDiscount: 0,
    },
  );

  return {
    itemCount,
    ...prices,
  };
}

export function storeSeasonCardInSessionStorage(item: CheckoutItem): void {
  if (
    item.variant.productType === ProductType.SEASON_CARD &&
    item.input.customAttributes
  ) {
    const [
      firstName,
      lastName,
      dateOfBirth,
      guardianFirstName,
      guardianLastName,
      address,
      zip,
      city,
      email,
      phone,
      photoId,
      ticketId,
    ] = getCustomAttributesByKeys(item.input.customAttributes, [
      SEASON_CARD_ATTRIBUTES.FIRST_NAME,
      SEASON_CARD_ATTRIBUTES.LAST_NAME,
      SEASON_CARD_ATTRIBUTES.DATE_OF_BIRTH,
      SEASON_CARD_ATTRIBUTES.GUARDIAN_FIRST_NAME,
      SEASON_CARD_ATTRIBUTES.GUARDIAN_LAST_NAME,
      SEASON_CARD_ATTRIBUTES.ADDRESS,
      SEASON_CARD_ATTRIBUTES.POSTAL_CODE,
      SEASON_CARD_ATTRIBUTES.CITY,
      SEASON_CARD_ATTRIBUTES.EMAIL,
      SEASON_CARD_ATTRIBUTES.PHONE,
      SEASON_CARD_ATTRIBUTES.PHOTO_ID,
      SEASON_CARD_ATTRIBUTES.TICKET_ID,
    ]);

    try {
      sessionStorage.setItem(
        SEASON_CARD_DATA_KEY,
        JSON.stringify({
          shouldOpenModal: true,
          checkoutLineItemId: item.checkoutLineItemId,
          variantId: item.variant.id,
          photoId,
          fields: {
            firstName,
            lastName,
            dateOfBirth,
            guardianFirstName,
            guardianLastName,
            address,
            zip,
            city,
            email,
            phone,
            ticketId,
          },
        }),
      );
    } catch (error) {
      Sentry.captureException(error);
    }
  }
}

export const hasShippingMethodConflict = (items: CheckoutItem[]): boolean => {
  let hasMailOnlyProduct = false;
  let hasSeasonCard = false;
  for (const item of items) {
    const { productType, weight } = item.variant;
    const shippableOnlyByMail = weight !== undefined && weight >= 1;

    hasMailOnlyProduct = hasMailOnlyProduct || shippableOnlyByMail;
    hasSeasonCard = hasSeasonCard || productType === ProductType.SEASON_CARD;
    if (hasMailOnlyProduct && hasSeasonCard) {
      return true;
    }
  }
  return false;
};
