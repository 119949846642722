import { useRouter } from "next/router";
import React, { useContext, useEffect, useMemo, useState } from "react";

import { CLIENT_KEY, CLIENT_MOBILE } from "../../config";
import { getLocalStorageItem, setLocalStorageItem } from "../utils/storage";

import { AuthenticationContext } from "./AuthenticationContext";

type LoginMessage = {
  action: "login";
};

type CopyToClipboardMessage = {
  action: "copyToClipboard";
  payload: string;
};

export type Message = LoginMessage | CopyToClipboardMessage;

type PostMessageFn = (message: string) => void;
type MobileAppChannel = {
  postMessage: PostMessageFn;
};

/** Mobile app should add MobileApp key to window when opening WebView */
export interface MobileAppWindow extends Window {
  MobileApp?: MobileAppChannel;
}

function getMobileApp(): MobileAppChannel | null {
  const mobileAppWindow = window as unknown as MobileAppWindow;
  if (
    typeof mobileAppWindow === "undefined" ||
    mobileAppWindow.MobileApp === undefined
  ) {
    console.log("window.MobileApp not available");
    return null;
  }

  return mobileAppWindow.MobileApp;
}

const queryParameters = ["client", "idToken"];

type HeaderComponents = "logo" | "cart" | "menu" | "account";
type HeaderRecord = Record<HeaderComponents, boolean>;

interface MobileContextProps {
  isMobile: boolean;
  sendMessage: (message: Message) => void;
  visibleHeaderComponents: HeaderRecord;
}

export const MobileContext = React.createContext<MobileContextProps>({
  isMobile: false,
  sendMessage: () => undefined,
  visibleHeaderComponents: {
    account: true,
    cart: true,
    logo: true,
    menu: true,
  },
});

interface MobileProviderProps {
  children?: React.ReactNode;
  contextProps?: Partial<MobileContextProps>;
}

const MobileProvider: React.FC<MobileProviderProps> = ({
  children,
  contextProps,
}) => {
  const router = useRouter();
  const { signInWithToken } = useContext(AuthenticationContext);
  const [isMobile, setIsMobile] = useState(false);
  const visibleHeaderComponents: HeaderRecord = useMemo(
    () => ({
      account: !isMobile,
      cart: true,
      logo: true,
      menu: !isMobile,
    }),
    [isMobile],
  );

  const sendMessage: MobileContextProps["sendMessage"] = (message: Message) => {
    const mobileApp = getMobileApp();
    if (mobileApp !== null) {
      mobileApp.postMessage(JSON.stringify(message));
    }
  };

  useEffect(() => {
    if (getLocalStorageItem(CLIENT_KEY) === CLIENT_MOBILE) {
      setIsMobile(true);
    }
  }, []);

  useEffect(() => {
    if (router.query.client === CLIENT_MOBILE) {
      setLocalStorageItem(CLIENT_KEY, CLIENT_MOBILE);
      setIsMobile(true);

      const encodedIdToken = router.query.idToken;
      if (typeof encodedIdToken === "string") {
        signInWithToken(encodedIdToken);
      }

      const { pathname, query } = router;

      for (const parameter of queryParameters) {
        delete query[parameter];
      }

      router.replace({ pathname, query }, undefined, { shallow: true });
    }
  }, [router, signInWithToken]);

  return (
    <MobileContext.Provider
      value={{
        isMobile,
        sendMessage,
        visibleHeaderComponents,
        ...contextProps,
      }}
    >
      {children}
    </MobileContext.Provider>
  );
};

export default MobileProvider;
