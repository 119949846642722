import { NextRouter } from "next/router";
import { useEffect } from "react";

const useScrollRestoration = (router: NextRouter): void => {
  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";

      const handleSaveScroll = () => {
        const scrollPos = { x: window.scrollX, y: window.scrollY };
        sessionStorage.setItem(location.pathname, JSON.stringify(scrollPos));
      };

      const handleRestoreScroll = () => {
        const item = sessionStorage.getItem(location.pathname);
        const scrollPos = item ? JSON.parse(item) : undefined;

        if (scrollPos) {
          window.scrollTo(scrollPos.x, scrollPos.y);
        }
      };

      window.addEventListener("beforeunload", handleSaveScroll);
      router.events.on("routeChangeStart", handleSaveScroll);
      window.addEventListener("popstate", handleRestoreScroll);
      return () => {
        window.removeEventListener("beforeunload", handleSaveScroll);
        router.events.off("routeChangeStart", handleSaveScroll);
        window.removeEventListener("popstate", handleRestoreScroll);
      };
    }
  }, [router]);
};

export default useScrollRestoration;
