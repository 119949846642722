import React, { useContext } from "react";

import { ThemeContext } from "../contexts/ThemeContext";
import { Menu } from "../lib/contentful/build-menu";

import HeaderMobileMenuListSubMenu from "./HeaderMobileMenuListSubMenu";
import LocaleLink from "./LocaleLink";

export const TEST_ID_MENU_ITEM = "header-mobile-menu-list-item";

interface HeaderMobileMenuListProps {
  menu: Menu;
}

const HeaderMobileMenuList: React.FC<HeaderMobileMenuListProps> = ({
  menu,
}) => {
  const { styles } = useContext(ThemeContext);
  return (
    <ul className="list ma0 pa0">
      {menu?.items.map(item => (
        <li className="mb4" key={item.name}>
          {item.items && item.items.length > 0 ? (
            <HeaderMobileMenuListSubMenu menuItem={item} />
          ) : (
            <LocaleLink href={item.href} slug={item.slug}>
              <a
                className={`${styles.heading2} no-underline color-inherit underline-hover db pv2`}
                target={item.openInNewWindow ? "_blank" : undefined}
                data-testid={TEST_ID_MENU_ITEM}
              >
                {item.name}
              </a>
            </LocaleLink>
          )}
        </li>
      ))}
    </ul>
  );
};

export default HeaderMobileMenuList;
