import React from "react";
import { FormattedMessage } from "react-intl";

import css from "./ErrorText.module.scss";

interface ErrorTextProps {
  errorId: string;
  testId?: string;
}

const ErrorText: React.FC<ErrorTextProps> = ({ errorId, testId }) => {
  return (
    <p className={css.errorText} data-testid={testId}>
      <FormattedMessage id={errorId} />
    </p>
  );
};

export default ErrorText;
