import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { EntryEmailSubscriptionForm } from "../lib/contentful";
import { IEmailSubscriptionFormFields } from "../types/generated/contentful";

interface EmailSubscriptionFormContextProps {
  form: IEmailSubscriptionFormFields | null;
  hasFailed: boolean;
  isLoading: boolean;
}

export const EmailSubscriptionFormContext =
  React.createContext<EmailSubscriptionFormContextProps>({
    form: null,
    hasFailed: false,
    isLoading: true,
  });

interface EmailSubscriptionFormProviderProps {
  children?: React.ReactNode;
  formId?: string;
  contextProps?: Partial<EmailSubscriptionFormContextProps>;
}

const EmailSubscriptionFormProvider: React.FC<
  EmailSubscriptionFormProviderProps
> = ({ children, contextProps, formId }) => {
  const { locale } = useIntl();
  const [hasFailed, setHasFailed] = useState<boolean>(
    contextProps?.hasFailed ?? false,
  );
  const [isLoading, setIsLoading] = useState(contextProps?.isLoading ?? true);
  const [form, setForm] = useState<IEmailSubscriptionFormFields | null>(
    contextProps?.form ?? null,
  );

  useEffect(() => {
    if (formId) {
      fetch(
        `/api/contentful/email-subscription-form?locale=${locale}&formId=${formId}`,
      )
        .then(async response => {
          if (response.status === 200) {
            const entry = (await response.json()) as EntryEmailSubscriptionForm;
            if (entry) {
              setForm(entry.fields);
            }
          }
        })
        .catch(() => {
          setHasFailed(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [formId, locale]);

  return (
    <EmailSubscriptionFormContext.Provider
      value={{
        form,
        hasFailed,
        isLoading,
        ...contextProps,
      }}
    >
      {children}
    </EmailSubscriptionFormContext.Provider>
  );
};

export default EmailSubscriptionFormProvider;
