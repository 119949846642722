import React from "react";

import css from "./LoginModalContent.module.scss";

interface LoginModalContentProps {
  children?: React.ReactNode;
}

const LoginModalContent: React.FC<LoginModalContentProps> = ({ children }) => {
  return <div className={css.content}>{children}</div>;
};

export default LoginModalContent;
