import React, { ChangeEventHandler } from "react";
import { FormattedMessage } from "react-intl";
import { Props } from "react-intl/src/components/message";

import EyeClosed from "../../../public/svg/icon-eye-closed.svg";
import EyeOpen from "../../../public/svg/icon-eye-open.svg";

import css from "./FormField.module.scss";

export const TEST_ID_ICON_BUTTON = "form-field-icon-button";
export const TEST_ID_FORM_FIELD_ERRORS = "form-field-errors";

export interface FormFieldProps {
  autoComplete?:
    | "email"
    | "current-password"
    | "new-password"
    | "given-name"
    | "family-name"
    | "off";
  errors?: string[];
  fieldIcon?: {
    icon: "eye-open" | "eye-closed";
    onClick?: () => void;
  };
  labelId: string;
  labelValues?: Props["values"];
  name?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  testId?: string;
  type: "email" | "password" | "text";
  value: string;
}

function getFieldIcon(
  fieldIcon?: FormFieldProps["fieldIcon"],
): React.ReactNode {
  if (fieldIcon === undefined) {
    return null;
  }

  const { icon, onClick } = fieldIcon;

  let iconElement: React.ReactNode;

  switch (icon) {
    case "eye-closed": {
      iconElement = <EyeClosed />;
      break;
    }
    case "eye-open": {
      iconElement = <EyeOpen />;
      break;
    }
  }

  if (iconElement === null) {
    return null;
  }

  return (
    <button
      className={css.iconButton}
      onClick={onClick}
      data-testid={TEST_ID_ICON_BUTTON}
    >
      {iconElement}
    </button>
  );
}

const FormField: React.FC<FormFieldProps> = ({
  autoComplete,
  errors,
  fieldIcon,
  labelId,
  labelValues,
  name,
  onChange,
  placeholder,
  readOnly,
  required,
  testId,
  type,
  value,
}) => {
  const icon = getFieldIcon(fieldIcon);
  const input = (
    <input
      autoComplete={autoComplete}
      type={type}
      onChange={onChange}
      name={name}
      placeholder={placeholder}
      readOnly={readOnly}
      value={value}
      aria-label={name}
      data-lpignore="true"
    />
  );
  return (
    <label className={css.field} htmlFor={name} data-testid={testId}>
      <FormattedMessage id={labelId} values={labelValues} />
      {required && <span className={css.required}>*</span>}
      {icon ? (
        <div className={css.inputContainer}>
          {input}
          {icon}
        </div>
      ) : (
        input
      )}
      {errors && errors.length > 0 && (
        <ul className={css.errorList} data-testid={TEST_ID_FORM_FIELD_ERRORS}>
          {errors.map(error => (
            <li key={error}>
              <FormattedMessage id={error} />
            </li>
          ))}
        </ul>
      )}
    </label>
  );
};

export default FormField;
