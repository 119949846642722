import React from "react";

import { defaultLocale } from "../lib/i18n";
import { money } from "../utils/money";

export const TEST_ID_MONEY = "money";

interface MoneyProps {
  className?: string;
  value: string | number;
}

const Money: React.FC<MoneyProps> = ({ className, value }) => {
  if (typeof value === "string") {
    value = Number(value);
  }
  return (
    <span className={className} data-testid={TEST_ID_MONEY}>
      {money(value, defaultLocale)}
    </span>
  );
};

export default Money;
