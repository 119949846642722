import React from "react";

type PageLocaleContextProps = {
  children?: React.ReactNode;
  hrefs?: { [key: string]: string };
};

export const PageLocaleContext = React.createContext<PageLocaleContextProps>(
  {},
);

export const PageLocaleProvider: React.FC<PageLocaleContextProps> = ({
  children,
  hrefs,
}) => (
  <PageLocaleContext.Provider value={{ hrefs }}>
    {children}
  </PageLocaleContext.Provider>
);
