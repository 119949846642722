import * as Sentry from "@sentry/nextjs";

import { IPage } from "../../types/generated/contentful";
import {
  isAttraction,
  isLocale,
  isRestaurant,
  locales,
  removeLocaleFromPathname,
} from "../i18n";

import {
  getAttractionWithLocaleBySlug,
  getPageWithLocaleBySlug,
  getRestaurantWithLocaleBySlug,
  PageLikeEntry,
} from ".";

export interface LocalizedEntryFields {
  slug: { [key: string]: string };
  parentSlug?: { [key: string]: string };
  parentContent?: { [key: string]: IPage };
}

const convertEntry = (entry: PageLikeEntry): LocalizedEntryFields =>
  entry.fields as unknown as LocalizedEntryFields;

const mapLocalizedEntryLink = (
  fields: LocalizedEntryFields,
  locale: string,
): string[] => {
  const structure = [fields.slug[locale]];
  if (fields.parentContent && fields.parentContent[locale]) {
    structure.push(
      mapLocalizedEntryLink(
        convertEntry(fields.parentContent[locale]),
        locale,
      )[0],
    );
  }
  return structure;
};

const buildLocalizedSlug = (entry: PageLikeEntry, locale: string): string[] => {
  const fields = convertEntry(entry);
  if (!fields?.slug[locale]) {
    return [];
  }

  if (fields.parentSlug && fields.parentSlug[locale]) {
    return fields.parentSlug[locale].endsWith(fields.slug[locale])
      ? [fields.parentSlug[locale]]
      : [fields.parentSlug[locale], fields.slug[locale]];
  }

  if (fields.parentContent) {
    const parentLocaleKey = fields.parentContent[locale] ? locale : "fi";

    if (fields.parentContent[parentLocaleKey]) {
      const structure = mapLocalizedEntryLink(
        convertEntry(fields.parentContent[parentLocaleKey]),
        locale,
      );
      structure.unshift(fields.slug[locale]);

      return structure.reverse();
    } else {
      Sentry.captureMessage(
        `Entry ${entry.sys.id} has parentContent, but couldn't parse it for locale "${locale}"`,
      );
    }
  }

  return [fields.slug[locale]];
};

export const buildLocalizedEntryLink = (
  entry: PageLikeEntry,
  locale: string,
): string => {
  if (isLocale(locale)) {
    return buildLocalizedSlug(entry, locale).join("/");
  } else {
    Sentry.captureMessage(
      `Invalid locale "${locale}" when building localized entry (id: ${entry.sys.id}) link`,
    );
    return "";
  }
};

export const getPageHrefsBySlug = async (
  query: string[] | string,
  slug: string,
  currentLocale: string,
  preview = false,
): Promise<{ [key: string]: string }> => {
  let entry: PageLikeEntry;
  if (isAttraction(query)) {
    entry = await getAttractionWithLocaleBySlug(slug, currentLocale, preview);
  } else if (isRestaurant(query)) {
    entry = await getRestaurantWithLocaleBySlug(slug, currentLocale, preview);
  } else {
    entry = await getPageWithLocaleBySlug(slug, currentLocale, preview);
  }

  return {
    fi: buildLocalizedEntryLink(entry, "fi"),
    en: buildLocalizedEntryLink(entry, "en"),
  };
};

export const getPageHrefsFromPathname = (
  pathname: string,
): { [key: string]: string } =>
  locales.reduce((acc, locale) => {
    return {
      ...acc,
      [locale]: removeLocaleFromPathname(pathname).slice(1),
    };
  }, {});
