import React, { useCallback, useContext, useState } from "react";
import { useIntl } from "react-intl";

import IconChevronDown from "../../public/svg/icon-chevron-down.svg";
import IconChevronUp from "../../public/svg/icon-chevron-up.svg";
import { ThemeContext } from "../contexts/ThemeContext";
import { MenuItem } from "../lib/contentful/build-menu";

import LocaleLink from "./LocaleLink";

export const TEST_ID_SUB_MENU_ITEM = "header-mobile-menu-list-sub-menu-item";

interface HeaderMobileMenuListSubMenuProps {
  menuItem: MenuItem;
  useArrowIcons?: boolean;
}

const HeaderMobileMenuListSubMenu: React.FC<
  HeaderMobileMenuListSubMenuProps
> = ({ menuItem }) => {
  const { styles } = useContext(ThemeContext);
  const { formatMessage } = useIntl();
  const [menuIsOpen, setmenuIsOpen] = useState(false);

  const handleMenuIsOpen = useCallback((): void => {
    setmenuIsOpen(!menuIsOpen);
  }, [setmenuIsOpen, menuIsOpen]);

  return (
    <ul className="list ma0 pa0">
      <li>
        <button
          className="flex items-center bg-transparent bw0 pointer ph0 pv2 color-inherit w-100 underline-hover has-children-menu-list-link svg-icon-large stroke-current-color fill-transparent"
          aria-label={formatMessage({
            id: menuIsOpen ? "menu.close" : "menu.open",
          })}
          onClick={handleMenuIsOpen}
          id={menuItem.name}
          data-testid="children-toggle"
        >
          <span
            className={`${styles.heading2} no-underline color-inherit db pr2`}
          >
            {menuItem.name}
          </span>
          {menuIsOpen ? <IconChevronUp /> : <IconChevronDown />}
        </button>
        <ul
          className={`list mt4 ml4 mb5 pa0 ${menuIsOpen ? "db" : "dn"}`}
          data-testid="children-menu"
        >
          {!menuItem.useOnlyAsLabel && (
            <li className="pv2 mb3" key={menuItem.name}>
              <LocaleLink href={menuItem.href} slug={menuItem.slug}>
                <a
                  className={`${styles.heading3} no-underline color-inherit underline-hover db`}
                  target={menuItem.openInNewWindow ? "_blank" : undefined}
                  data-testid={TEST_ID_SUB_MENU_ITEM}
                >
                  {menuItem.name}
                </a>
              </LocaleLink>
            </li>
          )}
          {menuItem.items
            ?.filter(subMenuItem => !subMenuItem.useOnlyAsLabel)
            .map(subMenuItem => (
              <li className="pv2 mb3" key={subMenuItem.name}>
                <LocaleLink href={subMenuItem.href} slug={subMenuItem.slug}>
                  <a
                    className={`${styles.heading3} no-underline color-inherit underline-hover db`}
                    target={subMenuItem.openInNewWindow ? "_blank" : undefined}
                    data-testid={TEST_ID_SUB_MENU_ITEM}
                  >
                    {subMenuItem.name}
                  </a>
                </LocaleLink>
              </li>
            ))}
        </ul>
      </li>
    </ul>
  );
};

export default HeaderMobileMenuListSubMenu;
