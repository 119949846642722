export type Environment = "development" | "staging" | "production" | "jest";

const environments: Environment[] = [
  "development",
  "staging",
  "production",
  "jest",
];

type WebsiteEnv = {
  COGNITO_REGION: string;
  CONSUMER_COGNITO_DOMAIN: string;
  CONSUMER_USER_POOL_ID: string;
  CONSUMER_USER_POOL_WEB_CLIENT_ID: string;
  CONTENTFUL_CONTENT_PREVIEW_API_ACCESS_TOKEN: string;
  CONTENTFUL_CONTENT_PREVIEW_API_HOST: string;
  CONTENTFUL_DELIVERY_API_ACCESS_TOKEN: string;
  CONTENTFUL_DELIVERY_API_HOST: string;
  CONTENTFUL_ENVIRONMENT: string;
  CONTENTFUL_SPACE_ID: string;
  ENVIRONMENT: Environment;
  GOOGLE_TAG_MANAGER_ID?: string;
  MAINTENANCE: boolean;
  ORGANIZATION_COGNITO_DOMAIN: string;
  ORGANIZATION_USER_POOL_WEB_CLIENT_ID: string;
  PANDECTES_STORE_ID: string;
  SARKANNIEMI_API_URI: string;
  SARKANNIEMI_HOST: string;
  SHOPIFY_ACCESS_TOKEN: string;
  SHOPIFY_DOMAIN: string;
  SHOPIFY_GRAPHQL_URI: string;
  SHOPIFY_SHOP_NAME: string;
  SHOPIFY_STOREFRONT_API_VERSION: string;
};

const COGNITO_REGION = process.env.COGNITO_REGION;
const CONSUMER_COGNITO_DOMAIN = process.env.CONSUMER_COGNITO_DOMAIN;
const CONSUMER_USER_POOL_ID = process.env.CONSUMER_USER_POOL_ID;
const CONSUMER_USER_POOL_WEB_CLIENT_ID =
  process.env.CONSUMER_USER_POOL_WEB_CLIENT_ID;
const CONTENTFUL_CONTENT_PREVIEW_API_ACCESS_TOKEN =
  process.env.CONTENTFUL_CONTENT_PREVIEW_API_ACCESS_TOKEN;
const CONTENTFUL_CONTENT_PREVIEW_API_HOST =
  process.env.CONTENTFUL_CONTENT_PREVIEW_API_HOST;
const CONTENTFUL_DELIVERY_API_ACCESS_TOKEN =
  process.env.CONTENTFUL_DELIVERY_API_ACCESS_TOKEN;
const CONTENTFUL_DELIVERY_API_HOST = process.env.CONTENTFUL_DELIVERY_API_HOST;
const CONTENTFUL_ENVIRONMENT = process.env.CONTENTFUL_ENVIRONMENT;
const CONTENTFUL_SPACE_ID = process.env.CONTENTFUL_SPACE_ID;
const ENVIRONMENT = process.env.ENVIRONMENT;
const GOOGLE_TAG_MANAGER_ID = process.env.GOOGLE_TAG_MANAGER_ID;
const MAINTENANCE = process.env.MAINTENANCE;
const ORGANIZATION_COGNITO_DOMAIN = process.env.ORGANIZATION_COGNITO_DOMAIN;
const ORGANIZATION_USER_POOL_WEB_CLIENT_ID =
  process.env.ORGANIZATION_USER_POOL_WEB_CLIENT_ID;
const PANDECTES_STORE_ID = process.env.PANDECTES_STORE_ID;
const SARKANNIEMI_API_URI = process.env.SARKANNIEMI_API_URI;
const SARKANNIEMI_HOST = process.env.SARKANNIEMI_HOST;
const SHOPIFY_ACCESS_TOKEN = process.env.SHOPIFY_ACCESS_TOKEN;
const SHOPIFY_DOMAIN = process.env.SHOPIFY_DOMAIN;
const SHOPIFY_SHOP_NAME = process.env.SHOPIFY_SHOP_NAME;
const SHOPIFY_STOREFRONT_API_VERSION =
  process.env.SHOPIFY_STOREFRONT_API_VERSION;

if (COGNITO_REGION === undefined) {
  throw new Error("Missing environment variable COGNITO_REGION");
}

if (CONSUMER_COGNITO_DOMAIN === undefined) {
  throw new Error("Missing environment variable CONSUMER_COGNITO_DOMAIN");
}

if (CONSUMER_USER_POOL_ID === undefined) {
  throw new Error("Missing environment variable CONSUMER_USER_POOL_ID");
}

if (CONSUMER_USER_POOL_WEB_CLIENT_ID === undefined) {
  throw new Error(
    "Missing environment variable CONSUMER_USER_POOL_WEB_CLIENT_ID",
  );
}

if (ENVIRONMENT === undefined) {
  throw new Error("Missing environment variable ENVIRONMENT");
} else if (!environments.includes(ENVIRONMENT as Environment)) {
  throw new Error(`Unsupported environment ${ENVIRONMENT}`);
}

if (CONTENTFUL_CONTENT_PREVIEW_API_ACCESS_TOKEN === undefined) {
  throw new Error(
    "Missing environment variable CONTENTFUL_CONTENT_PREVIEW_API_ACCESS_TOKEN",
  );
}

if (CONTENTFUL_CONTENT_PREVIEW_API_HOST === undefined) {
  throw new Error(
    "Missing environment variable CONTENTFUL_CONTENT_PREVIEW_API_HOST",
  );
}

if (CONTENTFUL_DELIVERY_API_ACCESS_TOKEN === undefined) {
  throw new Error(
    "Missing environment variable CONTENTFUL_DELIVERY_API_ACCESS_TOKEN",
  );
}

if (CONTENTFUL_DELIVERY_API_HOST === undefined) {
  throw new Error("Missing environment variable CONTENTFUL_DELIVERY_API_HOST");
}

if (CONTENTFUL_SPACE_ID === undefined) {
  throw new Error("Missing environment variable CONTENTFUL_SPACE_ID");
}

if (CONTENTFUL_ENVIRONMENT === undefined) {
  throw new Error("Missing environment variable CONTENTFUL_ENVIRONMENT");
}

if (ORGANIZATION_COGNITO_DOMAIN === undefined) {
  throw new Error("Missing environment variable ORGANIZATION_COGNITO_DOMAIN");
}

if (ORGANIZATION_USER_POOL_WEB_CLIENT_ID === undefined) {
  throw new Error(
    "Missing environment variable ORGANIZATION_USER_POOL_WEB_CLIENT_ID",
  );
}

if (PANDECTES_STORE_ID === undefined) {
  throw new Error("Missing environment variable PANDECTES_STORE_ID");
}

if (SARKANNIEMI_API_URI === undefined) {
  throw new Error("Missing environment variable SARKANNIEMI_API_URI");
}

if (SARKANNIEMI_HOST === undefined) {
  throw new Error("Missing environment variable SARKANNIEMI_HOST");
}

if (SHOPIFY_ACCESS_TOKEN === undefined) {
  throw new Error("Missing environment variable SHOPIFY_ACCESS_TOKEN");
}

if (SHOPIFY_DOMAIN === undefined) {
  throw new Error("Missing environment variable SHOPIFY_DOMAIN");
}

if (SHOPIFY_SHOP_NAME === undefined) {
  throw new Error("Missing environment variable SHOPIFY_SHOP_NAME");
}

if (SHOPIFY_STOREFRONT_API_VERSION === undefined) {
  throw new Error(
    "Missing environment variable SHOPIFY_STOREFRONT_API_VERSION",
  );
}

export const env: WebsiteEnv = {
  COGNITO_REGION,
  CONSUMER_COGNITO_DOMAIN,
  CONSUMER_USER_POOL_ID,
  CONSUMER_USER_POOL_WEB_CLIENT_ID,
  CONTENTFUL_CONTENT_PREVIEW_API_ACCESS_TOKEN,
  CONTENTFUL_CONTENT_PREVIEW_API_HOST,
  CONTENTFUL_DELIVERY_API_ACCESS_TOKEN,
  CONTENTFUL_DELIVERY_API_HOST,
  CONTENTFUL_ENVIRONMENT,
  CONTENTFUL_SPACE_ID,
  ENVIRONMENT: ENVIRONMENT as Environment,
  GOOGLE_TAG_MANAGER_ID,
  MAINTENANCE: MAINTENANCE === "true",
  ORGANIZATION_COGNITO_DOMAIN,
  ORGANIZATION_USER_POOL_WEB_CLIENT_ID,
  PANDECTES_STORE_ID,
  SARKANNIEMI_API_URI,
  SARKANNIEMI_HOST,
  SHOPIFY_ACCESS_TOKEN,
  SHOPIFY_DOMAIN,
  SHOPIFY_GRAPHQL_URI: `https://${SHOPIFY_DOMAIN}/api/${SHOPIFY_STOREFRONT_API_VERSION}/graphql.json`,
  SHOPIFY_SHOP_NAME,
  SHOPIFY_STOREFRONT_API_VERSION,
};
