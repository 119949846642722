import { defaultLocale } from "../lib/i18n";

export const money = (
  amount: number,
  locale: string = defaultLocale,
): string => {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: "EUR",
  }).format(amount);
};
