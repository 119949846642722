import { ScriptProps } from "next/script";

import { Environment } from "./environment";
import { CookieCategory } from "./src/contexts/CookieConsentContext";

export type CustomScript = ScriptProps & {
  id: string;
  cookieCategories: CookieCategory[];
  allowedEnvironments: Environment[];
};

export function filterScripts(
  scripts: CustomScript[],
  givenPermissions: CookieCategory[],
  environment: Environment,
): CustomScript[] {
  return scripts.filter(
    ({ allowedEnvironments, cookieCategories }) =>
      allowedEnvironments.includes(environment) &&
      cookieCategories.every(cookieCategory =>
        givenPermissions.includes(cookieCategory),
      ),
  );
}

const leadoo: CustomScript = {
  id: "leadoo-dynamic-script",
  allowedEnvironments: ["staging", "production"],
  cookieCategories: ["C0000"],
  src: "https://bot.leadoo.com/bot/dynamic.js?company=DYbONLZw&mode=spa",
  strategy: "afterInteractive",
};

export const injectedScripts: CustomScript[] = [leadoo];
