import React from "react";

import css from "./LoginModalContainer.module.scss";

interface LoginModalContainerProps {
  children?: React.ReactNode;
}

const LoginModalContainer: React.FC<LoginModalContainerProps> = ({
  children,
}) => {
  return <div className={css.container}>{children}</div>;
};

export default LoginModalContainer;
