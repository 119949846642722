import { DateTime } from "luxon";
import React from "react";
import { useIntl } from "react-intl";

import { Tag } from "../../ecommerce/product";

export const TEST_ID_PRODUCT_VARIANT_NAME = "product-variant-name";

interface ProductVariantNameProps {
  validityDate?: DateTime;
  minimumHeight?: number;
  maximumHeight?: number;
  tags?: string[];
}

const ProductVariantName: React.FC<ProductVariantNameProps> = ({
  validityDate,
  minimumHeight,
  maximumHeight,
  tags,
}) => {
  const { formatMessage, locale } = useIntl();
  const names = [];

  if (minimumHeight) {
    names.push(
      formatMessage({ id: "cart.heightOver" }, { value: minimumHeight }),
    );
  }

  if (maximumHeight) {
    names.push(
      formatMessage({ id: "cart.heightUnder" }, { value: maximumHeight }),
    );
  }

  if (validityDate?.isValid) {
    names.push(
      formatMessage(
        { id: "cart.fixedDay" },
        {
          value: validityDate.toLocaleString(
            {
              dateStyle: "medium",
              timeStyle: tags?.includes(Tag.TIME_SELECTION)
                ? "short"
                : undefined,
            },
            { locale },
          ),
        },
      ),
    );
  }

  return names.length ? (
    <span className="db mt1 lh-copy" data-testid={TEST_ID_PRODUCT_VARIANT_NAME}>
      {names.join(", ")}
    </span>
  ) : null;
};

export default ProductVariantName;
