import classNames from "classnames";
import { Router } from "next/router";
import React, { useCallback, useContext, useEffect, useState } from "react";

import { ThemeContext } from "../contexts/ThemeContext";
import { Menu } from "../lib/contentful/build-menu";

import HeaderDesktopMenuListSubMenu from "./HeaderDesktopMenuListSubMenu";
import LocaleLink from "./LocaleLink";

export const TEST_ID_MENU_ITEM = "header-desktop-menu-list-item";

interface HeaderDesktopMenuListProps {
  menu: Menu;
}

const HeaderDesktopMenuList: React.FC<HeaderDesktopMenuListProps> = ({
  menu,
}) => {
  const [openMenuIndex, setOpenMenuIndex] = useState<number | null>(null);
  const { styles } = useContext(ThemeContext);

  const handleMenuOpen = useCallback((index: number | null) => {
    setOpenMenuIndex(index);
  }, []);

  const onRouteChangeStart = useCallback(() => {
    if (openMenuIndex !== null) {
      setOpenMenuIndex(null);
    }
  }, [openMenuIndex]);

  useEffect(() => {
    Router.events.on("routeChangeStart", onRouteChangeStart);

    return (): void => {
      Router.events.off("routeChangeStart", onRouteChangeStart);
    };
  }, [onRouteChangeStart]);

  return (
    <ul className="list ma0 pa0 ml3 items-center flex">
      {menu.items.map((item, index) => (
        <li
          className={classNames("dib", {
            mr4: index !== menu.items.length - 1,
            mr3: index === menu.items.length - 1,
          })}
          key={item.name}
        >
          {item.items && item.items.length > 0 ? (
            <HeaderDesktopMenuListSubMenu
              menuItem={item}
              handleMenuOpen={handleMenuOpen}
              index={index}
              isOpen={openMenuIndex === index}
            />
          ) : (
            <LocaleLink href={item.href} slug={item.slug}>
              <a
                className={classNames(
                  styles.navItem,
                  styles.navItemHeader,
                  "tc",
                )}
                target={item.openInNewWindow ? "_blank" : undefined}
                data-testid={TEST_ID_MENU_ITEM}
              >
                {item.name}
              </a>
            </LocaleLink>
          )}
        </li>
      ))}
    </ul>
  );
};

export default HeaderDesktopMenuList;
