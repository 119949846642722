import {
  CognitoHostedUIIdentityProvider,
  CognitoUser,
} from "@aws-amplify/auth";
import { HubCallback } from "@aws-amplify/core";
import * as Sentry from "@sentry/nextjs";
import { CognitoIdToken, ISignUpResult } from "amazon-cognito-identity-js";
import { Auth, Hub } from "aws-amplify";
import { decode } from "jsonwebtoken";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import LoginModal from "../components/login-modal/LoginModal";

type UserOrToken =
  | {
      cognitoUser: CognitoUser;
      encodedIdToken?: never;
    }
  | {
      cognitoUser?: never;
      encodedIdToken: string;
    };

export type User = {
  email: string;
  idToken?: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  id: string;
};

type CustomOAuthState = {
  redirectTo: string;
};

function onUnhandledException(message: string) {
  Sentry.captureMessage(`Unhandled Cognito error: ${message}`, {
    extra: {
      message,
    },
  });
}

function getUser({ cognitoUser, encodedIdToken }: UserOrToken): User | null {
  let payload: CognitoIdToken["payload"];
  let idToken = encodedIdToken;

  if (cognitoUser) {
    const session = cognitoUser.getSignInUserSession();
    if (!session) {
      return null;
    }

    const cognitoIdToken = session.getIdToken();
    payload = cognitoIdToken.payload;
    idToken = cognitoIdToken.getJwtToken();
  } else {
    const decodedToken = decode(encodedIdToken);
    if (decodedToken === null) {
      return null;
    } else {
      payload = decodedToken as CognitoIdToken["payload"];
    }
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { email, given_name, family_name, sub } = payload;
  let firstName: string | undefined = given_name;
  let lastName: string | undefined = family_name;

  if (cognitoUser && "attributes" in cognitoUser) {
    const attributes = cognitoUser.attributes as Record<string, unknown>;

    if (
      typeof attributes.given_name === "string" &&
      typeof attributes.family_name === "string"
    ) {
      firstName = attributes.given_name;
      lastName = attributes.family_name;
    }
  }

  return {
    email,
    id: sub,
    idToken,
    ...(firstName !== undefined && { firstName }),
    ...(lastName !== undefined && { lastName }),
    ...(firstName !== undefined &&
      lastName !== undefined && {
        fullName: `${firstName} ${lastName}`,
      }),
  };
}

type SignUpData = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
};

export type CommonError =
  | "auth.error.unknown"
  | "auth.error.attemptLimitExceeded";
type CodeError = "auth.error.wrongCode";
export type SignUpError =
  | CommonError
  | "auth.error.username.empty"
  | "auth.error.username.notEmail"
  | "auth.error.username.alreadyExists"
  | "auth.error.password.lengthInsufficient"
  | "auth.error.password.lowercaseMissing"
  | "auth.error.password.numberMissing"
  | "auth.error.password.uppercaseMissing";
export type SignUpConfirmError = CommonError | CodeError;
export type SignInError =
  | CommonError
  | "auth.error.incorrectCredentials"
  | "auth.error.username.empty"
  | "auth.error.username.notEmail"
  | "auth.error.username.notVerified";
export type ForgotPasswordError =
  | CommonError
  | CodeError
  | "auth.error.username.empty"
  | "auth.error.username.notEmail";

type AuthenticationContextProps = {
  closeModal: () => void;
  confirmSignUp: (
    email: string,
    code: string,
  ) => Promise<SignUpConfirmError | null>;
  deleteUser: () => Promise<void>;
  federatedSignIn: (provider: "apple" | "google") => Promise<void>;
  forgotPassword: (email: string) => Promise<ForgotPasswordError | null>;
  forgotPasswordSubmit: (
    email: string,
    newPassword: string,
    verificationCode: string,
  ) => Promise<ForgotPasswordError | null>;
  openModal: () => void;
  resendSignUpCode: (email: string) => Promise<CommonError | null>;
  signIn: (email: string, password: string) => Promise<SignInError | null>;
  signInWithToken: (idToken: string) => void;
  signOut: () => Promise<void>;
  signUp: (data: SignUpData) => Promise<ISignUpResult | SignUpError | null>;
  isModalOpen?: boolean;
  user: User | null;
};

export const AuthenticationContext =
  React.createContext<AuthenticationContextProps>({
    closeModal: () => undefined,
    confirmSignUp: () => new Promise(() => undefined),
    deleteUser: () => new Promise(() => undefined),
    federatedSignIn: () => new Promise(() => undefined),
    forgotPassword: () => new Promise(() => undefined),
    forgotPasswordSubmit: () => new Promise(() => undefined),
    openModal: () => undefined,
    resendSignUpCode: () => new Promise(() => undefined),
    signIn: () => new Promise(() => undefined),
    signInWithToken: () => undefined,
    signOut: () => new Promise(() => undefined),
    signUp: () => new Promise(() => undefined),
    user: null,
  });

interface AuthenticationProviderProps {
  children?: React.ReactNode;
  contextProps?: Partial<AuthenticationContextProps>;
  loginModal?: React.ReactNode;
}

const AuthenticationProvider: React.FC<AuthenticationProviderProps> = ({
  children,
  contextProps,
  loginModal,
}) => {
  const { locale } = useIntl();
  const router = useRouter();
  const [user, setUser] = useState<User | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(
    contextProps?.isModalOpen ?? false,
  );
  const closeModal = () => setIsModalOpen(false);
  const openModal = () => setIsModalOpen(true);

  const confirmSignUp = async (
    email: string,
    code: string,
  ): Promise<SignUpConfirmError | null> => {
    try {
      await Auth.confirmSignUp(email, code);
      return null;
    } catch (error) {
      if (error instanceof Error) {
        const { message } = error;
        if (message.includes("Invalid verification code provided")) {
          return "auth.error.wrongCode";
        } else {
          onUnhandledException(message);
          console.log(error);
        }
      }
      return "auth.error.unknown";
    }
  };

  const resendSignUpCode = async (
    email: string,
  ): Promise<CommonError | null> => {
    try {
      await Auth.resendSignUp(email);
      return null;
    } catch (error) {
      if (error instanceof Error) {
        onUnhandledException(error.message);
        console.log(error);
      }
      return "auth.error.unknown";
    }
  };

  const signIn = useCallback(
    async (email: string, password: string): Promise<SignInError | null> => {
      try {
        await Auth.signIn(email, password);
        return null;
      } catch (error) {
        if (error instanceof Error) {
          const { message } = error;
          if (message.includes("Username should be an email")) {
            return "auth.error.username.notEmail";
          } else if (message.includes("Incorrect username or password")) {
            return "auth.error.incorrectCredentials";
          } else if (message.includes("Password attempts exceeded")) {
            return "auth.error.attemptLimitExceeded";
          } else if (message.includes("User is not confirmed")) {
            return "auth.error.username.notVerified";
          } else if (message.includes("Username cannot be empty")) {
            return "auth.error.username.empty";
          } else {
            onUnhandledException(message);
            console.log(error);
          }
        }
        return "auth.error.unknown";
      }
    },
    [],
  );

  const signInWithToken = useCallback((encodedIdToken: string) => {
    handleSignIn({ encodedIdToken });
  }, []);

  const federatedSignIn = useCallback(
    async (supportedProvider: "apple" | "google") => {
      try {
        let provider: CognitoHostedUIIdentityProvider;
        switch (supportedProvider) {
          case "apple": {
            provider = CognitoHostedUIIdentityProvider.Apple;
            break;
          }
          case "google": {
            provider = CognitoHostedUIIdentityProvider.Google;
          }
        }

        const customState: CustomOAuthState = {
          redirectTo: router.asPath,
        };

        await Auth.federatedSignIn({
          provider,
          customState: JSON.stringify(customState),
        });
      } catch (error) {
        if (error instanceof Error) {
          onUnhandledException(error.message);
          console.log(error);
        }
      }
    },
    [router.asPath],
  );

  const signOut = useCallback(async (): Promise<void> => {
    try {
      await Auth.signOut({ global: true });
      setUser(null);
    } catch (error) {
      if (error instanceof Error) {
        onUnhandledException(error.message);
        console.log(error);
      }
      setUser(null);
    }
  }, []);

  const signUp = useCallback(
    async ({
      email,
      password,
      firstName,
      lastName,
    }: SignUpData): Promise<ISignUpResult | SignUpError> => {
      try {
        const result = await Auth.signUp({
          username: email,
          password,
          attributes: {
            given_name: firstName,
            family_name: lastName,
          },
          autoSignIn: {
            enabled: true,
          },
        });
        return result;
      } catch (error) {
        if (error instanceof Error) {
          const { message } = error;
          if (message.includes("Username should be an email")) {
            return "auth.error.username.notEmail";
          } else if (
            message.includes("An account with the given email already exists")
          ) {
            return "auth.error.username.alreadyExists";
          } else if (message.includes("Password not long enough")) {
            return "auth.error.password.lengthInsufficient";
          } else if (
            message.includes("Password must have uppercase characters")
          ) {
            return "auth.error.password.uppercaseMissing";
          } else if (
            message.includes("Password must have lowercase characters")
          ) {
            return "auth.error.password.lowercaseMissing";
          } else if (
            message.includes("Password must have numeric characters")
          ) {
            return "auth.error.password.numberMissing";
          } else {
            onUnhandledException(message);
            console.log(error);
          }
        }
        return "auth.error.unknown";
      }
    },
    [],
  );

  const forgotPassword = useCallback(
    async (email: string): Promise<ForgotPasswordError | null> => {
      try {
        await Auth.forgotPassword(email);
        return null;
      } catch (error) {
        if (error instanceof Error) {
          const { message } = error;
          if (message.includes("Attempt limit exceeded")) {
            return "auth.error.attemptLimitExceeded";
          } else {
            onUnhandledException(message);
            console.log(error);
          }
        }
        return "auth.error.unknown";
      }
    },
    [],
  );

  const forgotPasswordSubmit = useCallback(
    async (
      email: string,
      password: string,
      verificationCode: string,
    ): Promise<ForgotPasswordError | null> => {
      try {
        await Auth.forgotPasswordSubmit(email, verificationCode, password);
        return null;
      } catch (error) {
        if (error instanceof Error) {
          const { message } = error;
          if (message.includes("Invalid verification code provided")) {
            return "auth.error.wrongCode";
          } else if (message.includes("Attempt limit exceeded")) {
            return "auth.error.attemptLimitExceeded";
          } else {
            onUnhandledException(message);
            console.log(error);
          }
        }
        return "auth.error.unknown";
      }
    },
    [],
  );

  const deleteUser = useCallback(async (): Promise<void> => {
    try {
      await Auth.deleteUser();
      setUser(null);
    } catch (error) {
      if (error instanceof Error) {
        const { message } = error;
        onUnhandledException(message);
      }
    }
  }, []);

  const handleSignIn = ({ cognitoUser, encodedIdToken }: UserOrToken): void => {
    const user = getUser(cognitoUser ? { cognitoUser } : { encodedIdToken });
    if (user) {
      setUser(user);
    }
  };

  const authListener: HubCallback = useCallback(
    ({ payload: { event, data } }) => {
      switch (event) {
        case "autoSignIn":
        case "signIn": {
          handleSignIn({ cognitoUser: data });
          break;
        }
        case "customOAuthState": {
          try {
            const state = JSON.parse(data) as CustomOAuthState;
            if (state?.redirectTo !== location.pathname) {
              console.log(`Redirecting to ${state.redirectTo}`);
              router.push(state.redirectTo);
            }
          } catch (error) {
            return;
          }
          break;
        }
        default: {
          break;
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale],
  );

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", authListener);

    Auth.currentAuthenticatedUser()
      .then(cognitoUser => handleSignIn({ cognitoUser }))
      // User not signed-in
      .catch(() => undefined);

    return unsubscribe;
  }, [authListener]);

  useEffect(() => {
    /*
     * Amplify does not remove OAuth params from the URL
     * so they need to be removed manually
     */
    if (
      router.query.code ||
      router.query.state ||
      router.query.error ||
      router.query.error_description
    ) {
      const { pathname, query } = router;
      delete query.code;
      delete query.state;
      delete query.error;
      delete query.error_description;
      router.replace({ pathname, query }, undefined, { shallow: true });
    }
  }, [router]);

  return (
    <AuthenticationContext.Provider
      value={{
        closeModal,
        confirmSignUp,
        deleteUser,
        federatedSignIn,
        forgotPassword,
        forgotPasswordSubmit,
        openModal,
        resendSignUpCode,
        signIn,
        signInWithToken,
        signOut,
        signUp,
        user,
        ...contextProps,
      }}
    >
      {children}
      {loginModal ?? <LoginModal isOpen={isModalOpen} onClose={closeModal} />}
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationProvider;
