import { ApolloError, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/nextjs";
import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import { MutationToCheckoutLineItemsReplaceArgs } from "shopify-storefront-api-typings";

import { CHECKOUT_LINE_ITEMS_REPLACE } from "../graphql/checkout";
import { Checkout, CheckoutLineItemsReplacePayload } from "../types/shopify";

export type UseCheckoutUpdateData = {
  checkoutLineItemsReplace?: CheckoutLineItemsReplacePayload;
};
export type UseCheckoutUpdateVariables = MutationToCheckoutLineItemsReplaceArgs;

type UseCheckoutUpdate = {
  data?: CheckoutLineItemsReplacePayload | null | undefined;
  error: ApolloError | undefined;
  loading: boolean;
  updateCheckout: (
    args: UseCheckoutUpdateVariables,
  ) => Promise<Checkout | null | undefined>;
};

const useCheckoutUpdate = (): UseCheckoutUpdate => {
  const [mutation, { loading, error: apolloError, data }] = useMutation<
    UseCheckoutUpdateData,
    UseCheckoutUpdateVariables
  >(CHECKOUT_LINE_ITEMS_REPLACE);

  if (apolloError) {
    Sentry.captureException(apolloError);
  }

  const updateCheckout = useCallback(
    async ({
      checkoutId,
      lineItems,
    }: UseCheckoutUpdateVariables): Promise<Checkout | null | undefined> => {
      if (checkoutId) {
        try {
          const { data, errors: mutationErrors } = await mutation({
            variables: {
              checkoutId,
              lineItems,
            },
          });

          if (data?.checkoutLineItemsReplace?.userErrors.length) {
            data.checkoutLineItemsReplace.userErrors.forEach(userError => {
              Sentry.captureException(userError);
            });

            return null;
          }

          if (mutationErrors?.length) {
            mutationErrors.forEach(mutationError => {
              Sentry.captureException(mutationError);
            });

            return null;
          }
          return data?.checkoutLineItemsReplace?.checkout;
        } catch (error) {
          toast.error(
            <p data-testid="toast">
              <FormattedMessage id="cart.errorOnUpdatingCart" />
            </p>,
          );

          if (
            error instanceof Error &&
            error.message?.includes("Too many requests")
          ) {
            Sentry.captureMessage("Checkout update: Too many requests", {
              level: "error",
            });
          } else {
            Sentry.captureException(error);
          }
          return null;
        }
      }

      return null;
    },
    [mutation],
  );

  return {
    data: data?.checkoutLineItemsReplace,
    error: apolloError,
    loading: typeof window === "undefined" ? false : loading,
    updateCheckout,
  };
};

export default useCheckoutUpdate;
