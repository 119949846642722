import React from "react";
import { IntlProvider } from "react-intl";

import { Locale } from "../lib/i18n";
import messagesEn from "../translations/en.json";
import messagesFi from "../translations/fi.json";

interface Message {
  [key: string]: string;
}

interface Messages {
  [key: string]: Message;
}

const messages: Messages = {
  en: messagesEn,
  fi: messagesFi,
};

interface WithLocaleProps {
  children?: React.ReactNode;
  customMessages?: Messages;
  locale: Locale;
}

const WithLocale: React.FC<WithLocaleProps> = ({
  children,
  customMessages,
  locale,
}): JSX.Element => {
  return (
    <IntlProvider
      locale={locale}
      messages={customMessages ? customMessages[locale] : messages[locale]}
    >
      {children}
    </IntlProvider>
  );
};

export default WithLocale;
