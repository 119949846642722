import React, { ReactNode, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { SignInError } from "../../contexts/AuthenticationContext";

import Button from "./Button";
import ErrorText from "./ErrorText";
import FormField from "./FormField";
import { CommonViewProps } from "./LoginModal";
import css from "./LoginModal.module.scss";
import LoginModalContainer from "./LoginModalContainer";
import LoginModalContent from "./LoginModalContent";
import LoginModalHeader from "./LoginModalHeader";

export const TEST_ID_SIGN_IN_VIEW_ERROR = "sign-in-view-error";

interface SignInViewProps extends CommonViewProps {
  onAppleSignIn: () => void;
  onForgotPasswordClick: () => void;
  onGoogleSignIn: () => void;
  onSignIn: (email: string, password: string) => void;
  onVerificationClick: (email: string) => void;
  registerButton: ReactNode;
  requiresVerification: boolean;
  signInError: SignInError | null;
}

const SignInView: React.FC<SignInViewProps> = ({
  onAppleSignIn,
  onClose,
  onForgotPasswordClick,
  onGoogleSignIn,
  onSignIn,
  onVerificationClick,
  registerButton,
  requiresVerification,
  signInError,
  state,
}) => {
  const { formatMessage } = useIntl();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  const clear = () => {
    setEmail("");
    setPassword("");
  };

  const onEmailChange = (email: string) => {
    setEmail(email);
  };

  const handleClose = () => {
    clear();
    onClose();
  };

  const isValid = email !== "" && password !== "";
  const loading = state !== "idle";

  return (
    <LoginModalContainer>
      <LoginModalHeader
        style="default"
        titleId="loginModal.signIn.short"
        onClose={handleClose}
      />
      <LoginModalContent>
        <Button
          icon="google"
          textId="loginModal.signIn.google"
          onClick={onGoogleSignIn}
          disabled={loading}
          loading={state === "sign-in-google"}
        />
        <Button
          icon="apple"
          textId="loginModal.signIn.apple"
          onClick={onAppleSignIn}
          disabled={loading}
          loading={state === "sign-in-apple"}
        />
        <h3>
          <FormattedMessage id="loginModal.signIn.credentials" />
        </h3>
        <FormField
          autoComplete="email"
          labelId="forms.email"
          onChange={event => onEmailChange(event.target.value)}
          type="email"
          placeholder={formatMessage({
            id: "forms.email.placeholder",
          })}
          name="email"
          readOnly={loading || requiresVerification}
          value={email}
          required
        />
        <FormField
          autoComplete="current-password"
          labelId="forms.password"
          onChange={event => setPassword(event.target.value)}
          placeholder="************"
          type={passwordVisible ? "text" : "password"}
          name="current-password"
          readOnly={loading || requiresVerification}
          value={password}
          fieldIcon={
            passwordVisible
              ? {
                  icon: "eye-closed",
                  onClick: () => setPasswordVisible(false),
                }
              : {
                  icon: "eye-open",
                  onClick: () => setPasswordVisible(true),
                }
          }
          required
        />
        <p>
          <FormattedMessage id="loginModal.forgotPassword" />{" "}
          <button className={css.linkButton} onClick={onForgotPasswordClick}>
            <FormattedMessage id="loginModal.changePassword" />
          </button>
        </p>
        {requiresVerification ? (
          <Button
            textId="loginModal.signIn.verification"
            style="primary"
            onClick={() => onVerificationClick(email)}
            disabled={loading}
            bold
          />
        ) : (
          <Button
            textId="loginModal.signIn.short"
            style="primary"
            onClick={() => onSignIn(email, password)}
            disabled={loading || !isValid}
            loading={state === "sign-in"}
            bold
          />
        )}
        {signInError !== null && (
          <ErrorText
            errorId={signInError}
            testId={TEST_ID_SIGN_IN_VIEW_ERROR}
          />
        )}
        <h3>
          <FormattedMessage id="loginModal.noAccount" />
        </h3>
        {registerButton}
      </LoginModalContent>
    </LoginModalContainer>
  );
};

export default SignInView;
