import classNames from "classnames";
import { useRouter } from "next/router";
import React, { useCallback, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { ThemeContext } from "../../contexts/ThemeContext";
import { storeSeasonCardInSessionStorage } from "../../ecommerce/checkout";
import {
  getSeasonCardHolderName,
  getSeasonCardVariantTitle,
} from "../../ecommerce/common";
import { ProductType } from "../../ecommerce/product";
import { CheckoutItem } from "../../types/shopify-components";
import Money from "../Money";

import ProductVariantName from "./ProductVariantName";

export const testIdTitle = "mini-cart-line-item-title";
export const testIdQuantityxPrice = "mini-cart-line-item-quantity-x-price";
export const testIdTotalPrice = "mini-cart-total-price";
export const TEST_ID_SEASON_PASS_MEDIA =
  "mini-cart-line-item-season-pass-media";

interface MiniCartLineItemProps {
  item: CheckoutItem;
}

const MiniCartLineItem: React.FC<MiniCartLineItemProps> = ({ item }) => {
  const router = useRouter();
  const { styles } = useContext(ThemeContext);
  const {
    title,
    validityDate,
    minimumHeight,
    maximumHeight,
    selectedOptions,
    tags,
  } = item.variant;
  const price = Number(item.variant.price.amount);
  const quantity = item.input.quantity;

  const { locale } = useIntl();

  const redirectToEdit = useCallback(() => {
    storeSeasonCardInSessionStorage(item);
    router.push(`/${locale}/product/${item.variant.handle}`);
  }, [item, locale, router]);

  return (
    <div className="flex justify-between" data-testid="mini-cart-line-item">
      {item.variant.productType === ProductType.SEASON_CARD ? (
        <div className="w-80 pr3">
          <h3
            className={classNames(styles.paragraph2, "ma0")}
            data-testid={testIdTitle}
          >
            {title}
          </h3>
          <p
            className={classNames(styles.paragraph3, "ma0")}
            data-testid={TEST_ID_SEASON_PASS_MEDIA}
          >
            {getSeasonCardVariantTitle(selectedOptions)}
          </p>
          <p className={classNames(styles.paragraph3, "ma0")}>
            {getSeasonCardHolderName(
              item.input.customAttributes ?? [],
              item.variant.productType,
            )}
          </p>
          <button
            className={classNames(styles.textButton, styles.paragraph3, "pa0")}
            onClick={redirectToEdit}
          >
            <FormattedMessage id="seasonpass.editInfo" />
          </button>
        </div>
      ) : (
        <>
          <div className="w-50 pr3">
            <h3
              className={classNames(styles.paragraph2, "ma0")}
              data-testid={testIdTitle}
            >
              {title}
            </h3>
            <ProductVariantName
              validityDate={validityDate}
              minimumHeight={minimumHeight}
              maximumHeight={maximumHeight}
              tags={tags}
            />
          </div>
          <div className="w-30">
            <span
              className={classNames(styles.paragraph2, "db")}
              data-testid={testIdQuantityxPrice}
            >
              {quantity} x <Money value={price} />
            </span>
          </div>
        </>
      )}
      <div className="w-20 tr">
        <span
          className={classNames(styles.paragraph2, "db")}
          data-testid={testIdTotalPrice}
        >
          <Money value={quantity * price} />
        </span>
      </div>
    </div>
  );
};

export default MiniCartLineItem;
