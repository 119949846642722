export type ThemeStyle =
  | "headingHero"
  | "heading1"
  | "heading2"
  | "heading3"
  | "navItem"
  | "navItemFooter"
  | "navItemHeader"
  | "paragraph1"
  | "paragraph2"
  | "paragraph3"
  | "detail"
  | "primaryButton"
  | "primaryButtonDisabled"
  | "secondaryButton"
  | "secondaryButtonDisabled"
  | "textButton"
  | "iconButton"
  | "input"
  | "separator";

export type ThemeStylesheet = Record<ThemeStyle, string>;

const heading3 = "b f5 f4-l lh-title";
const paragraph1 = "f6 f5-l fw6 lh-copy";

const heavyCondensed = "ttu fw8 font-stretch-condensed tracked";

const baseStylesheet: ThemeStylesheet = {
  headingHero: `${heavyCondensed} f1 f-headline-l lh-title`,
  heading1: `${heavyCondensed} f3 f-subheadline-l lh-title`,
  heading2: `${heavyCondensed} f4 f2-l lh-title`,
  heading3,
  navItem: `${heavyCondensed} f7 f6-l lh-title no-underline color-inherit db`,
  navItemFooter: "color-inherit no-underline underline-hover",
  navItemHeader: "underline-hover",
  paragraph1,
  paragraph2: "f7 f6-l fw6 lh-copy",
  paragraph3: "f7 f6-l fw5 lh-copy",
  detail: "f-detail ttu fw8 tracked-5",
  primaryButton: `${heavyCondensed} f4 dib br-pill pv3 ph4 white bg-green bn no-underline pointer lh-solid tc button-text-vertical-adjustment`,
  primaryButtonDisabled: `${heavyCondensed} dib ba bw1 br-pill b--moon-gray pv3 ph4 dark-gray no-underline bg-transparent not-allowed lh-solid tc button-text-vertical-adjustment`,
  secondaryButton: `${heavyCondensed} f4 dib br-pill pv3 ph4 black bg-light-pink bn no-underline pointer lh-solid tc button-text-vertical-adjustment`,
  secondaryButtonDisabled: `${heavyCondensed} dib ba bw1 br-pill b--light-gray pv3 ph4 dark-gray bg-light-gray no-underline not-allowed lh-solid tc button-text-vertical-adjustment`,
  textButton: `${paragraph1} bn pointer bg-transparent underline hover-no-underline`,
  iconButton: "bn bg-transparent pointer",
  input: "h2 br-0 bl-0 bt-0 bb b--dark-gray f6 fw6 outline-0",
  separator: "bb br-0 bl-0 bt-0 hide-last-border bn-ns",
};

export default baseStylesheet;
