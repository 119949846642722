import * as Sentry from "@sentry/nextjs";
import React, { useEffect, useState } from "react";

import { CalendarRow } from "../types/calendar";

interface CalendarContextProps {
  calendarRows: CalendarRow[];
  hasFailed: boolean;
  isLoading: boolean;
}

export const CalendarContext = React.createContext<CalendarContextProps>({
  calendarRows: [],
  hasFailed: false,
  isLoading: true,
});

interface CalendarProviderProps {
  children?: React.ReactNode;
  contextProps?: Partial<CalendarContextProps>;
}

const CalendarProvider: React.FC<CalendarProviderProps> = ({
  children,
  contextProps,
}) => {
  const [hasFailed, setHasFailed] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [calendarRows, setCalendarRows] = useState<CalendarRow[]>([]);

  useEffect(() => {
    if (
      contextProps === undefined &&
      process.env.SARKANNIEMI_CALENDAR_API_URI
    ) {
      fetch(process.env.SARKANNIEMI_CALENDAR_API_URI)
        .then(response => {
          if (!response.ok) {
            setHasFailed(true);
            Sentry.captureMessage(
              `Calendar API responded with status ${response.status}`,
            );
            return [];
          }
          return response.json() as Promise<CalendarRow[]>;
        })
        .then(rows => {
          setCalendarRows(rows);
          return rows;
        })
        .catch(() => {
          setHasFailed(true);
          return [];
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [contextProps]);

  return (
    <CalendarContext.Provider
      value={{
        calendarRows,
        hasFailed,
        isLoading,
        ...contextProps,
      }}
    >
      {children}
    </CalendarContext.Provider>
  );
};

export default CalendarProvider;
