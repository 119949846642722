// Documentation: https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry

export const ignoreErrors = [
  // Network errors
  "Failed to fetch",
  "NetworkError when attempting to fetch resource.",
  "cancelled",
  "Kumottu",
  "Verkkoyhteys katkesi.",
  "Nätverksanslutningen förlorades.",
  "avbruten",
  "Load failed",
  // document.createEvent misuse
  "Illegal invocation",
  // iOS & Browser: Instagram
  "Can't find variable: _pcmBridgeCallbackHandler",
  "ResizeObserver loop limit exceeded",
  "ResizeObserver loop completed with undelivered notifications",
];

export const denyUrls = [
  // GTM & analytics related errors
  /googletagmanager\.com/i,
  /\/(gtm|ga|analytics)\.js/i,
  // Cookie Pro / One Trust
  /cookiepro\.com/i,
  /\/otBannerSdk\.js/i,
  /\/otSDKStub\.js/i,
  // Chrome extensions
  /chrome-extension:\/\//i,
  /extensions\//i,
  /^chrome:\/\//i,
  // Custobar
  /custobar\.com/i,
  // Firefox extensions
  /^moz-extension:\/\//i,
  /^resource:\/\//i,
  // File system
  /^file:\/\/\//i,
  // Hotjar
  /hotjar\.com/i,
  // YouTube
  /doubleclick\.net/i,
  /google\.com/i,
  /gstatic\.com/i,
  /youtube\.com/i,
];
