import classNames from "classnames";
import React from "react";
import { FormattedMessage } from "react-intl";

import css from "./Checkbox.module.scss";

interface CheckboxProps {
  checked: boolean;
  className?: string;
  labelId: string;
  name?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  readOnly?: boolean;
  required?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  className,
  labelId,
  name,
  onChange,
  readOnly,
  required,
}) => {
  return (
    <label className={classNames(css.label, className)}>
      <input
        type="checkbox"
        checked={checked}
        name={name}
        onChange={onChange}
        readOnly={readOnly}
        aria-label={name}
      />
      <span>
        <FormattedMessage id={labelId} />
        {required && <span className={css.required}>*</span>}
      </span>
    </label>
  );
};

export default Checkbox;
