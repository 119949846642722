import classNames from "classnames";
import React, { useRef, useContext } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import { FormattedMessage, useIntl } from "react-intl";

import IconCross from "../../../public/svg/icon-cross.svg";
import { CheckoutContext } from "../../contexts/CheckoutContext";
import { MiniCartVisibilityContext } from "../../contexts/MiniCartVisibilityContext";
import { ThemeContext } from "../../contexts/ThemeContext";
import {
  getProductSKUFromTags,
  ProductTagPrefix,
} from "../../ecommerce/product";
import px2rem from "../../utils/px2rem";
import LocaleLink from "../LocaleLink";

import LineItemTotal from "./LineItemTotal";
import MiniCartLineItem from "./MiniCartLineItem";

export const TEST_ID_MINI_CART = "mini-cart";
export const TEST_ID_MINI_CART_ITEM_COUNT = "mini-cart-item-count";

type ClickOutsideEventTarget = EventTarget & {
  name?: string;
  parentNode?: ClickOutsideEventTarget;
};

function nodeTreeContainsName(
  target: ClickOutsideEventTarget,
  name: string,
): boolean {
  let currentTarget: ClickOutsideEventTarget | undefined = target;
  while (currentTarget) {
    if (currentTarget.name === name) {
      return true;
    }
    currentTarget = currentTarget.parentNode;
  }
  return false;
}

const MiniCart: React.FC = () => {
  const { formatMessage } = useIntl();
  const { styles } = useContext(ThemeContext);
  const { isMiniCartVisible, hideMiniCart, toggleId } = useContext(
    MiniCartVisibilityContext,
  );
  const { checkoutItems, summaries } = useContext(CheckoutContext);

  const miniCartRef = useRef(null);

  useOnclickOutside(
    event => {
      if (isMiniCartVisible && event?.currentTarget) {
        const target = event.target as ClickOutsideEventTarget;

        if (!nodeTreeContainsName(target, toggleId)) {
          hideMiniCart();
        }
      }
    },
    {
      refs: [miniCartRef],
    },
  );

  const visibleItems = checkoutItems.filter(
    ({ variant }) =>
      getProductSKUFromTags(variant.tags)?.type !==
      ProductTagPrefix.COMPOSITE_CHILD_PRODUCT,
  );

  return (
    <div
      className={classNames(
        "fixed right-0 bg-white shadow-1 z-9999 dn",
        isMiniCartVisible ? "db-ns" : "dn",
      )}
      style={{ width: px2rem(440) }}
      ref={miniCartRef}
      data-testid={TEST_ID_MINI_CART}
    >
      <div className="pt4 pr3 pl4">
        <div className="flex items-center justify-between">
          <h2 className={classNames(styles.heading2, "ma0")}>
            <FormattedMessage id="cart.yourCart" />
          </h2>
          <span
            className={styles.paragraph3}
            data-testid={TEST_ID_MINI_CART_ITEM_COUNT}
          >
            <FormattedMessage
              id="cart.numberOfItemsInCart"
              values={{ count: summaries.checkout.itemCount }}
            />
          </span>
          <button
            className="svg-icon icon-wrapper-small bg-transparent ba bw1 br-100 b--inherit stroke-current-color hover-green flex items-center justify-center pointer"
            aria-label={formatMessage({ id: "menu.close" })}
            onClick={hideMiniCart}
            data-testid="mini-cart-close-button"
          >
            <IconCross />
          </button>
        </div>
      </div>
      <div
        className="ph4 pb4 overflow-y-auto momentum-scrolling"
        style={{ maxHeight: "35vh" }}
      >
        {visibleItems.length ? (
          <ul className="list ma0 pa0">
            {visibleItems.map((item, i) => (
              <li
                className={i === 0 ? "mt4" : "bt b--dark-gray pt3 mt3"}
                key={item.checkoutLineItemId}
                data-testid={`mini-cart-list-item-${item.variant.id}`}
              >
                <MiniCartLineItem item={item} />
              </li>
            ))}
          </ul>
        ) : (
          <div
            className={classNames(
              styles.paragraph3,
              "nested-contained-copy-separator mt4",
            )}
          >
            <p>
              <FormattedMessage id="cart.isEmpty" />
            </p>
          </div>
        )}
      </div>
      <div className="bg-black white pa4">
        <LineItemTotal totalPrice={summaries.checkout.totalPrice} />
        <div className="mt4">
          <LocaleLink slug="cart">
            <a
              className={classNames(styles.primaryButton, "b--white w-100")}
              onClick={hideMiniCart}
              onKeyPress={hideMiniCart}
              role="button"
              tabIndex={0}
            >
              <FormattedMessage id="miniCart.toCart" />
            </a>
          </LocaleLink>
        </div>
      </div>
    </div>
  );
};

export default MiniCart;
