import { useCallback, useState } from "react";

interface UseViews<T extends string> {
  activeView: T;
  previousView: T;
  changeView: (view: T) => void;
  goBack: () => void;
}

function useViews<T extends string>(initialView: T): UseViews<T> {
  const [activeView, setActiveView] = useState<T>(initialView);
  const [previousView, setPreviousView] = useState<T>(initialView);

  const changeView = useCallback(
    (view: T) => {
      if (view !== activeView) {
        setPreviousView(activeView);
        setActiveView(view);
      }
    },
    [activeView],
  );

  const goBack = useCallback(() => {
    if (previousView !== activeView) {
      setActiveView(previousView);
    }
  }, [activeView, previousView]);

  return {
    activeView,
    previousView,
    changeView,
    goBack,
  };
}

export default useViews;
