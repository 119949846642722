import classNames from "classnames";
import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";

import IconChevronDown from "../../public/svg/icon-chevron-down.svg";
import IconChevronUp from "../../public/svg/icon-chevron-up.svg";
import { MenuItem } from "../lib/contentful/build-menu";
import sarkanniemiStylesheet from "../styles/themes/sarkanniemi";

import LocaleLink from "./LocaleLink";

export const TEST_ID_SUB_MENU_TOGGLE = "footer-menu-list-sub-menu-toggle";
export const TEST_ID_SUB_MENU = "footer-menu-list-sub-menu";
export const TEST_ID_SUB_MENU_ITEM = "footer-menu-list-sub-menu-item";
export const TEST_ID_SUB_MENU_PARENT_ITEM =
  "footer-menu-list-sub-menu-parent-item";
export const TEST_ID_SUB_MENU_PARENT_ITEM_DESKTOP =
  "footer-menu-list-sub-menu-parent-item-desktop";

interface FooterMenuListSubMenuProps {
  menuItem: MenuItem;
}

const FooterMenuListSubMenu: React.FC<FooterMenuListSubMenuProps> = ({
  menuItem,
}) => {
  const { formatMessage } = useIntl();
  const [menuIsOpen, setmenuIsOpen] = useState(false);

  const handlemenuIsOpen = useCallback((): void => {
    setmenuIsOpen(!menuIsOpen);
  }, [menuIsOpen]);

  return (
    <ul className="list ma0 pa0">
      <li>
        <button
          className={classNames(
            "flex",
            "items-center",
            "justify-between",
            "bg-transparent",
            "bw0",
            "pointer",
            "pa0",
            "white",
            "w-100",
            "underline-hover",
            "stroke-current-color",
            "svg-icon-large",
            "fill-transparent",
            "dn-l",
          )}
          aria-label={formatMessage({
            id: menuIsOpen ? "menu.close" : "menu.open",
          })}
          onClick={handlemenuIsOpen}
          id={menuItem.name}
          data-testid={TEST_ID_SUB_MENU_TOGGLE}
        >
          <span
            className={classNames(
              sarkanniemiStylesheet.heading2,
              sarkanniemiStylesheet.navItemFooter,
              "pr2",
              "pv2",
            )}
          >
            {menuItem.name}
          </span>
          {menuIsOpen ? <IconChevronUp /> : <IconChevronDown />}
        </button>
        <ul
          className={classNames("list ma0 pa0 db-l", {
            db: menuIsOpen,
            dn: !menuIsOpen,
          })}
          data-testid={TEST_ID_SUB_MENU}
        >
          <li className="mt3 dn db-l" key={`${menuItem.name}-desktop`}>
            {menuItem.useOnlyAsLabel ? (
              <span
                className={`${sarkanniemiStylesheet.heading2} no-underline color-inherit dn pv2 db`}
                data-testid={TEST_ID_SUB_MENU_PARENT_ITEM_DESKTOP}
              >
                {menuItem.name}
              </span>
            ) : (
              <LocaleLink href={menuItem.href} slug={menuItem.slug}>
                <a
                  className={`${sarkanniemiStylesheet.heading2} no-underline color-inherit dn pv2 underline-hover db`}
                  target={menuItem.openInNewWindow ? "_blank" : undefined}
                  data-testid={TEST_ID_SUB_MENU_PARENT_ITEM_DESKTOP}
                >
                  {menuItem.name}
                </a>
              </LocaleLink>
            )}
          </li>
          {!menuItem.useOnlyAsLabel && (
            <li className="mt3 db dn-l" key={`${menuItem.name}`}>
              <LocaleLink href={menuItem.href} slug={menuItem.slug}>
                <a
                  className={`${sarkanniemiStylesheet.heading3} no-underline color-inherit dn pv2 underline-hover db`}
                  target={menuItem.openInNewWindow ? "_blank" : undefined}
                  data-testid={TEST_ID_SUB_MENU_PARENT_ITEM}
                >
                  {menuItem.name}
                </a>
              </LocaleLink>
            </li>
          )}
          {menuItem.items
            ?.filter(subMenuItem => !subMenuItem.useOnlyAsLabel)
            .map(subMenuItem => (
              <li className="mt3" key={subMenuItem.name}>
                <LocaleLink href={subMenuItem.href} slug={subMenuItem.slug}>
                  <a
                    className={`${sarkanniemiStylesheet.heading3} no-underline color-inherit db pv2 underline-hover`}
                    target={subMenuItem.openInNewWindow ? "_blank" : undefined}
                    data-testid={TEST_ID_SUB_MENU_ITEM}
                  >
                    {subMenuItem.name}
                  </a>
                </LocaleLink>
              </li>
            ))}
        </ul>
      </li>
    </ul>
  );
};

export default FooterMenuListSubMenu;
