import React from "react";

import { buildEntryLink } from "../../lib/contentful/build-entry-link";
import { ILinkFields } from "../../types/generated/contentful";
import LocaleLink from "../LocaleLink";

export const TEST_ID_LINK = "link";

interface LinkProps extends ILinkFields {
  children?: React.ReactNode;
  cssClass?: string;
}

const Link: React.FC<LinkProps> = ({
  children,
  cssClass,
  externalLink,
  internalLink,
  linkTitle,
  openInNewWindow,
  queryParameters,
}) => {
  const baseLink = internalLink ? buildEntryLink(internalLink) : externalLink;
  const link = queryParameters ? `${baseLink}?${queryParameters}` : baseLink;
  const title = linkTitle || internalLink?.fields.title;
  const target = openInNewWindow ? "_blank" : undefined;

  if (!link) {
    return <></>;
  }

  if (internalLink) {
    return (
      <LocaleLink slug={link}>
        <a
          className={cssClass}
          target={target}
          aria-label={title}
          data-testid={TEST_ID_LINK}
        >
          {children}
        </a>
      </LocaleLink>
    );
  } else {
    return (
      <a
        className={cssClass}
        target={target}
        aria-label={title}
        href={link}
        data-testid={TEST_ID_LINK}
      >
        {children}
      </a>
    );
  }
};

export default Link;
