import { removeLocaleFromPathname } from "../lib/i18n";

type UseLocaleSwitcher = {
  change: (locale: string, hrefs?: { [key: string]: string }) => void;
};

const useLocaleSwitcher = (): UseLocaleSwitcher => {
  const change = (locale: string, hrefs?: { [key: string]: string }): void => {
    window.location.assign(
      hrefs && hrefs[locale] !== undefined
        ? `/${locale}/${hrefs[locale]}`
        : `/${locale}${removeLocaleFromPathname(window.location.pathname)}`,
    );
  };

  return {
    change,
  };
};

export default useLocaleSwitcher;
