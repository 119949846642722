import React, { useContext, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { AuthenticationContext } from "../../contexts/AuthenticationContext";
import { getHighlightElement } from "../../lib/intl-utils";

import Button from "./Button";
import css from "./DeleteAccountView.module.scss";
import FormField from "./FormField";
import { CommonViewProps } from "./LoginModal";
import LoginModalContainer from "./LoginModalContainer";
import LoginModalContent from "./LoginModalContent";
import LoginModalHeader from "./LoginModalHeader";

interface DeleteAccountViewProps extends CommonViewProps {
  onContinue: () => void;
}

const DeleteAccountView: React.FC<DeleteAccountViewProps> = ({
  onClose,
  onContinue,
  state,
}) => {
  const { formatMessage } = useIntl();
  const { user } = useContext(AuthenticationContext);
  const [confirmationValue, setConfirmationValue] = useState("");
  const confirmationText = formatMessage({
    id: "loginModal.deleteAccount.confirmationText",
  });
  const isLoading = state !== "idle";
  return (
    <LoginModalContainer>
      <LoginModalHeader
        style="default"
        titleId="loginModal.deleteAccount"
        onClose={onClose}
      />
      <LoginModalContent>
        <div className={css.container}>
          <p>
            <strong>
              <FormattedMessage id="loginModal.deleteAccount.disclaimer" />
            </strong>
          </p>
          {user !== null && (
            <p>
              <FormattedMessage id="loginModal.accountToBeDeleted" />
              <strong className={css.sub}>
                {user.fullName
                  ? `${user.fullName} (${user.email})`
                  : user.email}
              </strong>
            </p>
          )}
          <FormField
            type="text"
            name="confirmation-text"
            labelId="loginModal.deleteAccount.label"
            labelValues={{ confirmationText, strong: getHighlightElement }}
            value={confirmationValue}
            onChange={({ target }) => setConfirmationValue(target.value)}
          />
          <Button
            className={css.deleteButton}
            disabled={isLoading || confirmationValue !== confirmationText}
            loading={isLoading}
            icon="delete"
            style="primary"
            textId="loginModal.deleteAccount"
            onClick={onContinue}
            bold
          />
          <Button
            style="secondary"
            disabled={isLoading}
            textId="loginModal.cancel"
            onClick={onClose}
            bold
          />
        </div>
      </LoginModalContent>
    </LoginModalContainer>
  );
};

export default DeleteAccountView;
