import Head from "next/head";
import React from "react";

import { SITE_URL } from "../../config";

interface HeadAlternateLinksProps {
  hrefs?: { [key: string]: string };
}

const HeadAlternateLinks: React.FC<HeadAlternateLinksProps> = ({ hrefs }) => (
  <Head>
    {hrefs &&
      Object.entries(hrefs).map(([locale, href]) => {
        const url =
          href !== ""
            ? `${SITE_URL}/${locale}/${href}`
            : `${SITE_URL}/${locale}`;
        return (
          <link
            rel="alternate"
            href={url}
            hrefLang={locale}
            key={`${locale}-alt-link`}
          />
        );
      })}
  </Head>
);

export default HeadAlternateLinks;
