import React, { useCallback, useState } from "react";

interface MiniCartVisibilityContextProps {
  isMiniCartVisible: boolean;
  toggleId: string;
  hideMiniCart: () => void;
  showMiniCart: () => void;
  toggleMiniCart: () => void;
}

export const MINI_CART_TOGGLE_ID = "mini-cart-toggle";

export const MiniCartVisibilityContext =
  React.createContext<MiniCartVisibilityContextProps>({
    isMiniCartVisible: false,
    toggleId: MINI_CART_TOGGLE_ID,
    hideMiniCart: () => null,
    showMiniCart: () => null,
    toggleMiniCart: () => null,
  });

type MiniCartVisibilityProviderProps = {
  children?: React.ReactNode;
  isVisible?: MiniCartVisibilityContextProps["isMiniCartVisible"];
  contextProps?: Partial<MiniCartVisibilityContextProps>;
};

const MiniCartVisibilityProvider: React.FC<MiniCartVisibilityProviderProps> = ({
  children,
  isVisible,
  contextProps,
}) => {
  const [isMiniCartVisible, setIsMiniCartVisible] = useState(
    isVisible ?? false,
  );

  const hideMiniCart = useCallback((): void => {
    if (isMiniCartVisible) {
      setIsMiniCartVisible(false);
    }
  }, [isMiniCartVisible]);

  const showMiniCart = useCallback((): void => {
    if (!isMiniCartVisible) {
      setIsMiniCartVisible(true);
    }
  }, [isMiniCartVisible]);

  const toggleMiniCart = useCallback((): void => {
    setIsMiniCartVisible(!isMiniCartVisible);
  }, [isMiniCartVisible]);

  return (
    <MiniCartVisibilityContext.Provider
      value={{
        isMiniCartVisible,
        toggleId: MINI_CART_TOGGLE_ID,
        hideMiniCart,
        showMiniCart,
        toggleMiniCart,
        ...contextProps,
      }}
    >
      {children}
    </MiniCartVisibilityContext.Provider>
  );
};

export default MiniCartVisibilityProvider;
