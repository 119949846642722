import Script from "next/script";
import React, { Fragment, useContext, useEffect, useState } from "react";

import { Environment } from "../../environment";
import { CustomScript, filterScripts } from "../../injected-scripts";
import { CookieConsentContext } from "../contexts/CookieConsentContext";

export const TEST_ID_SCRIPT = "script";

interface ScriptsProps {
  environment: Environment;
  scripts: CustomScript[];
}

export const Scripts: React.FC<ScriptsProps> = ({ environment, scripts }) => {
  const { givenPermissions } = useContext(CookieConsentContext);
  const [visibleScripts, setVisibleScripts] = useState<CustomScript[]>(
    filterScripts(scripts, givenPermissions, environment),
  );

  useEffect(() => {
    setVisibleScripts(filterScripts(scripts, givenPermissions, environment));
  }, [environment, givenPermissions, scripts]);

  return (
    <Fragment>
      {visibleScripts.map(
        ({ id, cookieCategories: _c, allowedEnvironments: _a, ...props }) => (
          <Script key={id} id={id} {...props} data-testid={TEST_ID_SCRIPT} />
        ),
      )}
    </Fragment>
  );
};
