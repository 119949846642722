import isEqual from "lodash/isEqual";
import orderBy from "lodash/orderBy";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import { useDebouncedCallback } from "use-debounce";

import { EXTERNAL_USER_ID_KEY, CLIENT_KEY, CLIENT_MOBILE } from "../../config";
import {
  getCheckoutSummary,
  mapCheckoutToCheckoutItems,
} from "../ecommerce/checkout";
import { getProductSKUFromTags } from "../ecommerce/product";
import useCheckout from "../hooks/useCheckout";
import useCheckoutAttributes from "../hooks/useCheckoutAttributes";
import useCheckoutCreate from "../hooks/useCheckoutCreate";
import useCheckoutUpdate from "../hooks/useCheckoutUpdate";
import {
  triggerAddToCartEvent,
  triggerRemoveFromCartEvent,
} from "../seo/data-layer";
import { Attribute, Checkout } from "../types/shopify";
import {
  CheckoutItem,
  CheckoutSummaries,
  ExtendedVariant,
} from "../types/shopify-components";

import { AuthenticationContext } from "./AuthenticationContext";
import { CollectionsContext } from "./CollectionsContext";
import { MiniCartVisibilityContext } from "./MiniCartVisibilityContext";
import { MobileContext } from "./MobileContext";

interface UpdateOptions {
  openMiniCart: boolean;
  newItems?: CheckoutItem[];
}

interface IncreaseQuantityOptions {
  shouldUpdate?: boolean;
  variants: ExtendedVariant[];
}

interface DecreaseQuantityOptions {
  allowZero?: boolean;
  shouldUpdate?: boolean;
  variants: ExtendedVariant[];
}

export interface CheckoutContextProps {
  customAttributes: Attribute[];
  localItems: CheckoutItem[];
  checkoutItems: CheckoutItem[];
  checkoutUrl?: string;
  summaries: CheckoutSummaries;
  increaseQuantity: (options: IncreaseQuantityOptions) => void;
  decreaseQuantity: (options: DecreaseQuantityOptions) => void;
  resetQuantities: (variantIds: string[]) => void;
  remove: (items: CheckoutItem[]) => Promise<void>;
  update: (options: UpdateOptions) => Promise<boolean>;
  hasVariant: (variantId: string, inExistingCheckout: boolean) => boolean;
  isLoading: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  isTouched: boolean;
}

export const checkoutContextDefault: CheckoutContextProps = {
  customAttributes: [],
  localItems: [],
  checkoutItems: [],
  checkoutUrl: undefined,
  summaries: {
    checkout: {
      comparePriceDiscount: 0,
      comparePriceTotal: 0,
      itemCount: 0,
      totalPrice: 0,
    },
    local: {
      comparePriceDiscount: 0,
      comparePriceTotal: 0,
      itemCount: 0,
      totalPrice: 0,
    },
  },
  increaseQuantity: () => undefined,
  decreaseQuantity: () => undefined,
  resetQuantities: () => undefined,
  remove: async () => undefined,
  update: async () => false,
  hasVariant: () => false,
  isLoading: true,
  isCreating: false,
  isUpdating: false,
  isTouched: false,
};

export const CheckoutContext = React.createContext<CheckoutContextProps>(
  checkoutContextDefault,
);

interface CheckoutProviderProps {
  children?: React.ReactNode;
  checkoutId?: string;
  contextProps?: Partial<CheckoutContextProps>;
}

const CheckoutProvider: React.FC<CheckoutProviderProps> = ({
  children,
  checkoutId,
  contextProps,
}) => {
  const { user } = useContext(AuthenticationContext);
  const { isMobile } = useContext(MobileContext);
  const [isTouched, setIsTouched] = useState(false);
  const [localItemsInitialized, setLocalItemsInitialized] = useState(false);
  const [localItems, setLocalItems] = useState<CheckoutItem[]>([]);
  const [checkoutItems, setCheckoutItems] = useState<CheckoutItem[]>([]);
  const [summaries, setSummaries] = useState<CheckoutSummaries>({
    checkout: {
      comparePriceDiscount: 0,
      comparePriceTotal: 0,
      itemCount: 0,
      totalPrice: 0,
    },
    local: {
      comparePriceDiscount: 0,
      comparePriceTotal: 0,
      itemCount: 0,
      totalPrice: 0,
    },
  });

  const {
    checkout,
    refetch: refetchCheckout,
    loading: isLoading,
  } = useCheckout(checkoutId);
  const { createCheckout, loading: isCreating } = useCheckoutCreate({
    useCache: true,
  });
  const { updateCheckout, loading: isUpdating } = useCheckoutUpdate();
  const { updateAttributes } = useCheckoutAttributes();
  const { showMiniCart } = useContext(MiniCartVisibilityContext);
  const { products, loading: isCollectionsLoading } =
    useContext(CollectionsContext);

  const checkTouched = useCallback(
    (newItems: CheckoutItem[]): boolean => {
      const existing = orderBy(checkoutItems, item => item.input.variantId);
      const updated = orderBy(newItems, item => item.input.variantId);
      return !isEqual(existing, updated);
    },
    [checkoutItems],
  );

  const handleLocalItemsChange = useCallback(
    (newItems: CheckoutItem[]) => {
      setLocalItems(newItems);
      const touched = checkTouched(newItems);
      setIsTouched(touched);
    },
    [checkTouched],
  );

  const handleSubProducts = useCallback(
    (checkout: Checkout): Checkout => {
      return {
        ...checkout,
        lineItems: {
          edges: checkout.lineItems.edges.map(edge => {
            const { variant } = edge.node;
            const subData =
              variant && getProductSKUFromTags(variant.product.tags);
            if (subData?.type === "sub") {
              const containerProduct = products.find(product => {
                const productData = getProductSKUFromTags(product.tags);
                if (productData) {
                  return (
                    productData.type === "super" &&
                    productData.sku === subData.sku
                  );
                }
              });
              if (containerProduct) {
                return {
                  ...edge,
                  node: {
                    ...edge.node,
                    variant: edge.node.variant
                      ? {
                          ...edge.node.variant,
                          product: {
                            ...edge.node.variant.product,
                            handle: containerProduct.handle,
                          },
                        }
                      : null,
                  },
                };
              }
            }
            return edge;
          }),
        },
      };
    },
    [products],
  );

  const handleCheckoutItemsChange = useCallback(
    (checkout: Checkout): CheckoutItem[] => {
      return mapCheckoutToCheckoutItems(handleSubProducts(checkout));
    },
    [handleSubProducts],
  );

  const create = useCallback(
    async (items: CheckoutItem[]) => {
      if (!checkout) {
        const createdCheckout = await createCheckout({
          lineItems: items.map(item => item.input),
        });
        if (createdCheckout) {
          const items = handleCheckoutItemsChange(createdCheckout);
          setCheckoutItems(items);
          setIsTouched(false);
          showMiniCart();
          return true;
        }
      }
      return false;
    },
    [createCheckout, checkout, handleCheckoutItemsChange, showMiniCart],
  );

  const update =
    contextProps?.update ??
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useCallback(
      async ({ openMiniCart, newItems }: UpdateOptions) => {
        if (!checkout) {
          return await create(newItems ?? localItems);
        } else {
          if (isTouched || newItems) {
            const items = newItems ?? localItems;
            const updatedCheckout = await updateCheckout({
              checkoutId: checkout.id,
              lineItems: items.map(item => item.input),
            });

            if (!updatedCheckout) {
              return false;
            }

            const updatedItems = handleCheckoutItemsChange(updatedCheckout);
            setCheckoutItems(updatedItems);
            setLocalItems(updatedItems);
            setIsTouched(false);

            if (openMiniCart) {
              showMiniCart();
            }

            return true;
          }
          return false;
        }
      },
      [
        create,
        checkout,
        handleCheckoutItemsChange,
        localItems,
        isTouched,
        updateCheckout,
        showMiniCart,
      ],
    );

  const updateDebounced = useDebouncedCallback(update, 500);

  const remove = useCallback(
    async (removedItems: CheckoutItem[]) => {
      const newItems = localItems.filter(
        item =>
          !removedItems.some(
            ({ checkoutLineItemId }) =>
              checkoutLineItemId === item.checkoutLineItemId,
          ),
      );
      const success = await update({ openMiniCart: false, newItems });

      if (success) {
        for (const { variant, input } of removedItems) {
          triggerRemoveFromCartEvent(variant, input.quantity);
        }
      }
    },
    [localItems, update],
  );

  const increaseQuantity = useCallback(
    ({ variants, shouldUpdate }: IncreaseQuantityOptions) => {
      const updatedVariantIds: string[] = [];
      const createdVariants: ExtendedVariant[] = [];

      for (const variant of variants) {
        if (
          localItems.some(localItem => localItem.input.variantId === variant.id)
        ) {
          updatedVariantIds.push(variant.id);
        } else {
          createdVariants.push(variant);
        }
      }

      const newItems = [
        ...localItems.map(item => {
          if (updatedVariantIds.includes(item.input.variantId)) {
            return {
              ...item,
              input: {
                ...item.input,
                quantity: item.input.quantity + 1,
              },
            };
          }
          return item;
        }),
        ...createdVariants.map(variant => ({
          input: {
            variantId: variant.id,
            quantity: 1,
            customAttributes: [],
          },
          variant,
        })),
      ];

      for (const variant of variants) {
        triggerAddToCartEvent(variant);
      }

      handleLocalItemsChange(newItems);

      if (shouldUpdate) {
        updateDebounced({ openMiniCart: false });
      }
    },
    [localItems, handleLocalItemsChange, updateDebounced],
  );

  const decreaseQuantity = useCallback(
    ({ variants, allowZero, shouldUpdate }: DecreaseQuantityOptions) => {
      const removedVariants: ExtendedVariant[] = [];

      const updatedItems = localItems.reduce<CheckoutItem[]>((items, item) => {
        const existingVariant = variants.find(
          variant => variant.id === item.input.variantId,
        );

        if (existingVariant && item.input.quantity > 0) {
          const newQuantity = item.input.quantity - 1;

          if (newQuantity > 0) {
            items.push({
              ...item,
              input: {
                ...item.input,
                quantity: newQuantity,
              },
            });
            removedVariants.push(existingVariant);
          } else if (localItems.length && allowZero) {
            removedVariants.push(existingVariant);
          } else {
            items.push(item);
            toast.info(
              <p className="ma0" data-testid="toast">
                <FormattedMessage id="cart.useRemoveButtonToRemoveProductFromCart" />
              </p>,
              {
                toastId: "toast-use-remove-button-to-remove-product-from-cart",
              },
            );
          }
        } else {
          items.push(item);
        }
        return items;
      }, []);

      handleLocalItemsChange(updatedItems);

      for (const variant of removedVariants) {
        triggerRemoveFromCartEvent(variant);
      }

      if (shouldUpdate) {
        updateDebounced({ openMiniCart: false });
      }
    },
    [localItems, handleLocalItemsChange, updateDebounced],
  );

  const resetQuantities = useCallback(
    (variantIds: string[]) => {
      if (checkoutItems.length === 0 && localItems.length === 0) {
        return;
      }

      const newItems = checkoutItems.reduce<CheckoutItem[]>((items, item) => {
        const variantId = variantIds.find(id => id === item.input.variantId);
        if (variantId) {
          const checkoutQuantity = checkoutItems.find(
            ({ variant }) => variant?.id === item.input.variantId,
          )?.input.quantity;

          if (checkoutQuantity === undefined) {
            // No quantity, drop item
            return items;
          }
          // Is in given variant IDs, use checkout quantity
          return [
            ...items,
            {
              ...item,
              input: {
                ...item.input,
                quantity: checkoutQuantity,
              },
            },
          ];
        } else {
          const localQuantity = localItems.find(
            ({ variant }) => variant?.id === item.input.variantId,
          )?.input.quantity;

          if (localQuantity === undefined) {
            // No quantity, drop item
            return items;
          }
          // Not in given variant IDs, use local quantity
          return [
            ...items,
            {
              ...item,
              input: {
                ...item.input,
                quantity: localQuantity,
              },
            },
          ];
        }
      }, []);
      handleLocalItemsChange(newItems);
    },
    [checkoutItems, localItems, handleLocalItemsChange],
  );

  const hasVariant = useCallback(
    (variantId: string, inExistingCheckout = false) => {
      if (inExistingCheckout) {
        return checkoutItems.some(({ variant }) => variant.id === variantId);
      }
      return localItems.some(item => item.input.variantId === variantId);
    },
    [checkoutItems, localItems],
  );

  // Initialize items once if checkout exists
  useEffect(() => {
    if (
      checkout &&
      !checkout.completedAt &&
      !localItemsInitialized &&
      !isCollectionsLoading
    ) {
      const items = handleCheckoutItemsChange(checkout);
      setLocalItemsInitialized(true);
      setCheckoutItems(items);
      setLocalItems(items);
    }
  }, [
    checkout,
    handleCheckoutItemsChange,
    isCollectionsLoading,
    localItemsInitialized,
  ]);

  // Update summaries if local or checkout items change
  useEffect(() => {
    setSummaries({
      checkout: getCheckoutSummary(checkoutItems),
      local: getCheckoutSummary(localItems),
    });
  }, [checkoutItems, localItems]);

  // Update custom attributes if changed
  useEffect(() => {
    if (checkout?.id && checkout?.customAttributes) {
      const externalUserId = checkout.customAttributes.find(
        ({ key }) => key === EXTERNAL_USER_ID_KEY,
      )?.value;
      const client = checkout.customAttributes.find(
        ({ key }) => key === CLIENT_KEY,
      )?.value;

      const newCustomAttributes = [];
      const updateUser = externalUserId !== user?.id;
      const updateClient =
        (isMobile && client !== CLIENT_MOBILE) ||
        (!isMobile && client === CLIENT_MOBILE);
      if (updateUser || updateClient) {
        if (user) {
          newCustomAttributes.push({
            key: EXTERNAL_USER_ID_KEY,
            value: user.id,
          });
        }
        if (isMobile) {
          newCustomAttributes.push({
            key: CLIENT_KEY,
            value: CLIENT_MOBILE,
          });
        }
        updateAttributes(checkout.id, {
          customAttributes: newCustomAttributes,
        }).then(() => {
          refetchCheckout();
        });
      }
    }
  }, [
    checkout?.id,
    checkout?.customAttributes,
    user,
    isMobile,
    refetchCheckout,
    updateAttributes,
  ]);

  return (
    <CheckoutContext.Provider
      value={{
        customAttributes: checkout?.customAttributes ?? [],
        localItems,
        checkoutItems,
        checkoutUrl: checkout?.webUrl,
        summaries,
        decreaseQuantity,
        increaseQuantity,
        resetQuantities,
        remove,
        update,
        hasVariant,
        isLoading,
        isCreating,
        isUpdating,
        isTouched,
        ...contextProps,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};

export default CheckoutProvider;
