import React, { Fragment, useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { ForgotPasswordError } from "../../contexts/AuthenticationContext";

import Button from "./Button";
import ErrorText from "./ErrorText";
import FormField from "./FormField";
import { CommonViewProps } from "./LoginModal";
import LoginModalContainer from "./LoginModalContainer";
import LoginModalContent from "./LoginModalContent";
import LoginModalHeader from "./LoginModalHeader";
import { getPasswordErrors } from "./validators";

interface ForgotPasswordViewProps extends CommonViewProps {
  onForgotPassword: (email: string) => Promise<ForgotPasswordError | null>;
  onForgotPasswordSubmit: (
    email: string,
    newPassword: string,
    verificationCode: string,
  ) => Promise<ForgotPasswordError | null>;
  onSuccess: (email: string, password: string) => Promise<void>;
}

const ForgotPasswordView: React.FC<ForgotPasswordViewProps> = ({
  onClose,
  onForgotPassword,
  onForgotPasswordSubmit,
  onSuccess,
}) => {
  const { formatMessage } = useIntl();
  const [email, setEmail] = useState("");
  const [resetInitiated, setResetInitiated] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordErrors, setNewPasswordErrors] = useState<string[]>([]);
  const [forgotPasswordError, setForgotPasswordError] =
    useState<ForgotPasswordError | null>(null);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const onNewPasswordChange = (newPassword: string) => {
    setNewPasswordErrors(getPasswordErrors(newPassword));
    setNewPassword(newPassword);
  };

  const onContinue = useCallback(async () => {
    const error = await onForgotPassword(email);

    if (error) {
      setForgotPasswordError(error);
    } else {
      setResetInitiated(true);
    }
  }, [email, onForgotPassword]);

  const isFormValid =
    email.length > 0 &&
    verificationCode.length >= 6 &&
    newPassword !== "" &&
    newPasswordErrors.length === 0;

  const onSubmit = useCallback(async () => {
    if (isFormValid) {
      const error = await onForgotPasswordSubmit(
        email,
        newPassword,
        verificationCode,
      );
      if (error !== null) {
        setForgotPasswordError(error);
      } else {
        setPasswordChanged(true);
      }
    }
  }, [
    email,
    isFormValid,
    newPassword,
    verificationCode,
    onForgotPasswordSubmit,
  ]);

  return (
    <LoginModalContainer>
      <LoginModalHeader
        onClose={onClose}
        style="default"
        titleId="loginModal.changePassword"
      />
      <LoginModalContent>
        {resetInitiated ? (
          passwordChanged ? (
            <Fragment>
              <p>
                <FormattedMessage id="loginModal.passwordChanged" />
              </p>
              <Button
                disabled={!isFormValid}
                onClick={() => onSuccess(email, newPassword)}
                textId="loginModal.signInWithNewPassword"
                style="primary"
                bold
              />
            </Fragment>
          ) : (
            <Fragment>
              <p>
                <FormattedMessage id="loginModal.verification.forgotPassword.text" />
              </p>
              <FormField
                type="text"
                autoComplete="off"
                labelId="forms.verificationCode"
                name="one-time-code"
                value={verificationCode}
                placeholder="000000"
                onChange={event => setVerificationCode(event.target.value)}
              />
              <FormField
                type={passwordVisible ? "text" : "password"}
                autoComplete="new-password"
                labelId="forms.newPassword"
                name="new-password"
                value={newPassword}
                placeholder="************"
                onChange={event => onNewPasswordChange(event.target.value)}
                errors={newPasswordErrors}
                fieldIcon={
                  passwordVisible
                    ? {
                        icon: "eye-closed",
                        onClick: () => setPasswordVisible(false),
                      }
                    : {
                        icon: "eye-open",
                        onClick: () => setPasswordVisible(true),
                      }
                }
              />
              <Button
                disabled={!isFormValid}
                onClick={onSubmit}
                textId="loginModal.changePassword"
                style="primary"
                bold
              />
              {forgotPasswordError && (
                <ErrorText errorId={forgotPasswordError} />
              )}
            </Fragment>
          )
        ) : (
          <Fragment>
            <FormField
              type="email"
              autoComplete="off"
              labelId="forms.email"
              name="email"
              placeholder={formatMessage({
                id: "forms.email.placeholder",
              })}
              value={email}
              onChange={event => setEmail(event.target.value)}
            />
            <Button
              disabled={email === "" || resetInitiated}
              onClick={onContinue}
              textId="loginModal.continue"
              style="primary"
              bold
            />
            {forgotPasswordError && <ErrorText errorId={forgotPasswordError} />}
          </Fragment>
        )}
      </LoginModalContent>
    </LoginModalContainer>
  );
};

export default ForgotPasswordView;
