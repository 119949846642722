import { decode } from "jsonwebtoken";

import {
  ORGANIZATION_ID_TOKEN_KEY,
  ORGANIZATION_REFRESH_TOKEN_KEY,
} from "../../config";
import { env } from "../../environment";

export type OrganizationAuthTokens = {
  idToken: string;
  refreshToken: string;
};

export type OrganizationUserIdToken = {
  at_hash: string;
  sub: string;
  email_verified: boolean;
  iss: string;
  "cognito:username": string;
  "custom:organizationId"?: string;
  origin_jti: string;
  aud: string;
  token_use: string;
  auth_time: number;
  exp: number;
  iat: number;
  jti: string;
  email: string;
};

export interface IOrgAuthManager {
  getIdToken: () => string | null;
  getOrgIdFromToken: () => string | null;
  getRefreshToken: () => string | null;
  logout: () => void;
  redirectIfAuthTokensNotPresent: () => boolean;
  redirectToLogin: () => void;
  setAuthTokens: (tokens: OrganizationAuthTokens) => void;
  setIdToken: (newToken: string) => void;
  setRefreshToken: (newToken: string) => void;
}

export class OrgAuthManager implements IOrgAuthManager {
  private createLoginUri() {
    const redirectUri = `${env.SARKANNIEMI_API_URI}/organizations/oauth2/token`;
    const [currentPage] = window.location.href.split("?");

    return (
      `https://${env.ORGANIZATION_COGNITO_DOMAIN}/login` +
      `?client_id=${env.ORGANIZATION_USER_POOL_WEB_CLIENT_ID}` +
      `&response_type=code` +
      `&scope=aws.cognito.signin.user.admin+email+openid+phone+profile` +
      `&redirect_uri=${encodeURIComponent(redirectUri)}` +
      `&state=${encodeURIComponent(currentPage)}`
    );
  }

  private clearTokens(): void {
    sessionStorage.removeItem(ORGANIZATION_ID_TOKEN_KEY);
    sessionStorage.removeItem(ORGANIZATION_REFRESH_TOKEN_KEY);
  }

  redirectToLogin(): void {
    window.location.assign(this.createLoginUri());
  }

  logout(): void {
    this.clearTokens();
    window.location.assign("/");
  }

  getIdToken(): string | null {
    return sessionStorage.getItem(ORGANIZATION_ID_TOKEN_KEY);
  }

  getRefreshToken(): string | null {
    return sessionStorage.getItem(ORGANIZATION_REFRESH_TOKEN_KEY);
  }

  setIdToken(newToken: string): void {
    sessionStorage.setItem(ORGANIZATION_ID_TOKEN_KEY, newToken);
  }

  setRefreshToken(newToken: string): void {
    sessionStorage.setItem(ORGANIZATION_REFRESH_TOKEN_KEY, newToken);
  }

  setAuthTokens({ idToken, refreshToken }: OrganizationAuthTokens): void {
    this.setIdToken(idToken);
    this.setRefreshToken(refreshToken);
  }

  // Redirect is never instantaneous, so this will return boolean that can be used to foresee the behaviour
  redirectIfAuthTokensNotPresent(): boolean {
    if (this.getIdToken() === null || this.getRefreshToken() === null) {
      this.redirectToLogin();
      return true;
    }
    return false;
  }

  getOrgIdFromToken(): string | null {
    const token = this.getIdToken();
    if (token === null) {
      return null;
    }

    try {
      const decoded = decode(token) as OrganizationUserIdToken;
      return decoded["custom:organizationId"] ?? null;
    } catch (error) {
      this.clearTokens();
      return null;
    }
  }
}
