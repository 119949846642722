export function getSessionStorageItem(key: string): string | undefined {
  if (typeof window !== "undefined" && window.sessionStorage) {
    try {
      return window.sessionStorage.getItem(key) ?? undefined;
    } catch (error) {
      return undefined;
    }
  }
  return undefined;
}

export function setSessionStorageItem(key: string, value: string): void {
  if (typeof window !== "undefined" && window.sessionStorage) {
    try {
      return window.sessionStorage.setItem(key, value);
    } catch (error) {
      return undefined;
    }
  }
  return undefined;
}

export function removeSessionStorageItem(key: string): void {
  if (typeof window !== "undefined" && window.sessionStorage) {
    try {
      return window.sessionStorage.removeItem(key);
    } catch (error) {
      return undefined;
    }
  }
}

export function getLocalStorageItem(key: string): string | undefined {
  if (typeof window !== "undefined" && window.localStorage) {
    try {
      return window.localStorage.getItem(key) ?? undefined;
    } catch (error) {
      return undefined;
    }
  }
  return undefined;
}

export function setLocalStorageItem(key: string, value: string): void {
  if (typeof window !== "undefined" && window.localStorage) {
    try {
      return window.localStorage.setItem(key, value);
    } catch (error) {
      return undefined;
    }
  }
  return undefined;
}

export function removeLocalStorageItem(key: string): void {
  if (typeof window !== "undefined" && window.localStorage) {
    try {
      return window.localStorage.removeItem(key);
    } catch (error) {
      return undefined;
    }
  }
}

/** Get provided value primarily from `sessionStorage` and secondarily from `localStorage` */
export function getStorageItem(key: string): string | undefined {
  try {
    return getSessionStorageItem(key) ?? getLocalStorageItem(key);
  } catch (error) {
    return undefined;
  }
}

export function setStorageItem(
  key: string,
  value: string,
  persisted = false,
): void {
  try {
    return persisted
      ? setLocalStorageItem(key, value)
      : setSessionStorageItem(key, value);
  } catch (error) {
    return undefined;
  }
}

export function removeStorageItem(key: string): void {
  try {
    removeLocalStorageItem(key);
    removeSessionStorageItem(key);
  } catch (error) {
    return undefined;
  }
}
