import React, { ReactNode, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { SignUpError } from "../../contexts/AuthenticationContext";
import { getAnchorElement } from "../../lib/intl-utils";
import Checkbox from "../Checkbox";

import Button from "./Button";
import ErrorText from "./ErrorText";
import FormField from "./FormField";
import { CommonViewProps } from "./LoginModal";
import LoginModalContainer from "./LoginModalContainer";
import LoginModalContent from "./LoginModalContent";
import LoginModalHeader from "./LoginModalHeader";
import css from "./SignUpView.module.scss";
import { getPasswordErrors } from "./validators";

export const TEST_ID_SIGN_UP_VIEW_PASSWORD_FIELD =
  "sign-up-view-password-field";
export const TEST_ID_SIGN_UP_VIEW_ERROR = "sign-up-view-error";

interface SignUpViewProps extends CommonViewProps {
  onAppleSignIn: () => void;
  onGoogleSignIn: () => void;
  onSignUp: (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
  ) => void;
  loginButton: ReactNode;
  signUpError: SignUpError | null;
}

const SignUpView: React.FC<SignUpViewProps> = ({
  loginButton,
  onClose,
  onAppleSignIn,
  onGoogleSignIn,
  onSignUp,
  signUpError,
  state,
}) => {
  const { formatMessage, locale } = useIntl();
  const [ageCheck, setAgeCheck] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [privacyPolicyCheck, setPrivacyPolicyCheck] = useState(false);
  const [passwordErrors, setPasswordErrors] = useState<string[]>([]);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const clear = () => {
    setEmail("");
    setPassword("");
    setFirstName("");
    setLastName("");
    setPasswordErrors([]);
  };

  const handleClose = () => {
    clear();
    onClose();
  };

  const onPasswordChange = (newPassword: string) => {
    setPasswordErrors(getPasswordErrors(newPassword));
    setPassword(newPassword);
  };

  const loading = state !== "idle";
  const isFormValid =
    ageCheck &&
    firstName.length > 0 &&
    lastName.length > 0 &&
    email.length > 0 &&
    passwordErrors.length === 0 &&
    privacyPolicyCheck;

  return (
    <LoginModalContainer>
      <LoginModalHeader
        style="default"
        titleId="loginModal.signUp"
        onClose={handleClose}
      />
      <LoginModalContent>
        <Button
          icon="google"
          textId="loginModal.signUp.google"
          onClick={onGoogleSignIn}
          disabled={loading}
          loading={state === "sign-in-google"}
        />
        <Button
          icon="apple"
          textId="loginModal.signUp.apple"
          onClick={onAppleSignIn}
          disabled={loading}
          loading={state === "sign-in-apple"}
        />
        <h3>
          <FormattedMessage id="loginModal.signUp.credentials" />
        </h3>
        <FormField
          autoComplete="given-name"
          name="given-name"
          labelId="forms.firstName"
          onChange={event => setFirstName(event.target.value)}
          placeholder={formatMessage({
            id: "forms.firstName.placeholder",
          })}
          readOnly={loading}
          type="text"
          value={firstName}
          required
        />
        <FormField
          autoComplete="family-name"
          name="family-name"
          labelId="forms.lastName"
          onChange={event => setLastName(event.target.value)}
          placeholder={formatMessage({
            id: "forms.lastName.placeholder",
          })}
          readOnly={loading}
          type="text"
          value={lastName}
          required
        />
        <FormField
          autoComplete="email"
          name="email"
          labelId="forms.email"
          onChange={event => setEmail(event.target.value)}
          placeholder={formatMessage({
            id: "forms.email.placeholder",
          })}
          readOnly={loading}
          type="email"
          value={email}
          required
        />
        <FormField
          autoComplete="new-password"
          name="new-password"
          errors={passwordErrors}
          labelId="forms.password"
          onChange={event => onPasswordChange(event.target.value)}
          placeholder="************"
          readOnly={loading}
          testId={TEST_ID_SIGN_UP_VIEW_PASSWORD_FIELD}
          type={passwordVisible ? "text" : "password"}
          value={password}
          fieldIcon={
            passwordVisible
              ? { icon: "eye-closed", onClick: () => setPasswordVisible(false) }
              : { icon: "eye-open", onClick: () => setPasswordVisible(true) }
          }
          required
        />
        <Checkbox
          className={css.checkbox}
          name="age-check"
          labelId="loginModal.ageCheck"
          checked={ageCheck}
          onChange={event => setAgeCheck(event.target.checked)}
          readOnly={loading}
          required
        />
        <Checkbox
          className={css.checkbox}
          name="privacy-policy-check"
          labelId="loginModal.privacyPolicyCheck"
          checked={privacyPolicyCheck}
          onChange={event => setPrivacyPolicyCheck(event.target.checked)}
          readOnly={loading}
          required
        />
        <p>
          <FormattedMessage
            id="loginModal.privacyPolicyText"
            values={{
              a: getAnchorElement(
                `/${locale}/${formatMessage({ id: "appPath.privacyPolicy" })}`,
                "_blank",
              ),
            }}
          />
        </p>
        <Button
          disabled={!isFormValid || loading}
          loading={state === "sign-up"}
          textId="loginModal.continue"
          style="primary"
          onClick={() => onSignUp(email, password, firstName, lastName)}
          bold
        />
        {signUpError !== null && (
          <ErrorText
            errorId={signUpError}
            testId={TEST_ID_SIGN_UP_VIEW_ERROR}
          />
        )}
        <h3>
          <FormattedMessage id="loginModal.signInInstead" />
        </h3>
        {loginButton}
      </LoginModalContent>
    </LoginModalContainer>
  );
};

export default SignUpView;
