import classNames from "classnames";
import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";

import { localeNames, locales } from "../lib/i18n";

import css from "./LocaleSwitcherDropdown.module.scss";

interface LocaleSwitcherDropdownProps {
  handleLocaleChange: (locale: string) => void;
}

const LocaleSwitcherDropdown: React.FC<LocaleSwitcherDropdownProps> = ({
  handleLocaleChange,
}) => {
  const { locale: currentLocale } = useIntl();
  const [localeSwitcherDropdownOpen, setLocaleSwitcherDropdownOpen] =
    useState(false);

  const toggleLocaleSwitcherDropdown = useCallback((): void => {
    setLocaleSwitcherDropdownOpen(!localeSwitcherDropdownOpen);
  }, [setLocaleSwitcherDropdownOpen, localeSwitcherDropdownOpen]);

  const handleLocaleButtonClick = useCallback(
    (event: React.MouseEvent): void => {
      handleLocaleChange(event.currentTarget.id);
      setLocaleSwitcherDropdownOpen(false);
    },
    [handleLocaleChange, setLocaleSwitcherDropdownOpen],
  );

  return (
    <div className="relative lh-solid">
      <button
        className={`pa0 bg-transparent ba bw1 b--inherit br-100 flex items-center justify-center pointer overflow-hidden icon-wrapper-small ${
          localeSwitcherDropdownOpen &&
          classNames("br--top", css.localeSwitcherToggleOpen)
        }`}
        onClick={toggleLocaleSwitcherDropdown}
        data-testid="locale-switcher-toggle"
      >
        <span
          className="color-inherit fw6 f5 ttu db bg-white bw0 pa0"
          data-testid="current-locale"
        >
          {currentLocale}
        </span>
      </button>
      <ul
        className={`absolute left-0 w-100 list ma0 pa0 ba bw1 b--black br-100 br--bottom bt-0 overflow-hidden bg-white ${
          css.localeSwitcherChoices
        } z-1 ${localeSwitcherDropdownOpen ? "db" : "dn"}`}
        data-testid="locale-switcher-choices"
      >
        {locales
          .filter(locale => locale !== currentLocale)
          .map(locale => (
            <li className="flex items-center justify-center" key={locale}>
              <button
                className="fw6 f5 ttu db bw0 pa0 icon-wrapper-small pointer"
                aria-label={localeNames[locale]}
                id={locale}
                onClick={handleLocaleButtonClick}
                data-testid={`locale-switcher-locale-${locale}`}
              >
                {locale}
              </button>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default LocaleSwitcherDropdown;
