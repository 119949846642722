import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import React, { useCallback, useContext, useState } from "react";
import ReactModal from "react-modal";

import {
  AuthenticationContext,
  CommonError,
  SignInError,
  SignUpConfirmError,
  SignUpError,
} from "../../contexts/AuthenticationContext";
import useViews from "../../hooks/useViews";

import Button from "./Button";
import DeleteAccountView from "./DeleteAccountView";
import ForgotPasswordView from "./ForgotPasswordView";
import InitialView from "./InitialView";
import css from "./LoginModal.module.scss";
import SignInView from "./SignInView";
import SignUpSuccessView from "./SignUpSuccessView";
import SignUpVerificationView from "./SignUpVerificationView";
import SignUpView from "./SignUpView";

export const MODAL_CLOSE_TIMEOUT = 200;

function onAfterOpen() {
  const [overlay] = document.getElementsByClassName(css.overlay);
  if (overlay) {
    disableBodyScroll(overlay, {
      reserveScrollBarGap: true,
    });
  }
}

function onAfterClose() {
  clearAllBodyScrollLocks();
}

enum View {
  INITIAL = "initial",
  SIGN_IN = "sign-in",
  SIGN_UP = "sign-up",
  SIGN_UP_VERIFICATION = "sign-up-verification",
  SIGN_UP_SUCCESS = "sign-up-success",
  FORGOT_PASSWORD = "forgot-password",
  FORGOT_PASSWORD_VERIFICATION = "forgot-password-verification",
  DELETE_ACCOUNT = "delete-account",
}

type ProcessingState =
  | "idle"
  | "sign-in"
  | "sign-in-apple"
  | "sign-in-google"
  | "sign-in-confirm"
  | "sign-up"
  | "sign-up-confirm"
  | "sign-out"
  | "forgot-password"
  | "forgot-password-confirm"
  | "resend-signup-confirm"
  | "delete-account";

export interface CommonViewProps {
  state: ProcessingState;
  onClose: () => void;
}

interface LoginModalProps {
  onClose: () => void;
  isOpen: boolean;
}

const LoginModal: React.FC<LoginModalProps> = ({ isOpen, onClose }) => {
  const {
    deleteUser,
    federatedSignIn,
    forgotPassword,
    forgotPasswordSubmit,
    signIn,
    signOut,
    signUp,
    confirmSignUp,
    resendSignUpCode,
  } = useContext(AuthenticationContext);
  const { activeView, changeView } = useViews<View>(View.INITIAL);
  const [email, setEmail] = useState<string>("");
  const [state, setState] = useState<ProcessingState>("idle");
  const [signInError, setSignInError] = useState<SignInError | null>(null);
  const [signUpError, setSignUpError] = useState<SignUpError | null>(null);
  const [signUpConfirmError, setSignUpConfirmError] =
    useState<SignUpConfirmError | null>(null);
  const [resendSignUpConfirmationError, setResendSignUpConfirmationError] =
    useState<CommonError | null>(null);
  const [requiresVerification, setRequiresVerification] = useState(false);

  const clear = useCallback(() => {
    setEmail("");
    setSignInError(null);
    setSignUpError(null);
    setSignUpConfirmError(null);
    setRequiresVerification(false);
  }, []);

  const onModalClose = useCallback(() => {
    onClose();
    setTimeout(() => {
      clear();
      changeView(View.INITIAL);
    }, MODAL_CLOSE_TIMEOUT);
  }, [clear, changeView, onClose]);

  const onSignInClick = useCallback(() => {
    changeView(View.SIGN_IN);
    clear();
  }, [clear, changeView]);

  const onSignIn = useCallback(
    async (email: string, password: string) => {
      setState("sign-in");
      const error = await signIn(email, password);
      setState("idle");
      if (error) {
        setSignInError(error);
        if (error === "auth.error.username.notVerified") {
          setRequiresVerification(true);
        }
      } else {
        clear();
        changeView(View.INITIAL);
      }
    },
    [changeView, clear, signIn],
  );

  const onGoogleSignIn = useCallback(async () => {
    setState("sign-in-google");
    await federatedSignIn("google");
    setState("idle");
  }, [federatedSignIn]);

  const onAppleSignIn = useCallback(async () => {
    setState("sign-in-apple");
    await federatedSignIn("apple");
    setState("idle");
  }, [federatedSignIn]);

  const onSignOut = useCallback(async () => {
    setState("sign-out");
    await signOut();
    setState("idle");
    clear();
    changeView(View.INITIAL);
  }, [changeView, clear, signOut]);

  const onSignUpClick = useCallback(() => {
    changeView(View.SIGN_UP);
    clear();
  }, [clear, changeView]);

  const onSignUp = useCallback(
    async (
      email: string,
      password: string,
      firstName: string,
      lastName: string,
    ) => {
      setState("sign-up");
      const result = await signUp({
        email,
        password,
        firstName,
        lastName,
      });
      if (typeof result === "string") {
        setSignUpError(result);
      } else if (result === null) {
        setSignUpError("auth.error.unknown");
      } else {
        changeView(View.SIGN_UP_VERIFICATION);
        setEmail(email);
      }
      setState("idle");
    },
    [changeView, signUp],
  );

  const onResendSignUpConfirmation = useCallback(async () => {
    setState("resend-signup-confirm");
    const error = await resendSignUpCode(email);
    if (error) {
      setResendSignUpConfirmationError(error);
    }
    setState("idle");
  }, [email, resendSignUpCode]);

  const onSignUpConfirm = useCallback(
    async (verificationCode: string) => {
      setState("sign-up-confirm");
      const error = await confirmSignUp(email, verificationCode);
      setState("idle");
      if (error) {
        setSignUpConfirmError(error);
      } else {
        changeView(View.SIGN_UP_SUCCESS);
        clear();
      }
    },
    [email, changeView, clear, confirmSignUp],
  );

  const onForgotPassword = useCallback(
    async (email: string) => {
      setState("forgot-password");
      const result = await forgotPassword(email);
      setState("idle");
      return result;
    },
    [forgotPassword],
  );

  const onForgotPasswordClick = useCallback(() => {
    changeView(View.FORGOT_PASSWORD);
  }, [changeView]);

  const onForgotPasswordSubmit = useCallback(
    async (email: string, newPassword: string, verificationCode: string) => {
      setState("forgot-password-confirm");
      const result = await forgotPasswordSubmit(
        email,
        newPassword,
        verificationCode,
      );
      setState("idle");
      return result;
    },
    [forgotPasswordSubmit],
  );

  const onForgotPasswordSuccess = useCallback(
    async (email: string, password: string) => {
      setState("idle");
      await onSignIn(email, password);
      setState("idle");
    },
    [onSignIn],
  );

  const onVerificationClick = useCallback(
    (email: string) => {
      setEmail(email);
      changeView(View.SIGN_UP_VERIFICATION);
    },
    [changeView],
  );

  const onDeleteAccountClick = useCallback(() => {
    changeView(View.DELETE_ACCOUNT);
  }, [changeView]);

  const onDeleteAccount = useCallback(async () => {
    setState("delete-account");
    await deleteUser();
    setState("idle");
    changeView(View.INITIAL);
  }, [changeView, deleteUser]);

  const getView = useCallback(() => {
    const loading = state !== "idle";
    const loginButton = (
      <Button
        icon="login"
        textId="loginModal.signIn"
        onClick={onSignInClick}
        style="secondary"
        disabled={loading}
        loading={state === "sign-in"}
        bold
      />
    );
    const registerButton = (
      <Button
        icon="register"
        textId="loginModal.signUp"
        onClick={onSignUpClick}
        style={activeView === View.INITIAL ? "primary" : "secondary"}
        disabled={loading}
        loading={state === "sign-up"}
        bold
      />
    );

    switch (activeView) {
      case View.INITIAL: {
        return (
          <InitialView
            onClose={onModalClose}
            onDelete={onDeleteAccountClick}
            onSignOut={onSignOut}
            loginButton={loginButton}
            registerButton={registerButton}
            state={state}
          />
        );
      }
      case View.DELETE_ACCOUNT: {
        return (
          <DeleteAccountView
            state={state}
            onClose={onModalClose}
            onContinue={onDeleteAccount}
          />
        );
      }
      case View.FORGOT_PASSWORD: {
        return (
          <ForgotPasswordView
            onClose={onModalClose}
            onForgotPassword={onForgotPassword}
            onForgotPasswordSubmit={onForgotPasswordSubmit}
            onSuccess={onForgotPasswordSuccess}
            state={state}
          />
        );
      }
      case View.SIGN_IN: {
        return (
          <SignInView
            onAppleSignIn={onAppleSignIn}
            onClose={onModalClose}
            onForgotPasswordClick={onForgotPasswordClick}
            onGoogleSignIn={onGoogleSignIn}
            onSignIn={onSignIn}
            onVerificationClick={onVerificationClick}
            requiresVerification={requiresVerification}
            registerButton={registerButton}
            signInError={signInError}
            state={state}
          />
        );
      }
      case View.SIGN_UP: {
        return (
          <SignUpView
            onAppleSignIn={onAppleSignIn}
            onClose={onModalClose}
            onGoogleSignIn={onGoogleSignIn}
            onSignUp={onSignUp}
            loginButton={loginButton}
            signUpError={signUpError}
            state={state}
          />
        );
      }
      case View.SIGN_UP_VERIFICATION: {
        return (
          <SignUpVerificationView
            email={email}
            onClose={onModalClose}
            onResendSignUpConfirmation={onResendSignUpConfirmation}
            onSignUpConfirm={onSignUpConfirm}
            signUpConfirmError={signUpConfirmError}
            resendSignUpConfirmationError={resendSignUpConfirmationError}
            state={state}
          />
        );
      }
      case View.SIGN_UP_SUCCESS: {
        return (
          <SignUpSuccessView
            onClose={onModalClose}
            onContinue={() => changeView(View.INITIAL)}
            state={state}
          />
        );
      }
    }
  }, [
    activeView,
    email,
    requiresVerification,
    resendSignUpConfirmationError,
    signUpConfirmError,
    signInError,
    signUpError,
    state,
    changeView,
    onAppleSignIn,
    onDeleteAccount,
    onDeleteAccountClick,
    onForgotPassword,
    onForgotPasswordClick,
    onForgotPasswordSubmit,
    onForgotPasswordSuccess,
    onGoogleSignIn,
    onModalClose,
    onResendSignUpConfirmation,
    onSignIn,
    onSignInClick,
    onSignOut,
    onSignUp,
    onSignUpClick,
    onSignUpConfirm,
    onVerificationClick,
  ]);

  const shouldCloseLoosely = activeView === View.INITIAL;

  return (
    <ReactModal
      className={css.modal}
      overlayClassName={css.overlay}
      closeTimeoutMS={MODAL_CLOSE_TIMEOUT}
      isOpen={isOpen}
      shouldCloseOnOverlayClick={shouldCloseLoosely}
      shouldReturnFocusAfterClose={false}
      shouldCloseOnEsc={shouldCloseLoosely}
      onRequestClose={onModalClose}
      onAfterOpen={onAfterOpen}
      onAfterClose={onAfterClose}
    >
      {getView()}
    </ReactModal>
  );
};

export default LoginModal;
