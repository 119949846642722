import React from "react";
import { FormattedMessage } from "react-intl";

import { heading3, paragraph3, heading2 } from "../../utils/styles";
import Money from "../Money";

interface LineItemTotalProps {
  totalPrice: number;
}

const LineItemTotal: React.FC<LineItemTotalProps> = ({ totalPrice }) => (
  <div className="flex justify-between">
    <div className="mr3">
      <h2 className={`${heading3} mt0 mb2`}>
        <FormattedMessage id="lineItemTotal.totalPrice" />
      </h2>
      <span className={paragraph3}>
        (<FormattedMessage id="lineItemTotal.taxIncluded" />)
      </span>
    </div>
    <div>
      <span className={`${heading2()} ma0`} data-testid="line-item-total-price">
        <Money value={totalPrice} />
      </span>
    </div>
  </div>
);

export default LineItemTotal;
