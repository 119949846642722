/// <reference types="../../../backend/organizations/api/generated/types/organizations" />

import { env } from "../../environment";

import { IOrgAuthManager } from "./org-auth-manager";

export type ErrorResult = {
  data?: never;
  errorMessage: string;
  status: number | null;
};
type Result<T> =
  | {
      data: T;
      errorMessage?: never;
      status?: never;
    }
  | ErrorResult;

type Method = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

export interface IOrganizationAPIClient {
  deleteShares(
    body: Paths.DeleteOrganizationShares.RequestBody,
  ): Promise<Result<Paths.DeleteOrganizationShares.Responses.$200>>;
  deleteWalletShares(
    walletId: string,
  ): Promise<Result<Paths.DeleteWalletShares.Responses.$204>>;
  editWallet(
    walletId: string,
    body: Paths.OrgEditWallet.RequestBody,
  ): Promise<Result<Paths.OrgEditWallet.Responses.$200>>;
  editWalletShare(
    walletId: string,
    shareId: string,
    body: Paths.OrgEditWalletShare.RequestBody,
  ): Promise<Result<Paths.OrgEditWalletShare.Responses.$200>>;
  fetchWallet(
    walletId: string,
  ): Promise<Result<Paths.OrgFetchWallet.Responses.$200>>;
  reserveByMassShare(
    walletId: string,
    body: Paths.OrgReserveByMassShare.RequestBody,
  ): Promise<Result<Paths.OrgReserveByMassShare.Responses.$201>>;
}

type OrganizationAPIClientOptions = {
  authManager: IOrgAuthManager;
  organizationId: string;
};

export default class OrganizationAPIClient implements IOrganizationAPIClient {
  private baseURL: string;
  private organizationURL: string;
  private authManager: IOrgAuthManager;

  constructor({ authManager, organizationId }: OrganizationAPIClientOptions) {
    this.authManager = authManager;
    this.baseURL = `${env.SARKANNIEMI_API_URI}/organizations`;
    this.organizationURL = `${this.baseURL}/${organizationId}`;
  }

  private handleError(method: Method, response: Response): ErrorResult {
    return {
      errorMessage: `${method.toUpperCase()} ${
        response.url
      } failed with status ${response.status}`,
      status: response.status,
    };
  }

  private handleUnexpectedError(method: Method, url: string): ErrorResult {
    return {
      errorMessage: `${method} ${url} failed before response code was available`,
      status: null,
    };
  }

  private async makeRequest<TReq>(
    method: Method,
    endpoint: string,
    resource?: TReq,
  ): Promise<Response | ErrorResult> {
    this.authManager.redirectIfAuthTokensNotPresent();
    const url = `${this.organizationURL}/${endpoint}`;

    const headers: Record<string, string> = {
      Authorization: `Bearer ${this.authManager.getIdToken()}`,
    };

    const options: RequestInit = {
      method,
      headers,
    };

    if (resource) {
      options.body = JSON.stringify(resource);
      headers["Content-Type"] = "application/json";
    }

    try {
      return await fetch(url, options);
    } catch (error) {
      return this.handleUnexpectedError(method, url);
    }
  }

  private async get<T>(endpoint: string): Promise<Result<T>> {
    const response = await this.makeRequest("GET", endpoint);
    if ("errorMessage" in response) {
      return response;
    }

    switch (response.status) {
      case 200: {
        return {
          data: (await response.json()) as T,
        };
      }
      case 401: {
        return this.refreshTokenAndRetry(() => this.get<T>(endpoint));
      }
      default: {
        return this.handleError("GET", response);
      }
    }
  }

  private async post<TReq, TRes>(
    endpoint: string,
    resource: TReq,
  ): Promise<Result<TRes>> {
    const response = await this.makeRequest("POST", endpoint, resource);
    if ("errorMessage" in response) {
      return response;
    }

    switch (response.status) {
      case 200:
      case 201: {
        return { data: (await response.json()) as TRes };
      }
      case 401: {
        return this.refreshTokenAndRetry<TRes>(() =>
          this.post<TReq, TRes>(endpoint, resource),
        );
      }
      default: {
        return this.handleError("POST", response);
      }
    }
  }

  private async patch<TReq, TRes>(
    endpoint: string,
    resource: TReq,
  ): Promise<Result<TRes>> {
    const response = await this.makeRequest("PATCH", endpoint, resource);
    if ("errorMessage" in response) {
      return response;
    }

    switch (response.status) {
      case 200: {
        return { data: (await response.json()) as TRes };
      }
      case 401: {
        return this.refreshTokenAndRetry<TRes>(() =>
          this.post<TReq, TRes>(endpoint, resource),
        );
      }
      default: {
        return this.handleError("PATCH", response);
      }
    }
  }

  private async put<TReq, TRes>(
    endpoint: string,
    resource: TReq,
  ): Promise<Result<TRes>> {
    const response = await this.makeRequest("PUT", endpoint, resource);
    if ("errorMessage" in response) {
      return response;
    }

    switch (response.status) {
      case 200: {
        return { data: (await response.json()) as TRes };
      }
      case 401: {
        return this.refreshTokenAndRetry<TRes>(() =>
          this.post<TReq, TRes>(endpoint, resource),
        );
      }
      default: {
        return this.handleError("PUT", response);
      }
    }
  }

  private async delete<TReq, TRes>(
    endpoint: string,
    resource?: TReq,
  ): Promise<Result<TRes>> {
    const response = await this.makeRequest("DELETE", endpoint, resource);
    if ("errorMessage" in response) {
      return response;
    }

    switch (response.status) {
      case 200:
      case 201: {
        return { data: (await response.json()) as TRes };
      }
      case 204: {
        return { data: {} as TRes };
      }
      case 401: {
        return this.refreshTokenAndRetry<TRes>(() =>
          this.delete<TReq, TRes>(endpoint, resource),
        );
      }
      default: {
        return this.handleError("DELETE", response);
      }
    }
  }

  private async refreshTokenAndRetry<TRes>(
    toRetryCallback: () => Promise<Result<TRes>>,
  ): Promise<Result<TRes>> {
    const url = `${this.baseURL}/oauth2/refresh`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        refreshToken: this.authManager.getRefreshToken(),
      }),
    });

    if (response.status === 200) {
      const { idToken } =
        (await response.json()) as Paths.RefreshOAuth2Tokens.Responses.$200;
      this.authManager.setIdToken(idToken);
      return toRetryCallback();
    } else {
      this.authManager.redirectToLogin();
      throw new Error(
        "Code is not supposed to reach here, as client should be redirected to login",
      );
    }
  }

  public async deleteShares(
    body: Paths.DeleteOrganizationShares.RequestBody,
  ): Promise<Result<Paths.DeleteOrganizationShares.Responses.$200>> {
    return await this.delete<
      Paths.DeleteOrganizationShares.RequestBody,
      Paths.DeleteOrganizationShares.Responses.$200
    >("shares", body);
  }

  public async deleteWalletShares(
    walletId: string,
  ): Promise<Result<Paths.DeleteWalletShares.Responses.$204>> {
    return await this.delete<void, Record<string, never>>(
      `wallets/${walletId}/shares`,
    );
  }

  public async editWallet(
    walletId: string,
    body: Paths.OrgEditWallet.RequestBody,
  ): Promise<Result<Paths.OrgFetchWallet.Responses.$200>> {
    return await this.put<
      Paths.OrgEditWallet.RequestBody,
      Paths.OrgEditWallet.Responses.$200
    >(`wallets/${walletId}`, body);
  }

  public async editWalletShare(
    walletId: string,
    shareId: string,
    body: Paths.OrgEditWalletShare.RequestBody,
  ): Promise<Result<Components.Schemas.OrganizationWallet>> {
    return await this.patch<
      Paths.OrgEditWalletShare.RequestBody,
      Paths.OrgEditWalletShare.Responses.$200
    >(`wallets/${walletId}/shares/${shareId}`, body);
  }

  public async fetchWallet(
    walletId: string,
  ): Promise<Result<Paths.OrgFetchWallet.Responses.$200>> {
    return await this.get<Paths.OrgFetchWallet.Responses.$200>(
      `wallets/${walletId}`,
    );
  }

  public async reserveByMassShare(
    walletId: string,
    body: Paths.OrgReserveByMassShare.RequestBody,
  ): Promise<Result<Paths.OrgReserveByMassShare.Responses.$201>> {
    return await this.post<
      Paths.OrgReserveByMassShare.RequestBody,
      Paths.OrgReserveByMassShare.Responses.$201
    >(`wallets/${walletId}/reserve`, body);
  }
}
