import Link from "next/link";
import React from "react";
import { useIntl } from "react-intl";

interface LocaleLinkProps {
  children?: React.ReactNode;
  slug?: string;
  href?: string;
}

const LocaleLink: React.FC<LocaleLinkProps> = ({ slug, href, children }) => {
  const { locale } = useIntl();

  const pathname = href ?? `/${locale}/${slug}`;
  return (
    <Link href={pathname} scroll={true} legacyBehavior>
      {children}
    </Link>
  );
};

export default LocaleLink;
