import classnames from "classnames";
import { useRouter } from "next/router";
import React, { useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";

import CheckboxChecked from "../../public/svg/icon-checkbox-checked.svg";
import CheckboxUnchecked from "../../public/svg/icon-checkbox-unchecked.svg";
import { email as emailValidator } from "../lib/yup";
import {
  EmailSubscriptionAttributes,
  Subscription,
} from "../pages/api/custobar/email-subscription";
import sarkanniemiStylesheet from "../styles/themes/sarkanniemi";
import { IEmailSubscriptionFormFields } from "../types/generated/contentful";

import css from "./EmailSubscriptionForm.module.scss";
import Link from "./contentful/Link";

export const TEST_ID_EMAIL_SUBSCRIPTION_FORM = "email-subscription-form";
export const TEST_ID_EMAIL_SUBSCRIPTION_FORM_TITLE =
  "email-subscription-form-title";
export const TEST_ID_EMAIL_SUBSCRIPTION_FORM_DISCLAIMER =
  "email-subscription-form-disclaimer";
export const TEST_ID_EMAIL_SUBSCRIPTION_FORM_INPUT =
  "email-subscription-form-input";
export const TEST_ID_EMAIL_SUBSCRIPTION_FORM_CHECKBOX =
  "email-subscription-form-checkbox";
export const TEST_ID_EMAIL_SUBSCRIPTION_FORM_SUBSCRIBED =
  "email-subscription-form-subscribed";

const INPUT_NAME = "email";

const EmailSubscriptionForm: React.FC<IEmailSubscriptionFormFields> = ({
  availableSubscriptions,
  buttonText,
  colorScheme,
  heading,
  id,
  placeholder,
  termsLink,
}) => {
  const { query } = useRouter();
  const queryTags = query?.tags;
  const { formatMessage } = useIntl();
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([
    availableSubscriptions[0].fields.id,
  ]);
  const [email, setEmail] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(false);

  const handleEmailChange: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(({ target }) => {
      setEmail(target.value);
    }, []);

  const handleCheckboxChange: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(({ target }) => {
      const subscription = target.name;
      setSubscriptions(prev =>
        prev.includes(subscription)
          ? prev.filter(sub => sub !== subscription)
          : [...prev, subscription],
      );
    }, []);

  const showErrorNotification = useCallback(() => {
    toast.error(formatMessage({ id: "emailSubscriptionForm.error" }), {
      autoClose: 5000,
    });
  }, [formatMessage]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    event => {
      event.preventDefault();
      if (emailValidator.isValidSync(email) && subscriptions.length > 0) {
        const tags = Array.isArray(queryTags)
          ? queryTags
          : typeof queryTags === "string"
          ? queryTags.split(",")
          : undefined;
        const payload: EmailSubscriptionAttributes = {
          email,
          subscriptions,
          tags,
        };
        fetch("/api/custobar/email-subscription", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        })
          .then(response => {
            if (response.ok) {
              setEmail("");
              setIsSubscribed(true);
            } else {
              showErrorNotification();
            }
          })
          .catch(() => {
            showErrorNotification();
          });
      }
    },
    [email, queryTags, showErrorNotification, subscriptions],
  );

  return (
    <form
      id={id}
      className={classnames(css.container, css[colorScheme], {
        [css.subscribed]: isSubscribed,
      })}
      onSubmit={handleSubmit}
      data-testid={TEST_ID_EMAIL_SUBSCRIPTION_FORM}
    >
      {heading && (
        <h3
          data-testid={TEST_ID_EMAIL_SUBSCRIPTION_FORM_TITLE}
          className={classnames(sarkanniemiStylesheet.heading2)}
        >
          {heading}
        </h3>
      )}
      {isSubscribed ? (
        <p data-testid={TEST_ID_EMAIL_SUBSCRIPTION_FORM_SUBSCRIBED}>
          <FormattedMessage id="emailSubscriptionForm.subscribed" />
        </p>
      ) : (
        <div>
          <p
            className={css.disclaimer}
            data-testid={TEST_ID_EMAIL_SUBSCRIPTION_FORM_DISCLAIMER}
          >
            <FormattedMessage
              id="emailSubscriptionForm.accept"
              values={{
                termsLink: (
                  <Link {...termsLink.fields}>
                    <FormattedMessage id="emailSubscriptionForm.terms" />
                  </Link>
                ),
              }}
            />
          </p>
          <div className={css.inputContainer}>
            <input
              name={INPUT_NAME}
              type="email"
              placeholder={placeholder}
              onChange={handleEmailChange}
              value={email}
              data-testid={TEST_ID_EMAIL_SUBSCRIPTION_FORM_INPUT}
              autoComplete="off"
            />
            <button
              form={id}
              type="submit"
              disabled={subscriptions.length === 0}
              className={sarkanniemiStylesheet.primaryButton}
            >
              {buttonText}
            </button>
          </div>

          {availableSubscriptions.length > 1 && (
            <div className={css.subscriptions}>
              {availableSubscriptions.map(({ fields: { id, label } }) => (
                <div className={css.subscription} key={id}>
                  <label htmlFor={id}>
                    {subscriptions.includes(id) ? (
                      <CheckboxChecked />
                    ) : (
                      <CheckboxUnchecked />
                    )}
                    {label}
                  </label>
                  <input
                    id={id}
                    name={id}
                    type="checkbox"
                    onChange={handleCheckboxChange}
                    checked={subscriptions.includes(id)}
                    data-testid={TEST_ID_EMAIL_SUBSCRIPTION_FORM_CHECKBOX}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </form>
  );
};

export default EmailSubscriptionForm;
