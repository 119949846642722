import { useRouter } from "next/router";
import React, { useContext } from "react";
import { useIntl } from "react-intl";

import IconAccount from "../../public/svg/icon-account.svg";
import IconCart from "../../public/svg/icon-cart.svg";
import IconHamburger from "../../public/svg/icon-hamburger.svg";
import IconSearch from "../../public/svg/icon-search.svg";
import { AuthenticationContext } from "../contexts/AuthenticationContext";
import { CheckoutContext } from "../contexts/CheckoutContext";
import { MiniCartVisibilityContext } from "../contexts/MiniCartVisibilityContext";
import { MobileContext } from "../contexts/MobileContext";
import { Menu } from "../lib/contentful/build-menu";

import HeaderDesktopMenuList from "./HeaderDesktopMenuList";
import LocaleLink from "./LocaleLink";
import LocaleSwitcherDropdown from "./LocaleSwitcherDropdown";

export const TEST_ID_ACCOUNT = "header-desktop-account-button";
export const TEST_ID_MINI_CART_TOGGLE = "header-desktop-mini-cart-toggle";
export const testIdMiniCartItemCount =
  "minicart-toggle-total-count-of-items-in-cart";

interface HeaderDesktopProps {
  menu: Menu | null | undefined;
  handleLocaleChange: (locale: string) => void;
  toggleMobileMenu: () => void;
}

const HeaderDesktop: React.FC<HeaderDesktopProps> = ({
  menu,
  handleLocaleChange,
  toggleMobileMenu,
}) => {
  const { formatMessage } = useIntl();
  const router = useRouter();
  const { openModal } = useContext(AuthenticationContext);
  const { toggleMiniCart, toggleId } = useContext(MiniCartVisibilityContext);
  const { summaries } = useContext(CheckoutContext);
  const { visibleHeaderComponents } = useContext(MobileContext);

  const miniCartToggleButtonFragment = (
    <>
      <IconCart />
      {summaries.checkout.itemCount > 0 && (
        <span
          className="minicart-item-count flex items-center justify-center bg-black white b br-100 absolute top-100 left-0 translate-top-50"
          data-testid={testIdMiniCartItemCount}
        >
          {summaries.checkout.itemCount}
        </span>
      )}
    </>
  );

  // TODO: Implement search and remove this flag
  const isSearchVisible = false;

  return (
    <nav
      className="flex flex-grow-1 items-center justify-end justify-between-l"
      data-testid="navigation-desktop"
    >
      <div className="dn db-l">
        {menu && <HeaderDesktopMenuList menu={menu} />}
      </div>
      <div>
        <ul className="list ma0 pa0 flex">
          {isSearchVisible && (
            <li className="dib ml2">
              <button
                className="icon-wrapper-small svg-icon bg-transparent ba bw1 br-100 b--inherit flex items-center justify-center pointer fill-transparent stroke-current-color"
                aria-label={formatMessage({ id: "search.open" })}
              >
                <IconSearch />
              </button>
            </li>
          )}
          {visibleHeaderComponents.account && (
            <li className="dib-l self-center" data-testid={TEST_ID_ACCOUNT}>
              <button
                className="icon-wrapper-small svg-icon bg-transparent ba bw1 br-100 b--inherit flex items-center justify-center pointer fill-transparent stroke-current-color black"
                onClick={openModal}
                aria-label={formatMessage({ id: "account" })}
              >
                <IconAccount />
              </button>
            </li>
          )}
          <li className="dn dib-l ml2">
            <LocaleSwitcherDropdown handleLocaleChange={handleLocaleChange} />
          </li>
          {router.pathname !== "/[locale]/cart" &&
            visibleHeaderComponents.cart && (
              <>
                <li
                  className="dib dn-ns ml2"
                  data-testid={TEST_ID_MINI_CART_TOGGLE}
                >
                  <LocaleLink slug="cart">
                    <a className="minicart-toggle icon-wrapper-small svg-icon bg-transparent ba bw1 br-100 b--inherit flex items-center justify-center pointer fill-transparent stroke-current-color black relative">
                      {miniCartToggleButtonFragment}
                    </a>
                  </LocaleLink>
                </li>
                <li
                  className="dn dib-ns ml2"
                  data-testid={TEST_ID_MINI_CART_TOGGLE}
                >
                  <button
                    className="minicart-toggle icon-wrapper-small svg-icon bg-transparent ba bw1 br-100 b--inherit flex items-center justify-center pointer fill-transparent stroke-current-color black relative"
                    onClick={toggleMiniCart}
                    name={toggleId}
                    data-testid={toggleId}
                  >
                    {miniCartToggleButtonFragment}
                  </button>
                </li>
              </>
            )}
          {visibleHeaderComponents.menu && (
            <li className="dib dn-l ml2">
              <button
                className="icon-wrapper-small svg-icon bg-transparent ba bw1 br-100 b--inherit flex items-center justify-center pointer stroke-current-color"
                onClick={toggleMobileMenu}
                aria-label={formatMessage({ id: "menu.open" })}
                data-testid="mobile-navigation-open"
              >
                <IconHamburger />
              </button>
            </li>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default HeaderDesktop;
