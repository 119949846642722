import classNames from "classnames";
import Link from "next/link";
import React, { useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { ThemeContext } from "../contexts/ThemeContext";

import Layout from "./layouts/Default";

export const TEST_ID_ERROR_PAGE = "error-page";
type ErrorPageProps = {
  dontRenderLayout?: boolean;
  message?: {
    headingId?: string;
    bodyId?: string;
    buttonId?: string;
  };
};

const ErrorPage: React.FC<ErrorPageProps> = ({ dontRenderLayout, message }) => {
  const { styles } = useContext(ThemeContext);
  const { locale } = useIntl();

  const page = (
    <div className="mv5 tc" data-testid={TEST_ID_ERROR_PAGE}>
      <h1 className={classNames(styles.heading1)}>
        <FormattedMessage id={message?.headingId ?? "errorPage.heading"} />
      </h1>
      <p className={classNames(styles.paragraph1, "mv4")}>
        <FormattedMessage id={message?.bodyId ?? "errorPage.body"} />
      </p>
      <Link href={`/${locale}/`} legacyBehavior>
        <a className={classNames(styles.primaryButton, "mt3")}>
          <FormattedMessage id={message?.buttonId ?? "errorPage.backToHome"} />
        </a>
      </Link>
    </div>
  );

  return dontRenderLayout ? page : <Layout>{page}</Layout>;
};

export default ErrorPage;
