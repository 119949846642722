import baseStylesheet, { ThemeStylesheet } from "./base";

const nasinneulaStylesheet: ThemeStylesheet = {
  ...baseStylesheet,
  heading1: "ttu f3 f-subheadline-l lh-title nn tc tracked-5",
  heading2: "ttu fw6 f4 lh-title tracked-10",
  primaryButton:
    "ttu f6 f5-l lh-title dib ba bw1 br-pill b--black pv3 ph4 black no-underline hover-bg-black hover-white pointer lh-solid tc button-text-vertical-adjustment bg-white",
};

export default nasinneulaStylesheet;
