import classNames from "classnames";
import React, { useContext } from "react";

import { ContentfulPageContext } from "../../contexts/ContentfulPageContext";
import { MenuContext } from "../../contexts/MenuContext";
import { MobileContext } from "../../contexts/MobileContext";
import Container from "../Container";
import Footer from "../Footer";
import Header from "../Header";
import MiniCart from "../ecommerce/MiniCart";

interface LayoutProps {
  children?: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { isMobile } = useContext(MobileContext);
  const { footerMenu, headerMenu } = useContext(MenuContext);
  const { backgroundClassName, backgroundColor } = useContext(
    ContentfulPageContext,
  );

  return (
    <Container
      cssClass={classNames(
        "h-100",
        "flex",
        "flex-column",
        backgroundClassName && `bg-${backgroundClassName}`,
      )}
      style={{
        backgroundColor,
      }}
    >
      <Header menu={headerMenu} />
      <MiniCart />
      {/* following style definitions are needed to break collapsing margins */}
      <main
        className={classNames(
          backgroundClassName && `bg-${backgroundClassName}`,
        )}
        style={{ paddingTop: 1, marginTop: -1, backgroundColor }}
      >
        {children}
      </main>
      {!isMobile && <Footer menu={footerMenu} />}
    </Container>
  );
};

export default Layout;
